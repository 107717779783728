import React from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";

function SelectButton({ htmlFor, name, id, option }: any) {
  return (
    <SelectDiv>
      <label htmlFor={htmlFor}></label>
      <select name={name} id={id}>
        <option value="">{option}</option>
      </select>
    </SelectDiv>
  );
}

export default function StatistiquesChart() {
  return (
    <Statistiques>
      <div className="container__head">
        <p className="chartTitle">Statistics</p>
        <SelectButton
          htmlFor="yearSelect"
          name="year"
          id="yearSelect"
          option="per Year"
        />
      </div>
      <Chart
        type="bar"
        height={250}
        series={[
          {
            name: "Funding",
            data: [110, 210, 280, 110, 60, 270, 90],
          },
          {
            name: "housing adaptation",
            data: [210, 110, 55, 210, 95, 65, 100],
          },
          {
            name: "home meal service",
            data: [60, 0, 30, 50, 220, 85, 205],
          },
          {
            name: "Assistance / Care",
            data: [45, 80, 65, 40, 20, 0, 0],
          },
        ]}
        options={{
          dataLabels: { enabled: false },
          colors: ["#CBF2F4", "#8DDFE4", "#54C3CA", "#18B6C0"],
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
            background: "#fff",
          },
          yaxis: {},
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [
              "2017",
              "2018",
              "2019",
              "2020",
              "2021",
              "2022",
              "2023",
            ],
          },
          legend: {
            // show: false,
          },
          responsive: [{}],
        }}
      />
    </Statistiques>
  );
}

const Statistiques = styled.div`
  /* width: 500px; */
  width: 100%;
  background-color: #fff;
  min-width: 500px;

  .exporter {
    color: #18b6c0;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

const SelectDiv = styled.div`
  select {
    width: 180px;
    min-width: 150px;
    height: 35px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    text-align: center;
    border: 0px;
    border-radius: 4px;
    @media screen and (max-width: 320px) {
      width: 100px;
      min-width: 0;
      font-size: 14px;
    }
    @media screen and (max-width: 550px) {
      width: 100px;
    }
    @media screen and (max-width: 1850px) and (min-width: 551px) {
      width: 80px;
    }
  }
`;
