import React from "react";
import styled from "styled-components";
import FrequentationChart from "./Tools/FrequentationChart";
import Map from "./Tools/Map";
import StatistiquesChart from "./Tools/StatistiquesChart";

export default function Social() {
  return (
    <Content>
      <TitleStyle>
        <img src="/Social/Logo.svg" alt="" />
        <p>
          {/* Centre Local d'Information et Coordination */}
          Local Information and Coordination Center
          
          </p>
      </TitleStyle>
      <Details>
        <FirstRow>
          <Map />
        </FirstRow>
        <SecondRow>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <FrequentationChart />
          </div>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <StatistiquesChart />
          </div>
        </SecondRow>
      </Details>
    </Content>
  );
}

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  gap: 2rem;
  p {
    margin-bottom: 0;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  @media screen and (max-width: 1512px) {
    justify-content: space-around;
  }
  width: 100%;
  height: 730px;
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  max-width: 780px;
  @media screen and (max-width: 1512px) {
    max-width: unset;
    align-items: center;
  }
`;

const TitleStyle = styled.div`
  display: flex;
  gap: 1rem;
  p {
    color: #3f487e;
    font-weight: 600;
  }
  @media screen and (max-width: 440px){
    p{
      font-size: 15px;
    }
  }
`;

const Details = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media screen and (max-width: 1512px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
