import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function LongueurChart() {
  return (
    <Longueur>
      <div className="container__head">
        {/* <p className="chartTitle">Longueurs traitées par état de réalistion</p> */}
        <p className="chartTitle">Lengths processed by state of completion</p>
      </div>
      <Chart
        type="pie"
        // height="85 %"
        series={[15, 30, 55]}
        options={{
          responsive: [
            {
              breakpoint: 560,
              options: {
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          labels: ["Bon", "Moyen", "Mauvais"],
          colors: ["#3CD1DB", "#FBACC8", "#42728D"],
          legend: {
            position: "right",
          },
          chart: {
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
        }}
      />
    </Longueur>
  );
}

const Longueur = styled.div`
  height: 100%;

  min-width: 50%;
  background-color: #fff;
  @media screen and (max-width: 630px) {
    width: 100%;
  }
  @media screen and (max-width: 1470px) and (min-width: 631px) {
    width: 48%;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 600 !important;

      font-size: 14px !important;

      /* text-align: center; */
    }
    min-height: 15%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 560px) {
    min-height: 300px;
  }
`;
