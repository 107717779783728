import React from "react";
import {
  Circle,
  MapContainer,
  TileLayer,
  Rectangle,
  LayersControl,
  Marker,
  LayerGroup,
  Popup,
  FeatureGroup,
} from "react-leaflet";
import styled from "styled-components";

function Map1() {
  const L = require("leaflet");
  const position = { lat: 30.428480398828107, lng:  -9.597313996784024};
  const GreenMarker = new L.Icon({
    iconUrl: "/Social/GreenMarker.svg",
  });
  const BlueMarker = new L.Icon({
    iconUrl: "/Social/BlueMarker.svg",
  });
  const OrangeMarker = new L.Icon({
    iconUrl: "/Social/OrangeMarker.svg",
  });
  const RedMarker = new L.Icon({
    iconUrl: "/Social/RedMarker.svg",
  });
  const YellowMarker = new L.Icon({
    iconUrl: "/Social/YellowMarker.svg",
  });

  const markerPositions: {
    position: any | any[];
    icon: any;
    title: string;
    titleColor: string;
  }[] = [
    {
      position: [48.6853091, 2.2587767],
      icon: GreenMarker,
      title: "Hosting",
      titleColor: "#4AC9A3",
    },
    {
      position: [48.672, 2.2987767],
      icon: OrangeMarker,
      title: "Retirement home",
      titleColor: "#FF6E01",
    },
    {
      position: [48.6543091, 2.2787767],
      icon: YellowMarker,
      title: "Residence",
      titleColor: "#FFE500",
    },
    {
      position: [48.6543091, 2.2406767],
      icon: BlueMarker,
      title: "Day care center",
      titleColor: "#0C7FDA",
    },
    {
      position: [48.6723091, 2.2307767],
      icon: RedMarker,
      title: "CLIC",
      titleColor: "#FF4B55",
    },
  ]; // khasni nzid array dyal positions hnaya
  return (
    <Map>
      <div className="details">
        <div className="mapTitle">
          <p>Map</p>
        </div>
        <div className="legend legend1">
          <p className="p1">
            Day care center
             </p>
          <p className="p2">
            Hosting
          </p>
          <p className="p3">  
            Residence
          </p>
          <p className="p4">
            Retirement home
          </p>
          <p className="p5">  
            CLIC
          </p>
        </div>
      </div>
      <MapContainer
        style={{
          width: "90%",
          height: "90%",
          border: "1px solid #18B6C0",
          zIndex: 1,
        }}
        center={position}
        zoom={13}
        scrollWheelZoom={true}
      >
        <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
        {markerPositions.map((element) => (
          <Marker position={element.position} key={1} icon={element.icon}>
            <Popup>
              <p style={{color:`${element.titleColor}`, fontWeight: 1000}}>{element.title}</p>
              <p className="popupDescription">
                Lot {element.position[0]} | Long {element.position[1]}
              </p>
            </Popup>
          </Marker>
        ))}

        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
          <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
      </MapContainer>
      <div className="legend legend2">
        <p className="p1">
          Day care center
        </p>
        <p className="p2">
          Hosting
        </p>
        <p className="p3">
          Residence
        </p>
        <p className="p4">
          Retirement home
        </p>
        <p className="p5">
          CLIC
        </p>
      </div>
    </Map>
  );
}

export default Map1;

const Map = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 1rem 0;
  height: 100%;
  .details {
    display: flex;
    justify-content: space-between;
    width: 90%;
    .legend1 p {
      font-size: 14px;
    }
    margin-bottom: 2rem;
  }
  .mapTitle {
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    p {
      font-weight: 700;
      margin: 0%;
    }
  }

  .legend {
    display: flex;
    gap: 1rem;
    p {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0%;
    }

    p::before {
      margin-right: 5px;
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background-color: #0c7fda;
    }
    .p2::before {
      background-color: #4ac9a3;
    }
    .p3::before {
      background-color: #ffe500;
    }
    .p4::before {
      background-color: #ff6e01;
    }
    .p5::before {
      background-color: #ff4b55;
    }
  }

  .legend2 {
    display: none;
  }

  @media screen and (max-width: 1700px) {
    .mapTitle p {
      font-size: 18px !important;
    }
    .details {
      p {
        font-size: 15px;
      }
      .legend1 {
        display: none;
      }
      .mapTitle p {
        text-align: center;
        font-size: 14px;
      }
    }
    .legend2 {
      display: flex !important;
      justify-content: space-around;
      width: 90%;
      @media screen and (max-width: 425px) {
        font-size: 12px;
        p::before {
          height: 10px;
          width: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 755px) {
    .legend2 {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0.1rem;
    }
    .legend {
      p {
        height: min-content;
      }
    }
  }
  min-width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
