import React, { useState } from "react";
import styled from "styled-components";
import Card from "./Card";

function Dots() {
  return (
    <DotsStyle>
      <li></li>
      <li></li>
      <li></li>
    </DotsStyle>
  );
}

export default function Cards() {
  const [arr, setArr] = useState([
    {
      image: "./robinetInter.svg",
      titleColor: "rgba(24, 182, 192, 0.24)",
      descriptionColor: "#12B68D",
      borderColor: "1px solid #18B6C0",
      title: "Green Space",
      titleDescription: "Solenoid Valve E105",
      content: "Water Leak",
      date: "21/05/2002 10:29",
    },
    {
      image: "./toolsInter.svg",
      titleColor: "rgba(241, 79, 84, 0.2)",
      descriptionColor: "#F14F54",
      borderColor: "1px solid #F14F54",
      title: "Security",
      titleDescription: "IP Camera IP003",
      content: "Malfunction",
      date: "19/01/2024 08:45",
    },
    {
      image: "./qualityInter.svg",
      titleColor: "rgba(24, 182, 192, 0.24)",
      descriptionColor: "#12B68D",
      borderColor: "1px solid #18B6C0",
      title: "Air Quality",
      titleDescription: "Air Quality Sensor SM015",
      content: "NA Value",
      date: "21/01/2024 10:29",
    },
    {
      image: "./electriciteInter.svg",
      titleColor: "rgba(241, 79, 84, 0.2)",
      descriptionColor: "#F14F54",
      borderColor: "1px solid #F14F54",
      title: "Smart Lighting",
      titleDescription: "Lux Sensor",
      content: "Voltage Drop",
      date: "21/01/2024 10:29",
    },
  ]);
  return (
    <Container>
      <div className="container__head">
        <p className="chartTitle">List of Interventions</p>
        <button className="plusDeDetails">More Details</button>
        <Dots />
      </div>
      <CardsStyle>
        {arr.map((element) => (
          <Card
            image={element.image}
            titleColor={element.titleColor}
            descriptionColor={element.descriptionColor}
            borderColor={element.borderColor}
            title={element.title}
            titleDescription={element.titleDescription}
            content={element.content}
            date={element.date}
          />
        ))}
      </CardsStyle>
    </Container>
  );
}

const CardsStyle = styled.div`
  display: grid;
  height: 90%;
  justify-content: center;
  grid-template-columns: 1fr;
  padding: 12px;
  @media (min-width: 760px) {
    max-width: 1000px;
    gap: 1%;
    grid-template-columns: 1fr 1fr;
  }
`;

const Container = styled.div`
  background-color: #fff;
  margin: 0;
  padding: 0;
  /* max-width: 900px;
  min-width: 30%; */

  .plusDeDetails {
    color: #18b6c0;
    font-weight: 600;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    @media screen and (max-width: 425px) {
      p {
        font-size: 15px;
      }
      .plusDeDetails {
        display: none;
      }
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

const DotsStyle = styled.ul`
  margin: 0;
  display: none;
  li {
    background: black;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    list-style: none outside none;
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;
