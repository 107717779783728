import React from "react";
import styled from "styled-components";

function Dots() {
  return (
    <DotsStyle>
      <li></li>
      <li></li>
      <li></li>
    </DotsStyle>
  );
}

export default function Card({
  image,
  titleColor,
  descriptionColor,
  borderColor,
  title,
  titleDescription,
  content,
  date,
}: any) {
  return (
    <CardStyle>
      <div
        className="title"
        style={{
          backgroundColor: `${titleColor}`,
          color: `${descriptionColor}`,
          borderBottom: `${borderColor}`,
        }}
      >
        <div className="description">
          <p className="__title">{title}</p>
          <p className="__description">{titleDescription}</p>
        </div>
        <Dots />
      </div>
      <div className="card__content">
        <img src={image} alt="" width={42} height={42} />
        <p>{content}</p>
      </div>
      <hr />
      <p className="date">{date}</p>
    </CardStyle>
  );
}

const DotsStyle = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 0;
  /* height: 100%; */
  li {
    background: black;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    list-style: none outside none;
  }
`;

const CardStyle = styled.div`
  p {
    margin: 0;
    font-size: 12px;
  }
  /* max-width: 400px; */
  .title {
    padding: 0.7rem;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .description {
      .__description {
        font-weight: 500;
      }
    }
  }
  .date {
    text-align: right;
  }
  .card__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 15px;
    align-items: center;
    margin-top: 15px;
    /* padding: 1.5rem; */
    img {
      max-width: 80px;
      height: 42px;
    }
    p {
      max-width: 200px;
      font-size: 17px;
      font-weight: 600;
    }
  }
  .date {
    font-weight: 500;
    padding: 1.5rem;
  }
`;

//rgba(24, 182, 192, 0.24)"} Title COlor
