import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import DropDown from "../dropdown/Dropdown";
let windowheight = window.innerHeight;
let windowwidth = window.innerWidth;
const MySidebar = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  .logo_content {
    @media only screen and (max-width: 829px) {
      display: none;
    }
    display: flex;
    .logo {
      justify-content: center;
      color: #fff;
      display: flex;
      height: 50px;
      width: 100%;
      padding: 4px;
      margin-top: 17px;
      i {
        font-size: 28px;
        margin-right: 5px;
      }
      .logo_name {
        font-size: 28px;
        font-weight: 400;
      }
    }
  }
  .divlist {
    height: 85%;
    width: 100%;
    overflow: auto;
    /* height: 100vh; */
    margin-top: -6px;
    overflow-y: hidden;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(91, 147, 255, 0.12);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #5d7285;
    }
    ul {
      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      padding: 5px;
      margin-top: 24px;
      width: 100%;
      overflow: auto;
      height: -webkit-fill-available;
      overflow-x: hidden;
      .linedashed {
        border: 1px dashed #e8e8ea;
        margin-top: 16px;
        margin-bottom: 12px;
        width: 211px;
        margin-left: 9px;
      }
      li {
        position: relative;
        width: 100%;
        list-style: none;
        line-height: 47px;
        transition: all 100ms ease-in-out;
        cursor: pointer;
        .bx-chevron-down {
          width: 100%;
          color: #5d7285;
          font-size: 25px;
          margin-left: 39px;
        }

        .dropdowncountainer {
          width: inherit;
          height: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 87%;
          margin-left: 16px;
          margin-top: 0px;
          height: auto;
          .dropdownelement {
            display: flex;
            .links_name {
              width: 100%;
              cursor: pointer;
              height: 30px;
              padding-left: 25px;
              color: #6f738a;
              display: flex;
              flex-direction: column;
              justify-content: center;
              font-size: 14px;
            }
            .textspan {
              width: 100%;
            }
          }
          .childdropdown {
            height: auto;
            transition: all 100ms ease-in;
            .links_name {
              font-size: 14px;
              line-height: 116%;
              display: flex;
              align-items: center;
              color: #6f738a;
              margin-left: 41px;
              margin-top: 10px;
              padding-bottom: 4px;
              cursor: pointer;
            }
            .links_name:hover {
              color: #18b6c0;
            }
          }
          .dropdownelement:hover {
            .links_name {
              color: #18b6c0;
            }
          }
        }
        .bx-chevron-up {
          width: 100%;
          color: #5d7285;
          font-size: 25px;
          margin-left: 39px;
        }
        .bx-search {
          position: absolute;
          z-index: 99;
          color: #5d7285;
          font-size: 29px;
          margin-top: 12px;
          left: 7px;
        }
        input {
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          border-radius: 12px;
          outline: none;
          border: none;
          background: rgba(91, 147, 255, 0.12);
          padding-left: 50px;
          font-size: 18px;
          color: #5d7285;
          margin-bottom: 16px;
        }
        a {
          padding-left: 7px;
          margin-top: 0px;
          color: #5d7285;
          display: flex;
          align-items: center;
          text-decoration: none;
          justify-content: stretch;
          transition: all 0.4s ease;
          border-radius: 12px;
          height: 40px;
          i {
            height: 50px;
            min-width: 50px;
            line-height: 50px;
            padding-left: 7px;
          }
          .links_name {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 116%;
            display: flex;
            align-items: center;
            color: #161e54;
            margin-left: 10px;
          }
        }
        a:hover {
          .links_name {
            color: #18b6c0;
          }
          background: #defdff;
          border-radius: 66px;
        }
      }
      .lineSepare {
        width: 84%;
        border: 1px solid rgba(93, 114, 133, 0.47);
      }
    }
  }
`;
const Sidebar = (props: any) => {
  const [clickmenu, setClickmenu] = useState(false);
  const Sendclick = (test: boolean) => {
    props.checksidebar(test);
  };
  const [clickdropdown, setClickdropdown] = useState(false);
  const [dropmobilite, setDropmobilite] = useState(false);
  const [dropCulturesport, setCulturesport] = useState(false);
  const [dropgestiondescontrat, setDropgestiondescontrat] = useState(false);
  return (
    <MySidebar className="">
      <div className="logo_content ">
        <div className="logo">
          <img src="/foooter.svg" alt="amine"></img>
        </div>
      </div>
      <div className="divlist ">
        <ul className="nav_list">
          <li>
            <a>
              <NavLink
                to="/Vuedynamiquesurleterritoire"
                onClick={() => {
                  if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                }}
              >
                <img src="/home.svg" alt="" style={{ width: "19px" }} />
                <span className="links_name">
                  {/* Vue dynamique sur le territoire */}
                  Dynamic view of the territory.
                </span>
              </NavLink>
            </a>
          </li>
          <li>
            <div className="linedashed"></div>
          </li>
          <li onClick={() => setClickdropdown(!clickdropdown)}>
            {/* <NavLink className={(navData) => navData.isActive ? "nav-active" : ""} to="/" > */}
            <NavLink to="/Hypervision">
              <a>
                <img src="/hypervision.svg" alt="" style={{ width: "19px" }} />
                {/* <span className="links_name">Hypervision</span> */}
                <span className="links_name">Hypervision</span>
                <i
                  className="bx bx-chevron-down"
                  style={{
                    fontSize: "19px",
                    marginLeft: "69px",
                    marginTop: "0px",
                  }}
                ></i>
              </a>
            </NavLink>
            {/* </a> */}
          </li>
          <li
            className=""
            style={
              !clickdropdown
                ? { maxHeight: "0", opacity: "0" }
                : { maxHeight: "fit-content" }
            }
          >
            <div className="dropdowncountainer">
              <span className="dropdownelement">
                <NavLink
                  className="links_name"
                  to="/Eclairage"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Éclairage public</span> */}
                  <span className="textspan">Public lighting</span>
                </NavLink>
              </span>
              <span
                className="dropdownelement"
                onClick={() => setDropmobilite(!dropmobilite)}
              >
                <NavLink
                  className="links_name"
                  to=""
                  style={{ display: "flex" }}
                >
                  {/* <span className="textspan">Mobilité</span> */}
                  <span className="textspan">Mobility</span>
                </NavLink>
                <i
                  className="bx bx-chevron-down"
                  style={{
                    fontSize: "19px",
                    marginLeft: "95px",
                    marginTop: "5px",
                  }}
                ></i>
              </span>
              <div
                className="childdropdown"
                style={
                  !dropmobilite
                    ? { maxHeight: "0", opacity: "0" }
                    : { maxHeight: "fit-content" }
                }
              >
                <NavLink
                  className="links_name"
                  to="/trafic"
                  style={{ marginTop: "0px" }}
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Trafic</span> */}
                  <span className="textspan">Traffic</span>
                </NavLink>
                <NavLink
                  className="links_name"
                  to="/Transportpublic"
                  style={{ marginTop: "0px" }}
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Transport public</span> */}
                  <span className="textspan">Public transport</span>
                </NavLink>
                <NavLink
                  className="links_name"
                  to="/ponctualite"
                  style={{ marginTop: "0px" }}
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Ponctualité</span> */}
                  <span className="textspan">Punctuality</span>
                </NavLink>
                <NavLink
                  className="links_name"
                  to="/stationnement"
                  style={{ marginTop: "0px" }}
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Stationnement</span> */}
                  <span className="textspan">Parking</span>
                </NavLink>
              </div>
              <span className="dropdownelement" style={{ zIndex: 11 }}>
                <NavLink
                  className="links_name"
                  to="/qualitedair"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Qualité de l’air</span> */}
                  <span className="textspan">Air quality</span>
                </NavLink>
              </span>
              <span className="dropdownelement" style={{ zIndex: 11 }}>
                <NavLink
                  className="links_name"
                  to="/gestiondedechet"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Gestion de déchets</span> */}
                  <span className="textspan">Waste management</span>
                </NavLink>
              </span>
              <span className="dropdownelement" style={{ zIndex: 11 }}>
                <NavLink
                  className="links_name"
                  to="/voiree"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Voiries</span> */}
                  <span className="textspan">Roads</span>
                </NavLink>
              </span>
              <span className="dropdownelement" style={{ zIndex: 11 }}>
                <NavLink
                  className="links_name"
                  to="/assainissement"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Assainissement</span> */}
                  <span className="textspan">Sanitation</span>
                </NavLink>
              </span>
              <span className="dropdownelement" style={{ zIndex: 11 }}>
                <NavLink
                  className="links_name"
                  to="/patrimoine"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Patrimoine</span> */}
                  <span className="textspan">Heritage</span>
                </NavLink>
              </span>
              <span className="dropdownelement" style={{ zIndex: 11 }}>
                <NavLink
                  className="links_name"
                  to="/social"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Social</span> */}
                  <span className="textspan">Social</span>
                </NavLink>
              </span>
              <span
                className="dropdownelement"
                onClick={() => setCulturesport(!dropCulturesport)}
                style={{ zIndex: 11 }}
              >
                {/* <NavLink className={(navData) => navData.isActive ? "nav-active" : ""} to="/patrimoine"> */}
                <div
                  className="links_name"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-7px",
                    marginBottom: "10px",
                  }}
                >
                  <span
                    className="textspan"
                    style={{ width: "67%", marginLeft: "-18px" }}
                  >
                    {/* Culture et sport */}
                    Culture and sport
                  </span>
                  <span>
                    <i
                      className="bx bx-chevron-down"
                      style={{
                        fontSize: "19px",
                        marginTop: "0px",
                        marginLeft: "26px",
                      }}
                    ></i>
                  </span>
                </div>

                {/* </NavLink> */}
              </span>

              {/* <span className="dropdownelement" style={{zIndex: 11}}> */}

              <div
                className="childdropdown"
                style={
                  !dropCulturesport
                    ? { maxHeight: "0", opacity: "0" }
                    : { maxHeight: "fit-content" }
                }
              >
                <NavLink
                  className="links_name"
                  to="/Evenement"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Evenement</span> */}
                  <span className="textspan">Event</span>
                </NavLink>
                <NavLink
                  className="links_name"
                  to="/equipement"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Equipement</span> */}
                  <span className="textspan">Equipment</span>
                </NavLink>
              </div>
              {/* </span> */}
              <span className="dropdownelement" style={{ zIndex: 11 }}>
                <NavLink
                  className="links_name"
                  to="/espacevert"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <span className="textspan">Espaces verts</span> */}
                  <span className="textspan">Green spaces</span>
                </NavLink>
              </span>
            </div>
          </li>
          <li>
            <div className="linedashed"></div>
          </li>
          <li>
            {/* <NavLink className={(navData) => navData.isActive ? "nav-active" : ""} to="/"  > */}
            <a>
              <NavLink
                to="/gestiondesintervention"
                onClick={() => {
                  if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                }}
              >
                <img src="/gestion.svg" alt="" style={{ width: "19px" }} />
                {/* <span className="links_name">Gestion des interventions</span> */}
                <span className="links_name">Intervention management</span>
              </NavLink>
            </a>
            {/* </NavLink> */}
            {/* </a> */}
          </li>
          <li
            onClick={() => setDropgestiondescontrat(!dropgestiondescontrat)}
            style={{ marginLeft: "7px" }}
          >
            <a>
              {/* <NavLink to="/gestiondescontart"  > */}
              <img
                src="/gestiondescontart.svg"
                alt=""
                style={{ width: "18px" }}
              />
              <div className="links_name">
                <span className="textspan" style={{ whiteSpace: "nowrap" }}>
                  {/* Gestion des contrats */}
                  Contract management
                </span>
                <i
                  className="bx bx-chevron-down"
                  style={{
                    fontSize: "19px",
                    marginTop: "0px",
                    marginLeft: "26px",
                  }}
                ></i>
              </div>
              <span></span>

              {/* </NavLink> */}
            </a>
          </li>

          <li
            style={
              !dropgestiondescontrat
                ? { maxHeight: "0", opacity: "0" }
                : { maxHeight: "fit-content" }
            }
          >
            <div className="dropdowncountainer">
              <span className="dropdownelement">
                <span className="links_name">
                  <NavLink
                    to="/general"
                    onClick={() => {
                      if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                    }}
                  >
                    {/* Général */}
                    General
                  </NavLink>
                </span>
              </span>
              <span className="dropdownelement">
                <span
                  className="links_name"
                  onClick={() => {
                    if (windowwidth <= 829) Sendclick(!props.sidebaricon);
                  }}
                >
                  {/* <NavLink to="/budget">Budget</NavLink> */}
                  <NavLink to="/budget">Budget</NavLink>
                </span>
              </span>
            </div>
          </li>
          {/* <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <NavLink className={(navData) => navData.isActive ? "nav-active" : ""} to="/page2"  >
                            <span className="links_name">Dashboard</span>
                        </NavLink>
                    </a>
                </li> */}
          {/* <li onClick={() => setClickdropdown(!clickdropdown)}>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                        {
                            !clickdropdown ?
                                <i className='bx bx-chevron-down' onClick={() => setClickdropdown(!clickdropdown)}></i>
                                :
                                <>
                                    <i className='bx bx-chevron-up' onClick={() => setClickdropdown(!clickdropdown)}></i>
                                </>
                        }
                    </a>
                </li> */}

          {/* <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>

                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li> */}
          {/* <hr className="lineSepare" />
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bx bx-grid-alt' ></i>
                        <span className="links_name">Dashboard</span>
                    </a>
                </li> */}
        </ul>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src="/DigieyeLogo.svg" alt="" style={{ width: "60%" }} />
      </div>
    </MySidebar>
  );
};
export default Sidebar;
