import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  Marker,
  Popup,
  useMapEvents,
  Polyline,
} from "react-leaflet";

import styled from "styled-components";

function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMapEvents({
    click(e) {
      console.log(e.latlng);
      map.locate();
    },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}


function Map1() {
  const L = require("leaflet");
  const position = { lat: 30.428480398828107, lng:  -9.597313996784024};
  const parkingIcon = new L.Icon({
    iconUrl: "/icons/ParkingIcon.png",
    // iconUrl: "/Social/GreenMarker.svg",
  });
  const markerPositions: {
    position: any | any[];
    icon: any;
    description: string;
  }[] = [
    {
      position: [48.85708817500348, 2.349403176600307],
      icon: parkingIcon,
      description: "61% Available",
    },
    {
      position: [48.85537635655868, 2.346449192528755],
      icon: parkingIcon,
      description: "93% Available",
    },
    {
      position: [48.85585637753356, 2.34299255253204],
      icon: parkingIcon,
      description: "25% Available",
    },
    {
      position: [48.85505163387744, 2.3410710042108995],
      icon: parkingIcon,
      description: "52% Available",
    },
    {
      position: [48.85257378928073, 2.342670505327376],
      icon: parkingIcon,
      description: "47% Available",
    },
  ];
  return (
    <Map>
      <div className="details">
        <div className="searchPostion">
            <input placeholder="Search for position"></input>
          <img src="/Patrimoine/Search.svg" alt="" />
        </div>
        <button className="exporterMap">
          More
          <img src="./Evenement/RightArrow.svg" alt="" />
        </button>
      </div>
      <MapContainer
        style={{
          width: "97%",
          height: "100%",
          border: "1px solid #18B6C0",
          zIndex: 1,
        }}
        center={position}
        zoom={10}
        scrollWheelZoom={true}
      >
        <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
        {markerPositions.map((element) => (
          <Marker
            position={element.position}
            key={1}
            icon={element.icon}
          >
            <Popup>
              <p>{element.description}</p>
            </Popup>
          </Marker>
        ))}
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
          <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <LocationMarker />
      </MapContainer>
    </Map>
  );
}

export default Map1;

const Map = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  .details {
    display: flex;
    justify-content: space-between;
    width: 90%;
    .legend1 p {
      font-size: 14px;
    }
    margin-bottom: 2rem;
  }
  .mapTitle {
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    p {
      font-weight: 700;
      margin: 0%;
    }
  }

  .legend {
    display: flex;
    gap: 1rem;
    p {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0%;
    }
  }

  .legend2 {
    display: none;
  }

  @media screen and (max-width: 1700px) {
    .mapTitle p {
      font-size: 18px !important;
    }
    .details {
      p {
        font-size: 15px;
      }
      .legend1 {
        display: none;
      }
      .mapTitle p {
        text-align: center;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 755px) {
    .legend2 {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0.1rem;
    }
    .legend {
      p {
        height: min-content;
      }
    }
  }

  width: 100%;
  height: 400px;

  .searchPostion {
    display: flex;
    gap: 0.4rem;
    background-color: #fff;
    min-height: 35px;
    justify-content: space-around;
    box-shadow: 0px 1px 4p x rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    border: 0.4px solid #18b6c0;
    input {
      width: 75%;
      ::placeholder {
        color: rgba(80, 83, 99, 0.25);
      }
    }
  }

  .exporterMap {
    color: #18b6c0;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  @media screen and (max-width: 470px) {
    .exporterMap {
      img {
        display: none;
      }
    }
    .searchPostion {
      width: 70%;
    }
  }
`;
