import React, { useEffect, useState } from 'react';

import './App.css'
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import DefaultLayout from './components/layouts/defaultlayout';
import Login from './pages/login';
import { chart } from 'highcharts';
// import { Line } from "react-chartjs-2";

import {
  BarController,
  LinearScale,
  BarElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
// import 'chartjs-adapter-date-fns';
// import { enUS } from 'date-fns/locale';

import { ReactChart } from 'chartjs-react';

// Register modules,
// this example for time scale and linear scale
ReactChart.register(BarController, LinearScale, BarElement, TimeScale, Tooltip);

// options of chart similar to v2 with a few changes
// https://www.chartjs.org/docs/next/getting-started/v3-migration/
const chartOption: any = {
  scales: {
    x: {
      type: 'time',
      adapters: {
        date: 'enUS',
      },
    },
    y: {
      type: 'linear',
    },
  },
};

// data of chart similar to v2, check the migration guide
const chartData : any= {};

const BarChart = () => {
  return (
    <ReactChart
      type="bar"
      data={chartData}
      options={chartOption}
      height={400}
    />
  );
};

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)"
    },
    {
      label: "Second dataset",
      data: [33, 25, 35, 51, 54, 76],
      fill: false,
      borderColor: "#742774"
    }
  ]
};

const App = () => {
  const [userConnecter, setUserConnecter] = useState<any>();

  localStorage.getItem("userConnecter");
  return (
    <div className="">
      <BrowserRouter>
        <Switch>
          <Route path='/login'
            component={Login}></Route>
          <Route path='/'
            component={DefaultLayout}></Route>
        </Switch>
      </BrowserRouter>
    </div>
    // <DefaultLayout />
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <Counter />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <span>
    //       <span>Learn </span>
    //       <a
    //         className="App-link"
    //         href="https://reactjs.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         React
    //       </a>
    //       <span>, </span>
    //       <a
    //         className="App-link"
    //         href="https://redux.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Redux
    //       </a>
    //       <span>, </span>
    //       <a
    //         className="App-link"
    //         href="https://redux-toolkit.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Redux Toolkit
    //       </a>
    //       ,<span> and </span>
    //       <a
    //         className="App-link"
    //         href="https://react-redux.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         React Redux
    //       </a>
    //     </span>
    //   </header>
    // </div>
  );
}

export default App;
