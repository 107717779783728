import React from "react";
// import { Badge } from "../utils";
import Chart from "react-apexcharts";
import styled from "styled-components";

const MyTable = styled.table`
  border-collapse: separate;
  border: solid black 1px;
  border-radius: 6px;
  width: 100%;
  th {
    border-collapse: separate;
    /* border-top:solid black 1px; */
    border-radius: 6px;
  }
  td {
    border-collapse: separate;
    border-top: solid black 1px;
    /* border-radius:6px; */
  }
`;

const Badge = (props: any) => {
  return (
    <Mydiv
      className="badges__card_item"
      style={{
        minHeight: "100px",
      }}
    >
      <div
        className="my-icon col-4 d-flex rounded"
        style={{ minWidth: "100px" }}
      >
        <img
          className="p-1"
          src={props.src}
          alt=""
          style={{ maxWidth: "60px", marginLeft: "auto", marginRight: "auto" }}
        />
      </div>
      <div className="col d-flex flex-column justify-content-center rounded">
        <div className="row">
          <div className="col font-weight-bold" style={{ fontWeight: "bold" }}>
            {props.value}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span>{props.name}</span>
          </div>
        </div>
      </div>
    </Mydiv>
  );
};

const Mydiv = styled.div`
  min-width: 200px;
  max-width: 300px;
  filter: drop-shadow(0px 3.31035px 4px rgba(0, 0, 0, 0.14));
  div.my-icon {
    background-color: #42728d;
  }
  div.my-icon:hover {
    background-color: #18b6c0;
  }
`;

const TotalBudget = () => {
  return (
    <Chart
      type="bar"
      height="380px"
      series={[
        {
          name: "Lighting",
          data: [7, 4, 2, 7, 11, 7, 2, 7, 11, 7],
        },
        {
          name: "Waste",
          data: [11, 10, 22, 18, 8, 8, 22, 18, 8, 8],
        },
        {
          name: "Roads",
          data: [12, 16, 6, 5, 11, 15, 6, 5, 11, 15],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: ["#CBF2F4", "#EA759E", "#54C3CA", "#18B6C0"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          background: "#fff",
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "Jan",
            "Fev",
            "Mar",
            "Avr",
            "Mai",
            "Jui",
            "Juil",
            "Aoa",
            "Sep",
            "Oct",
          ],
          labels: {
            show: true,
          },
        },
        legend: {
          show: true,
          markers: {
            radius: 50,
          },
        },
        grid: {
          show: false,
        },
        responsive: [{}],
      }}
    />
  );
};

const NiveauRisque = () => {
  return (
    <Chart
      type="bar"
      height="200px"
      series={[
        {
          name: "Completed",
          data: [1, 1],
        },
        {
          name: "In Planned",
          data: [3, 4],
        },
        {
          name: "In progress",
          data: [0, 0],
        },
        {
          name: "Late",
          data: [3, 1],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: ["#86DFE4", "#18B6C0", "#5090AC", "#426579"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: ["Suppliers", "Contracts"],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            align: "left",
            style: {
              fontSize: "1em",
              fontWeight: "600",
            },
          },
        },
        legend: {
          show: false,
          horizontalAlign: "center",
          markers: {
            radius: 20,
          },
        },
        grid: {
          show: false,
        },
      }}
    />
  );
};

const Fournisseurs = () => {
  const series = [12, 13, 75];
  const options: any = {
    colors: ["#5090AC", "#EA759E", "#18B6C0"],
    expandOnClick: true,
    chart: {
      type: "pie",
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["AFEL", "GDF", "GEFL"],
    legend: {
      show: true,
      // show: true,
      position: "right",
    },
    plotOptions: {
      pie: {
        customScale: 1,
        pie: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: false,
              formatter: (e: any) => console.log(e),
            },
          },
        },
      },
    },
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="pie" height="350" />
    </div>
  );
};

const Categories = () => {
  const series = [12, 13, 75];
  const options: any = {
    colors: ["#5090AC", "#EA759E", "#18B6C0"],
    expandOnClick: true,
    chart: {
      type: "pie",
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Leasing", "Supply", "Management"],
    legend: {
      show: true,
      position: "right",
    },
    plotOptions: {
      pie: {
        customScale: 1,
        pie: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: false,
              formatter: (e: any) => console.log(e),
            },
          },
        },
      },
    },
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="pie" height="350" />
    </div>
  );
};

const Budget = () => {
  return (
    <BudgetStyle>
      <BadgesStyle>
        <div className="badges__card">
          <Badge
            value="16.6 m"
            name="Total contract budget"
            src="/equipement/Total.svg"
          />
        </div>
        <div className="badges__card">
          <Badge value="12" name="Category" src="/budget/icon/Categorie.svg" />
        </div>
        <div className="badges__card">
          <Badge
            value="112"
            name="Suppliers"
            src="/budget/icon/Fournisseurs.svg"
          />
        </div>
        <div className="badges__card">
          <Badge
            value="73"
            name="Total contracts"
            src="/budget/icon/TotalContrats.svg"
          />
        </div>
        <div className="badges__card align-items-center">
          <Mydiv className="d-flex">
            <div className="col-6">
              <div
                className="row justify-content-center"
                style={{ fontSize: "2em" }}
              >
                09
              </div>
              <div
                className="row justify-content-center"
                style={{ wordWrap: "break-word" }}
              >
                Action
                <br />
                opened
              </div>
            </div>
            <div className="col-6 mx-2">
              <div
                className="row justify-content-center"
                style={{ fontSize: "2em" }}
              >
                20
              </div>
              <div className="row justify-content-center">
                Action
                <br />
                finished
              </div>
            </div>
          </Mydiv>
        </div>
      </BadgesStyle>
      <SecondRowStyle>
        <div className="secondRow__leftSide">
          <div className="card p-3">
            <div className="d-flex justify-content-between">
              <div className="" style={{ fontWeight: "700" }}>
                Total budget consumed
              </div>
              <div
                className="d-flex"
                style={{ color: "#18B6C0", fontWeight: "600" }}
              >
                End date
                <img
                  src="/GestionDuContrats/arrow.svg"
                  alt=""
                  className="mx-2"
                />
              </div>
            </div>
            <div className="row">
              <TotalBudget />
            </div>
          </div>
        </div>
        <div className="secondRow__rightSide" style={{ gap: "30px" }}>
          <div className="secondRow__rightSide_top">
            <div className="card p-3">
              <div className="row">
                <div className="" style={{ fontWeight: "700" }}>
                  Risk level
                </div>
              </div>
              <div className="row">
                <NiveauRisque />
              </div>
            </div>
          </div>
          <div className="secondRow__rightSide_bottom">
            <div className="secondRow__rightSide_bottom_table">
              <table className="h-100">
                <tr>
                  <th>Company</th>
                  <th>Late</th>
                  <th>Currently</th>
                </tr>
                <tr>
                  <td>Suppliers</td>
                  <td>5</td>
                  <td>12</td>
                </tr>
                <tr style={{ borderTop: "solid 1px #A5B0D1" }}>
                  <td>Contracts</td>
                  <td>0</td>
                  <td>23</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SecondRowStyle>
      <ThirdRowStyle>
        <div className="ThirdRowStyle__first">
          <div className="ThirdRowStyle__first_item">
            <div>
              <div style={{ fontWeight: "700" }}>Budget</div>
              <div>Budget concentration by type of contract</div>
            </div>
            <div className="ThirdRowStyle__first_item_table">
              <div>
                <MyTable className="">
                  <tr style={{ color: "#18B6C0" }}>
                    <th>Top 10 contracts</th>
                    <th>%</th>
                  </tr>
                  <tr>
                    <td>AB - 32929929</td>
                    <td>39</td>
                  </tr>
                  <tr>
                    <td>AB - 28002202</td>
                    <td>39</td>
                  </tr>
                  <tr>
                    <td>AB - 28002202</td>
                    <td>39</td>
                  </tr>
                </MyTable>
              </div>
            </div>
          </div>
        </div>
        <div className="ThirdRowStyle__second">
          <div className="">
            <div className="">
              <div style={{ fontWeight: "700" }}>Suppliers</div>
            </div>
            <div className="">
              <Fournisseurs />
            </div>
          </div>
        </div>
        <div className="ThirdRowStyle__third">
          <div className="">
            <div className="">
              <div style={{ fontWeight: "700" }}>Categories</div>
            </div>
            <div className="">
              <div className="">Top 3 categories</div>
            </div>
            <div className="">
              <Categories />
            </div>
          </div>
        </div>
      </ThirdRowStyle>
    </BudgetStyle>
  );
};

const BadgesStyle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 780px) and (min-width: 551px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1245px) and (min-width: 781px) {
    grid-template-columns: repeat(3, 1fr);
  }
  gap: 1rem;
  .badges__card {
    background-color: white;
    .badges__card_item {
      display: flex;
      gap: 1rem;
    }
  }
`;

const SecondRowStyle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  .secondRow__leftSide {
    width: 50%;
  }
  .secondRow__rightSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .secondRow__rightSide_top {
    }
    .secondRow__rightSide_bottom {
      height: 150px;
      background-color: white;
      border: 1px solid #e5e7eb;
      padding: 1rem;
      .secondRow__rightSide_bottom_table {
        width: 100%;
        display: flex;
        overflow-x: auto;
        ::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        table {
          width: 100%;
          min-width: 400px;
        }
      }
    }
  }
  @media screen and (max-width: 880px) {
    flex-direction: column;
    .secondRow__leftSide {
      width: 100%;
    }
    .secondRow__rightSide {
      width: 100%;
    }
  }
`;

const ThirdRowStyle = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  gap: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .ThirdRowStyle__first,
  .ThirdRowStyle__second,
  .ThirdRowStyle__third {
    background-color: white;
  }
  .ThirdRowStyle__first {
    .ThirdRowStyle__first_item {
      padding: 1.5rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .ThirdRowStyle__first_item_table {
        width: 100%;
      }
    }
  }
  .ThirdRowStyle__second,
  .ThirdRowStyle__third {
    padding: 1.5rem 1rem;
  }
  @media screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 1024px) and (min-width: 751px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const BudgetStyle = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
`;
export default Budget;
