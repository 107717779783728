import React from 'react';

import Chart from "react-apexcharts";
import styled from 'styled-components';

export default function Agressionbar() {
  return <Chart
        type="bar"
        width='100%'
        height='90%'
        series={[
          {
            data: [4, 6, 7, 4, 1, 7, 2, 3, 5],
          },
        ]}
        options={{
          dataLabels: { enabled: false },
          colors: ["#18B6C0"],
          chart: {
            stacked: false,
            toolbar:{
              show: false
            }
          },
        //   title: {
        //     text: 'Consommation',
        //     offsetX: 0,
        //     offsetY: 0,
        //     style: {
        //         color: '#161E54',
        //         fontSize: '15px',
        //         fontFamily: 'Helvetica, Arial, sans-serif',
        //         fontWeight: 600,
        //     },
        // },
          yaxis: {
            show: true,
          },
  
        //   plotOptions: {
        //     bar: {
        //       horizontal: true
        //     }
        //   },
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [
                "Bus 1",
                "Bus 2",
                "Bus 3",
                "Bus 4",
                "Bus 5",
                "Bus 6",
                "Bus 7",
                "Bus 8",
                "Bus 9",
            ],
            labels: {
              show: true,
              // rotate: 320,
              // rotateAlways: true,
            },
          },
        }}
      />
}
