import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function StatusChart() {
  return (
    <Status>
      <div className="container__head">
        <p className="chartTitle">Status of interventions</p>
        <button className="exporter">
          <img src="./download.svg" alt="" />
          Export
        </button>
      </div>
      <Chart
        type="pie"
        height="530px"
        series={[12, 13, 10, 75]}
        options={{
          labels: ["Terminée", "Planifiée", "En cours", "En retard"],
          colors: ["#42728D", "#FBACC8", "#CBF2F4", "#3CD1DB"],
          legend: {
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
        }}
      />
    </Status>
  );
}

const Status = styled.div`
  /* max-width: 900px;
  min-width: 30%; */
  background-color: #fff;
  @media screen and (max-width: 425px) {
    .exporter {
      font-size: 0;
    }
  }
  .exporter {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    color: #18b6c0;
    font-weight: 600;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
