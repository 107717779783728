import React, { useState } from 'react'

import { MapContainer, TileLayer, Rectangle, LayersControl, Marker, LayerGroup, Popup, FeatureGroup } from 'react-leaflet'
import { useHistory } from 'react-router-dom';
function MapEclairage() {
    const L = require('leaflet');
    const Icon1 = L.icon({
        iconUrl: '/icons/mapicon.svg',
        iconSize: [30, 40],
        iconAnchor: [32, 64],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    // const Icon2 = L.icon({
    //     iconUrl: '/streat/icon2.svg',
    //     iconSize: [64, 64],
    //     iconAnchor: [32, 64],
    //     popupAnchor: null,
    //     shadowUrl: null,
    //     shadowSize: null,
    //     shadowAnchor: null
    // });
    // const Icon3 = L.icon({
    //     iconUrl: '/streat/icon3.svg',
    //     iconSize: [64, 64],
    //     iconAnchor: [32, 64],
    //     popupAnchor: null,
    //     shadowUrl: null,
    //     shadowSize: null,
    //     shadowAnchor: null
    // });
    // const Icon4 = L.icon({
    //     iconUrl: '/streat/icon4.svg',
    //     iconSize: [64, 64],
    //     iconAnchor: [32, 64],
    //     popupAnchor: null,
    //     shadowUrl: null,
    //     shadowSize: null,
    //     shadowAnchor: null
    // });
    // const Icon5 = L.icon({
    //     iconUrl: '/streat/icon5.svg',
    //     iconSize: [64, 64],
    //     iconAnchor: [32, 64],
    //     popupAnchor: null,
    //     shadowUrl: null,
    //     shadowSize: null,
    //     shadowAnchor: null
    // });
    // const Icon6 = L.icon({
    //     iconUrl: '/streat/icon6.svg',
    //     iconSize: [64, 64],
    //     iconAnchor: [32, 64],
    //     popupAnchor: null,
    //     shadowUrl: null,
    //     shadowSize: null,
    //     shadowAnchor: null
    // });
    const fackLocalisation = [
        { position: { lat: 30.85341, lng: -9.2911701 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.2821701 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.2631701 }, name: Icon1 },
        { position: { lat: 30.87941, lng: -9.2441701 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.2651701 }, name: Icon1 },
        { position: { lat: 30.86641, lng: -9.3861701 }, name: Icon1 },
        { position: { lat: 30.83441, lng: -9.3811701 }, name: Icon1 },
        { position: { lat: 30.88041, lng: -9.35011701 }, name: Icon1 },
        { position: { lat: 30.87341, lng: -9.34611701 }, name: Icon1 },
        { position: { lat: 30.86341, lng: -9.2911701 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.2821701 }, name: Icon1 },
        { position: { lat: 30.82341, lng: -9.2631701 }, name: Icon1 },
        { position: { lat: 30.84341, lng: -9.2441701 }, name: Icon1 },
        { position: { lat: 30.86341, lng: -9.2651701 }, name: Icon1 },
        { position: { lat: 30.84341, lng: -9.3861701 }, name: Icon1 },
        { position: { lat: 30.80341, lng: -9.3811701 }, name: Icon1 },
        { position: { lat: 30.81341, lng: -9.35011701 }, name: Icon1 },
        { position: { lat: 30.80341, lng: -9.34611701 }, name: Icon1 },
        { position: { lat: 30.80341, lng: -9.2711701 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.2911701 }, name: Icon1 },
        { position: { lat: 30.81341, lng: -9.3511701 }, name: Icon1 },
        { position: { lat: 30.82341, lng: -9.3561701 }, name: Icon1 },
        { position: { lat: 30.83341, lng: -9.3581701 }, name: Icon1 },
        { position: { lat: 30.84341, lng: -9.3591701 }, name: Icon1 },
        { position: { lat: 30.86341, lng: -9.3521701 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.3541701 }, name: Icon1 },
        { position: { lat: 30.84341, lng: -9.3571701 }, name: Icon1 },
        { position: { lat: 30.83341, lng: -9.3531701 }, name: Icon1 },
        { position: { lat: 30.88341, lng: -9.3697767 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.3657767 }, name: Icon1 },
        { position: { lat: 30.876341, lng: -9.3607767 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.3667767 }, name: Icon1 },
        { position: { lat: 30.83341, lng: -9.4611701 }, name: Icon1 },
        { position: { lat: 30.8441, lng: -9.4697767 }, name: Icon1 },
        { position: { lat: 30.8661, lng: -9.4657767 }, name: Icon1 },
        { position: { lat: 30.87851, lng: -9.4607767 }, name: Icon1 },
        { position: { lat: 30.83445, lng: -9.4647767 }, name: Icon1 },
        { position: { lat: 30.88341, lng: -9.4611701 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.4697767 }, name: Icon1 },
        { position: { lat: 30.83341, lng: -9.4657767 }, name: Icon1 },
        { position: { lat: 30.84341, lng: -9.3607767 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.3911701 }, name: Icon1 },
        { position: { lat: 30.86341, lng: -9.3821701 }, name: Icon1 },
        { position: { lat: 30.87341, lng: -9.3631701 }, name: Icon1 },
        { position: { lat: 30.88341, lng: -9.3441701 }, name: Icon1 },
        { position: { lat: 30.89341, lng: -9.3051701 }, name: Icon1 },
        { position: { lat: 30.88341, lng: -9.3861701 }, name: Icon1 },
        { position: { lat: 30.87341, lng: -9.3811701 }, name: Icon1 },
        { position: { lat: 30.86341, lng: -9.3011701 }, name: Icon1 },
        { position: { lat: 30.85341, lng: -9.3611701 }, name: Icon1 },
        { position: { lat: 30.84341, lng: -9.3711701 }, name: Icon1 },
        { position: { lat: 30.83341, lng: -9.34511701 }, name: Icon1 },
        { position: { lat: 30.82341, lng: -9.3911701 }, name: Icon1 },
        { position: { lat: 30.83341, lng: -9.3561701 }, name: Icon1 },
        { position: { lat: 30.84341, lng: -9.3281701 }, name: Icon1 },
        { position: { lat: 30.86341, lng: -9.3091701 }, name: Icon1 },
    ]
    const position = { lat: 30.428480398828107, lng:  -9.597313996784024};
    const greenIcon = new L.Icon({
        iconUrl: '/assets/global/eclairage.svg',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
    const [clickicon, setClickicon] = useState(false);
    const his = useHistory();
    return <MapContainer
        style={{width: "100%", height: "100%", border: "1px solid #18B6C0", zIndex: 1 }}
        center={position} zoom={13} scrollWheelZoom={true}>
        <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
        {fackLocalisation.map((localisation, index) => {
            return (
                <Marker position={localisation.position} key={index} icon={localisation.name} eventHandlers={{
                    click: () => {
                        his.push('/details')
                    },
                }}>
                    {/* <Popup >
                        {"lat" + localisation.position.lat + "lng" + localisation.position.lng}
                    </Popup> */}
                </Marker>
            )

        })}
        <LayersControl position="topright">
            <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </LayersControl.BaseLayer>
        </LayersControl>
    </MapContainer>
}

export default MapEclairage