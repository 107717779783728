import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function PieChart() {
  return (
    <Chart
      type="pie"
      width="100%"
      height="135px"
      series={[12, 13, 75]}
      options={{
        colors: ["#42728D", "#FBACC8", "#3CD1DB"],
        plotOptions: {
          pie: {
            donut: {
              size: "100%",
            },
          },
        },
        labels: ["Scheduled", "In progress", "Completed"],
        legend: {
          show: true,
          itemMargin: {
            horizontal: 5,
            vertical: 7,
          },
        },
        dataLabels: {
          formatter: (val) => {
            return `${val}`;
          },
        },
        // labels: {
        //     show: false,
        //     // rotate: 320,
        //     // rotateAlways: true,
        //   },
      }}
    />
  );
}
