import React, { useEffect, useState } from "react";
import L, { LeafletMouseEvent } from "leaflet";
import Map from "./Map";
import { useMap } from "react-leaflet";
import styled from "styled-components";

function Search(props: any) {
  let value;
  let SelectDiv: any;
  let check = false;
  const options = `<input placeholder=${props.placeholder}></input><img src="/Patrimoine/Search.svg" alt="" />`;
  const createButtonControl = () => {
    const MapHelp = L.Control.extend({
      onAdd: (map: any) => {
        const helpDiv = L.DomUtil.create("div", "inputDiv");
        helpDiv.innerHTML = options;
        SelectDiv = helpDiv;
        SelectDiv.addEventListener("click", (e: any) => {});

        //a bit clueless how to add a click event listener to this button and then
        // open a popup div on the map
        return SelectDiv;
      },
    });
    return new MapHelp({ position: "topleft" });
  };
  useEffect(() => {
    const map = props.map;
    const control = createButtonControl();
    control.addTo(map);
    return () => {
      SelectDiv.remove();
    };
  }, []);
  return null;
}

function SearchInput(props: any) {
  const map = useMap();
  return (
        <Search map={map} placeholder={props.placeholder}/>
  )
}

export default SearchInput;
