import Carte from "./pages/Carte";
import Eclairage from "./components/Eclairage/Eclairage";
import Interventions from "./components/GestionDesInterventions /Interventions";
import Budget from "./components/GestionsDuContrats/Budget";
import General from "./components/GestionsDuContrats/Genetal";
import Global from "./components/Global/Global";
import Evenements from "./components/Hypervision/Evenements/Evenements";
import Patrimoine from "./components/Patrimoine/patrimoine";
import Ponctualite from "./components/Ponctualite/Ponctualite";
import Stationnement from "./components/Stationnement/Stationnement";
import Trafic from "./components/Trafic/Trafic";
import TravPub from "./components/travPub/travPub";
import Voiries from "./components/voiries/voiries";
import { AirQuality } from "./features/air-quality/AirQuality";
import { Assainissement } from "./features/assainissement/Assainissement";
import { Dechet } from "./features/dechets/Dechet";
import Page1 from "./pages/Page1";
import BarChart from "./components/charts/barchar";
import Securite from "./components/securite/Securite";
import Details from "./components/Eclairage/Details";
import Equipement from "./components/Hypervision/Equipements";
import Social from "./components/Social/Social";

const dashboardRoutes = [
  {
    path: "/Vuedynamiquesurleterritoire",
    name: "Vuedynamiquesurleterritoire",
    component: Carte,
  },
  {
    path: "/Hypervision",
    name: "Hypervision",
    component: Global,
  },
  {
    path: "/social",
    name: "Hypervision",
    component: Social,
  },
  {
    path: "/Eclairage",
    name: "EclairagePublic",
    component: Eclairage,
  },
  {
    path: "/trafic",
    name: "trafic",
    component: Trafic,
  },
  {
    path: "/Transportpublic",
    name: "Transportpublic",
    component: TravPub,
  },
  {
    path: "/details",
    name: "Transportpublic",
    component: Details,
  },
  {
    path: "/ponctualite",
    name: "ponctualite",
    component: Ponctualite,
  },
  {
    path: "/stationnement",
    name: "stationnement",
    component: Stationnement,
  },
  {
    path: "/gestiondedechet",
    name: "gestiondedechet",
    component: Dechet,
  },
  {
    path: "/voiree",
    name: "voiree",
    component: Voiries,
  },
  {
    path: "/assainissement",
    name: "assainissement",
    component: Assainissement,
  },
  {
    path: "/patrimoine",
    name: "patrimoine",
    component: Patrimoine,
  },
  {
    path: "/qualitedair",
    name: "qualitedair",
    component: AirQuality,
  },
  {
    path: "/equipement",
    name: "equipement",
    component: Equipement,
  },
  {
    path: "/Evenement",
    name: "Evenement",
    component: Evenements,
  },
  {
    path: "/espacevert",
    name: "espacevert",
    component: Page1,
  },
  {
    path: "/gestiondesintervention",
    name: "gestiondesintervention",
    component: Interventions,
  },
  {
    path: "/general",
    name: "general",
    component: General,
  },
  {
    path: "/securite",
    name: "general",
    component: Securite,
  },
  {
    path: "/budget",
    component: Budget,
  },
  {
    path: "/chart",
    component: BarChart,
  },
  {
    path: "/",
    component: Global,
  },
];
export default dashboardRoutes;
