import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function OccupationChart() {
  return (
    <Equipe>
      <div className="min__width">
        <div className="container__head">
          <p className="chartTitle">Occupancy rate</p>
          <button className="exporter">
            <img src="./Evenement/Warning.svg" alt="" />
          </button>
        </div>
        <Chart
          type="bar"
          height={250}
          series={[
            {
              data: [0, 33, 26, 23, 28, 20, 34, 42],
            },
          ]}
          options={{
            dataLabels: { enabled: false },
            colors: ["#FBACC8"],
            chart: {
              stacked: true,
              toolbar: {
                show: false,
              },
              background: "#fff",
            },
            tooltip: {
              followCursor: true,
            },
            xaxis: {
              categories: ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8"],
            },
            legend: {
              show: false,
            },
            title: {
              text: "Theaters",
            },
          }}
        />
      </div>
    </Equipe>
  );
}

const Equipe = styled.div`
  /* width: 100%; */
  min-height: 380px;
  background-color: #fff;
  grid-column: 1/2;
  grid-row: 1 / 2;
  overflow-x: auto;
  .exporter {
    color: #18b6c0;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  @media screen and (max-width: 700px) {
    .min__width {
      min-width: 500px;
    }
  }
  @media screen and (max-width: 865px) {
    grid-column: 1;
    grid-row: 1 / 2;
  }
  @media screen and (max-width: 1670px) and (min-width: 866px) {
    grid-column: 1/3;
    grid-row: 1 / 2;
  }
`;
