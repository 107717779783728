import { autocompleteClasses } from "@mui/material";
import React, { useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import { Badge } from "../utils";
// import ReactStars from "react-rating-stars-component";

const Stars = () => {
  return (
    <div>
      <i className="bx bxs-star" style={{ color: "#FFE600" }}></i>
      <i className="bx bxs-star" style={{ color: "#FFE600" }}></i>
      <i className="bx bxs-star" style={{ color: "#FFE600" }}></i>
      <i className="bx bxs-star" style={{ color: "#FFE600" }}></i>
      <i className="bx bxs-star" style={{ color: "#FFE600" }}></i>
    </div>
  );
};

const Materiel = () => {
  return (
    <Chart
      type="bar"
      height="380px"
      series={[
        {
          name: "In stock",
          data: [7, 4, 2, 7, 11, 7],
        },
        {
          name: "Under reparation",
          data: [11, 10, 22, 18, 8, 8],
        },
        {
          name: "Functional",
          data: [12, 16, 6, 5, 11, 15],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: ["#CBF2F4", "#EA759E", "#54C3CA", "#18B6C0"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          background: "#fff",
        },
        yaxis: {
          show: true,
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "Team 1",
            "Team 2",
            "Team 3",
            "Team 4",
            "Team 5",
            "Team 6",
            "Team 7",
            "Team 8",
            "Team 9",
            "Team 10",
          ],
          labels: {
            show: false,
          },
        },
        legend: {
          show: true,
          markers: {
            radius: 50,
          },
        },
        grid: {
          show: true,
        },
        responsive: [{}],
      }}
    />
  );
};

const DonutChart = () => {
  const series = [18, 45, 37];
  const options: any = {
    colors: ["#5090AC", "#EA759E", "#18B6C0"],
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Lighting", "Heating", "Filtering"],
    legend: {
      show: true,
      position: "bottom",
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: false,
              formatter: (e: any) => console.log(e),
            },
          },
        },
      },
    },
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="donut" height="350" />
    </div>
  );
};
const PieChart = () => {
  const series = [12, 13, 75, 5];
  const options: any = {
    colors: ["#5090AC", "#EA759E", "#18B6C0", "#CBF2F4"],
    expandOnClick: true,
    chart: {
      type: "pie",
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Conservatories", "Theaters", "Cinemas", "Pool"],
    legend: {
      show: true,
      position: "bottom",
    },
    plotOptions: {
      pie: {
        customScale: 1,
        pie: {
          size: "50%",
          labels: {
            show: true,
            total: {
              show: false,
              formatter: (e: any) => console.log(e),
            },
          },
        },
      },
    },
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="pie" height="350" />
    </div>
  );
};

const Equipement = () => {
  const prev = () => {
    const all = document.querySelectorAll(
      "#carouselExampleFade .carousel-item"
    );
    const len = all.length;
    let activeIndex = -1;

    all.forEach((e, i) => {
      if (e.classList.contains("active")) {
        // console.log("========");
        activeIndex = i;
        e.classList.remove("active");
      }
    });
    let prevElemIndex = -1;

    if (activeIndex == 0) prevElemIndex = len - 1;
    else prevElemIndex = activeIndex - 1;

    console.log(prevElemIndex);
    all[prevElemIndex].classList.add("active");
  };

  const next = () => {
    const all = document.querySelectorAll(
      "#carouselExampleFade .carousel-item"
    );
    const len = all.length;
    let activeIndex = -1;

    all.forEach((e, i) => {
      if (e.classList.contains("active") == true) {
        // console.log("========");
        activeIndex = i;
        e.classList.remove("active");
      }
    });

    let nextElemIndex = -1;

    if (activeIndex == len - 1) nextElemIndex = 0;
    else nextElemIndex = activeIndex + 1;

    all[nextElemIndex].classList.add("active");
  };

  return (
    <EquipementStyle>
      <CardsStyle>
        <div>
          <Badge value="135" name="Equipments" src="/equipement/Total.svg" />
        </div>
        <div>
          <Badge
            value="23"
            name="Swimming pools"
            src="/equipement/Piscines.svg"
          />
        </div>
        <div>
          <Badge value="11" name="Theatres" src="/equipement/Theatre.svg" />
        </div>
        <div>
          <Badge
            value="73"
            name="Libraries"
            src="/equipement/Bibliotheques.svg"
          />
        </div>
        <div>
          <Badge value="12" name="Cinemas" src="/equipement/Cinema.svg" />
        </div>
      </CardsStyle>
      <div className="row">
        <div className="col-lg mt-4">
          <div className="card p-3 h-100">
            {/* <img src="/equipement/Consommations.svg" alt="" /> */}
            <div className="row  mb-4" style={{ fontWeight: "700" }}>
              <div className="col">Consumption</div>
            </div>
            <div className="row">
              <div className="col-12 col-md-5">
                Filtres:
                <select
                  className="form-select form-select-lg mb-3 __select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>Days</option>
                </select>
                <select
                  className="form-select form-select-lg mb-3 __select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>Swimming pools</option>
                </select>
                <select
                  className="form-select form-select-lg mb-3 __select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>electricity</option>
                </select>
              </div>
              <div className="col-12 col-md-7">
                <DonutChart />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg mt-4">
          <div className="card p-3 h-100">
            {/* <img src="/equipement/Materiel.svg" alt="" /> */}
            <div className="row">
              <div className="col-6" style={{ fontWeight: "700" }}>
                Materials
              </div>
              <div className="col-6">
                <select
                  className="form-select form-select-lg mb-3 __select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>Conservatory</option>
                </select>
              </div>
            </div>
            <div className="row">
              <Materiel />
            </div>
          </div>
        </div>
        <div className="col-lg mt-4">
          <div className="card p-3 h-100">
            {/* <img src="/equipement/Evaluation Google.svg" alt="" /> */}
            <div className="row">
              <div className="col-6" style={{ fontWeight: "700" }}>
                Google rating
              </div>
              <div className="col-6">
                <select
                  className="form-select form-select-lg mb-3 __select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>Cinemas</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="row mt-1 mb-1">
                <div className="col-6">Cinema 1</div>
                <div className="col-6">
                  <Stars />
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-6">Cinema 2</div>
                <div className="col-6">
                  <Stars />
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-6">Cinema 3</div>
                <div className="col-6">
                  <Stars />
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-6">Cinema 4</div>
                <div className="col-6">
                  <Stars />
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-6">Cinema 5</div>
                <div className="col-6">
                  <Stars />
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-6">Cinema 6</div>
                <div className="col-6">
                  <Stars />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LastRowStyle>
        <div className="leftSide">
          <div className="card p-3 h-100">
            {/* <img src="/equipement/Investissements.svg" alt="" /> */}
            <div className="row mb-4" style={{ fontWeight: "700" }}>
              <div className="col">Investments</div>
            </div>
            <div className="row">
              <div className="col-12 col-md-5">
                Gross Costs Invested by equipment
                <select
                  className="form-select form-select-lg mb-3 __select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>Cinemas</option>
                </select>
              </div>
              <div className="col-12 col-md-7">
                <PieChart />
              </div>
            </div>
          </div>
        </div>
        <div className="rightSide" style={{ backgroundColor: "#FFF" }}>
          <div className="rightSide__left">
            <div className="row">
              <div className="col" style={{ fontWeight: "700" }}>
                General
              </div>
            </div>
            <div className="row">
              <div
                className="d-flex p-0 mb-2 justify-content-center"
                style={{
                  maxWidth: "200px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div>
                  <button
                    onClick={prev}
                    className=""
                    type="button"
                    data-bs-target="#carouselExampleFade"
                    data-bs-slide="prev"
                  >
                    <img src="/equipement/left.svg" alt="" />
                  </button>
                </div>
                <div
                  style={{
                    backgroundColor: "#D9D9D9",
                    borderRadius: "0.5em",
                    marginLeft: "1em",
                    marginRight: "1em",
                    color: "#6D6D6D",
                    padding: "0.40em",
                  }}
                >
                  Photo 1
                </div>
                <div>
                  <button
                    onClick={next}
                    className=""
                    type="button"
                    data-bs-target="#carouselExampleFade"
                    data-bs-slide="next"
                  >
                    <img src="/equipement/right.svg" alt="" />
                  </button>
                </div>
              </div>
              <div className="row rounded">
                <div
                  id="carouselExampleFade"
                  className="carousel slide carousel-fade"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="/equipement/pool.jfif"
                        className="d-block w-100"
                        alt=""
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="/equipement/pp.png"
                        className="d-block w-100"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rightSide__right">
            <div className="rightSide__right_selects">
              <div>
                <select
                  className="form-select form-select-lg mb-3 __select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>Cinemas</option>
                  <option>Theaters</option>
                  <option>Cinemas</option>
                  <option>Conservatories</option>
                </select>
              </div>
              <div>
                <select
                  className="form-select form-select-lg mb-3 __select"
                  aria-label=".form-select-lg example"
                >
                  <option selected>Clamart swimming pools</option>
                </select>
              </div>
            </div>
            <div className="rightSide__right_video">
              <div className="row">
                <video src="/equipement/1VeA.mp4" controls></video>
              </div>
            </div>
          </div>
        </div>
      </LastRowStyle>
    </EquipementStyle>
  );
};

const EquipementStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  .__select {
    @media screen and (max-width: 400px) {
      font-size: 11px;
    }
    @media screen and (max-width: 1650px) and (min-width: 401px) {
      font-size: 14px;
    }
  }
`;

const CardsStyle = styled.div`
  width: 100%;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(5, 1fr);

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 800px) and (min-width: 551px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1270px) and (min-width: 801px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const LastRowStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1.5rem;
  .leftSide {
    width: 100%;
  }
  .rightSide {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .rightSide__left,
    .rightSide__right {
      padding: 1rem;
    }
    .rightSide__right {
      .rightSide__right_selects {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
        @media screen and (max-width: 550px) {
          gap: 0.4rem;
          grid-template-columns: 1fr;
        }
      }
      .rightSide__right_video {
        width: 100%;
      }
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

export default Equipement;
