import React from "react";
import styled from "styled-components";
import MapContainer from "./components/Map";
import { RamassageChart } from "./components/RamassageChart";
import { StatistiquesChart } from "./components/StatistiquesChart";
import "./style.css";
export function Dechet() {
  return (
    <ContainerStyle>
      <h5 className="dark-bleu">
        {/* <i className="fa fa-pie-chart"></i> Gestion de déchets */}
        <i className="fa fa-pie-chart"></i> Waste management
      </h5>
      <ContentStyle>
        <div className="__leftSide">
          <div style={{ width: "100%", overflowX: "auto" }}>
            <RamassageChart />
          </div>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <StatistiquesChart />
          </div>
        </div>
        <div className="__rightSide">
          <MapContainer />
        </div>
      </ContentStyle>
    </ContainerStyle>
  );
}

const ContainerStyle = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  /* box-sizing: border-box; */
`;

const ContentStyle = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  .__leftSide,
  .__rightSide {
    width: 50%;
  }
  .__leftSide {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .__rightSide {
    padding: 1rem;
    background-color: white;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    .__leftSide,
    .__rightSide {
      width: 100%;
    }
  }
`;
