import React, { useState } from "react";
import styled from "styled-components";

export default function TravailCard() {
  const [data, setData] = useState([
    ["Intermittents of the show", "105h"],
    ["Cultural facilitators", "107h"],
    ["Sports presenters", "50h"],
    ["Artisans", "20h"],
  ]);
  return (
    <TravailStyle>
      <div className="container__head">
        <p>Number of working hours</p>
      </div>
      <div className="travailDetails">
        {data.map((element) => {
          return (
            <div className="content">
              <p className="titles">{element[0]}</p>
              <p className="heures">{element[1]}</p>
            </div>
          );
        })}
      </div>
    </TravailStyle>
  );
}

const TravailStyle = styled.div`
  background-color: #fff;
  width: 100%;
  grid-column: 4/5;
  grid-row: 1 / 2;
  @media screen and (max-width: 730px) {
    grid-column: 1;
    grid-row: 3 / 4;
  }
  @media screen and (max-width: 1200px) and (min-width: 731px) {
    grid-column: 2/3;
    grid-row: 2 / 3;
  }
  p {
    margin-bottom: 0;
  }
  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 2rem;
  }
  padding: 1.3rem 2rem;

  .travailDetails {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .content {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .heures {
      background-color: rgba(24, 182, 192, 0.14);
      padding: 0.3rem 2rem;
      border-radius: 15px;
      color: #18b6c0;
    }
  }
  border-radius: 4.5px;
  box-shadow: 0px 3.31035px 4px rgba(0, 0, 0, 0.14);
  min-height: 340px;
  @media screen and (max-width: 1850px) and (min-width: 1151px) {
    min-height: 290px;
  }
`;
