import React, { useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function EquipeChart() {
  // const [true]
  return (
    <Equipe>
      <div className="container__head">
        <p className="chartTitle">Status of Interventions</p>
        <button className="exporter">
          <img src="./download.svg" alt="" />
          Export
        </button>
      </div>
      <Chart
        type="bar"
        height={500}
        series={[
          {
            data: [4, 6, 7, 4, 1, 7, 2, 3, 5, 4],
          },
          {
            data: [5, 3, 1, 5, 3, 2, 3, 3, 2, 1],
          },
          {
            data: [1, 2, 1, 2, 7, 3, 5, 2, 1, 1],
          },
          {
            data: [1, 0, 2, 1, 1, 0, 0, 1, 1, 2],
          },
        ]}
        options={{
          dataLabels: { enabled: false },
          colors: ["#CBF2F4", "#EA759E", "#54C3CA", "#18B6C0"],
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
            background: "#fff",
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [
              "Team1",
              "Team2",
              "Team3",
              "Team4",
              "Team5",
              "Team6",
              "Team7",
              "Team8",
              "Team9",
              "Team10",
            ],
            labels: {
              rotate: 320,
              rotateAlways: true,
            },
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          responsive: [{}],
        }}
      />
    </Equipe>
  );
}

const Equipe = styled.div`
  height: 590px;
  background-color: #fff;
  padding: 1rem;
  @media screen and (max-width: 425px) {
    .exporter {
      font-size: 0;
    }
  }
  .exporter {
    color: #18b6c0;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
