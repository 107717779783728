import React, { useEffect, useState } from "react";
import L, { LeafletMouseEvent } from "leaflet";
import Map from "./Map";
import { useMap } from "react-leaflet";

function Select(props: any) {
  let value;
  let SelectDiv: any;
  let check = false;
  const options =
    '<option value="Cadastre">Cadastre</option><option value="Voirie">Voirie</option><option value="Réseaux">Réseaux</option><option value="Carte cadastral">Carte cadastral</option><option value="Satellite">Satellite</option><option value="Autres">Autres</option>';
  const createButtonControl = () => {
    const MapHelp = L.Control.extend({
      onAdd: (map: any) => {
        const helpDiv = L.DomUtil.create("select", "selectMap");
        helpDiv.innerHTML = options;
        SelectDiv = helpDiv;
        const marker = L.polygon([
          [48.66457891863769, 2.27102659775317],
          [48.66452223035566, 2.2724439217458596],
          [48.66435216512703, 2.273431753619559],
          [48.663926999544756, 2.27399009337427],
          [48.66304831264226, 2.2726586678053544],
          [48.66216961042053, 2.271885581991189],
          [48.66194284607008, 2.271541988295973],
          [48.661262546897376, 2.269995816667603],
          [48.66078066276127, 2.268234898979698],
          [48.66191450045454, 2.2647130636039674],
          [48.66208457390867, 2.261320075863878],
          [48.66279320712378, 2.260632888473486],
          [48.66412541059615, 2.256252068859737],
          [48.66466395094116, 2.2575405452167456],
          [48.66534420420414, 2.257325799157251],
          [48.66557095325139, 2.258829021573714],
          [48.66602444828527, 2.259043767633209],
          [48.66687474047471, 2.2609335329567952],
          [48.66690308330063, 2.2617066187710004],
          [48.66653462532052, 2.2627803490684744],
          [48.666166164646455, 2.2634245872469587],
          [48.6661378214061, 2.2654002509943587],
          [48.66602444828527, 2.2675477115893066],
          [48.66602444828527, 2.26905093400581],
        ]);
        SelectDiv.addEventListener("click", (e: any) => {
          // console.log(e.target.value);
          value = e.target.value;
          if (check == false && value == "Cadastre") {
            const popupContent =
              '<p className="parcelle">Parcelle         311219120010</p><p>Commune : --</p><p>Département : 33</p><p>Surface : 105 m²</p><p>Statut juridique  : Proprietaire </p><p>Type de foncier: --</p>';
            marker.bindPopup(popupContent).addTo(map);
            marker.openPopup();
            check = true;
          } else if (value != "Cadastre") {
            if (marker) {
              map.removeLayer(marker);
            }
            check = false;
          }
        });

        //a bit clueless how to add a click event listener to this button and then
        // open a popup div on the map
        return SelectDiv;
      },
    });
    return new MapHelp({ position: "topright" });
  };
  useEffect(() => {
    const map = props.map;
    const control = createButtonControl();
    control.addTo(map);
    return () => {
      SelectDiv.remove();
    };
  }, []);
  return null;
}

function SelectButton(Component: any) {
  return function WrappedComponent() {
    const map = useMap();
    return <Component map={map} />;
  };
}

export default SelectButton(Select);
