import React, { useState } from "react";
import { Table } from "react-bootstrap";
// import data from "./generalData";
const General = () => {
  const flexCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const btnPrimary = {
    backgroundColor: "#18B6C0",
    color: "white",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    padding: "5px",
    margin: "5px",
  };
  const btnOutlinePrimary = {
    color: "#18B6C0",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #18B6C0",
    width: "fit-content",
    padding: "5px",
    margin: "5px",
  };
  const columns = [
    {
      key: "number",
      title: "Number",
      sortable: false,
    },
    {
      key: "Description",
      title: "Description",
      sortable: false,
    },
    {
      key: "Type",
      title: "Type",
      sortable: true,
    },
    {
      key: "vendor",
      title: "Supplier",
      sortable: true,
    },
    {
      key: "startDate",
      title: "Start date",
      sortable: true,
    },
    {
      key: "Expirydate",
      title: "Expiration date",
      sortable: true,
    },
    {
      key: "Phase",
      title: "Phase",
      sortable: true,
    },
    {
      key: "documents",
      title: "Documents",
      sortable: true,
    },
  ];
  const data = [
    {
      id: 1,
      number: "3393",
      Description: "Collection of household waste",
      Type: "Delegated Management",
      vendor: "EMFA",
      startDate: "13/06/2020",
      Expirydate: "13/06/2020",
      Phase: "Execution",
      state: "In progress",
      risk: "Medium",
      documents: "docx",
    },
    {
      id: 2,
      number: "0202",
      Description: "Drinking Water Distribution",
      Type: "Delegated Management",
      vendor: "GGEF",
      startDate: "21/05/2017",
      Expirydate: "21/05/2017",
      Phase: "Execution",
      state: "In progress",
      risk: "Low",
      documents: "docx",
    },
    {
      id: 3,
      number: "5793",
      Description: "Sanitation",
      Type: "Lease",
      vendor: "AMS",
      startDate: "9/04/2018",
      Expirydate: "9/04/2023",
      Phase: "Execution",
      state: "In progress",
      risk: "Medium",
      documents: "docx",
    },
    {
      id: 4,
      number: "0832",
      Description: "Electricity Distribution",
      Type: "Service Provision",
      vendor: "GDF",
      startDate: "9/04/2019",
      Expirydate: "09/04/2023",
      Phase: "Execution",
      state: "In progress",
      risk: "High",
      documents: "docx",
    },
    {
      id: 5,
      number: "2092",
      Description: "Drinking Water Distribution",
      Type: "Service Provision",
      vendor: "GGEF",
      startDate: "23/06/2020",
      Expirydate: "23/06/2024",
      Phase: "Execution",
      state: "In progress",
      risk: "Medium",
      documents: "docx",
    },
    {
      id: 1,
      number: "3393",
      Description: "Collection of household waste",
      Type: "Delegated Management",
      vendor: "EMFA",
      startDate: "13/06/2020",
      Expirydate: "13/06/2020",
      Phase: "Execution",
      state: "In progress",
      risk: "Medium",
      documents: "docx",
    },
    {
      id: 2,
      number: "0202",
      Description: "Drinking Water Distribution",
      Type: "Delegated Management",
      vendor: "GGEF",
      startDate: "21/05/2017",
      Expirydate: "21/05/2017",
      Phase: "Execution",
      state: "In progress",
      risk: "Low",
      documents: "docx",
    },
    {
      id: 3,
      number: "5793",
      Description: "Sanitation",
      Type: "Lease",
      vendor: "AMS",
      startDate: "9/04/2018",
      Expirydate: "9/04/2023",
      Phase: "Execution",
      state: "In progress",
      risk: "Medium",
      documents: "docx",
    },
    {
      id: 4,
      number: "0832",
      Description: "Electricity Distribution",
      Type: "Service Provision",
      vendor: "GDF",
      startDate: "9/04/2019",
      Expirydate: "09/04/2023",
      Phase: "Execution",
      state: "In progress",
      risk: "High",
      documents: "docx",
    },
    {
      id: 5,
      number: "2092",
      Description: "Drinking Water Distribution",
      Type: "Service Provision",
      vendor: "GGEF",
      startDate: "23/06/2020",
      Expirydate: "23/06/2024",
      Phase: "Execution",
      state: "In progress",
      risk: "Medium",
      documents: "docx",
    },
    {
      id: 1,
      number: "3393",
      Description: "Collection of household waste",
      Type: "Delegated Management",
      vendor: "EMFA",
      startDate: "13/06/2020",
      Expirydate: "13/06/2020",
      Phase: "Execution",
      state: "In progress",
      risk: "Medium",
      documents: "docx",
    },
  ];

  const handleSortBy = (key: any, dataMap: any) => {
    console.log(key);

    let newData = [...dataMap].sort((a, b) => {
      if (a.get(key) > b.get(key)) {
        console.log("a > b");
        return 1;
      }

      if (a.get(key) < b.get(key)) {
        console.log("a < b");
        return -1;
      }
      console.log("a = b");
      return 0;
    });
    setDataMap(newData);
  };
  const sortBy = null;
  const [dataMap, setDataMap] = useState(
    data.map((item) => new Map(Object.entries(item)))
  );
  return (
    <div>
      <div className="shadow bg-white  m-3 rounded mx-3 p-2">
        <div className="row">
          <div
            className="col-12 col-lg-3 col-xl-4 mt-2"
            style={{ color: "black", fontWeight: "900" }}
          >
            Contract management
          </div>
          <div
            className="col-12 col-lg-9 col-xl-8 d-md-flex justify-content-lg-end"
            style={{ gap: "10px" }}
          >
            <div style={{ color: "#18B6C0", padding: "10px" }}>
              Total number of contracts : 493
            </div>
            <div>
              <div style={{ ...btnPrimary }}>
                <img src="/toDoCompenents/settingIcon.svg" alt="" />
                <div className="px-2">Contract settings</div>
              </div>
            </div>
            <div>
              <div style={{ ...btnOutlinePrimary }}>
                <img
                  className="mr-2"
                  src="/toDoCompenents/plusIcon.svg"
                  alt=""
                />
                <div className="px-2">New contract</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="shadow bg-white  m-3"
        style={{
          borderRadius: "10px",
          overflowX: "auto",
        }}
      >
        <Table hover className="">
          <thead
            style={{
              borderBottom: "2px solid white",
              backgroundColor: "#F4F4F4",
            }}
          >
            <tr>
              {columns.map((column) => (
                <th className=" p-0" key={column.key}>
                  <div
                    className="h-100 w-100  p-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{column.title}</span>
                    {column.sortable && (
                      <img
                        onClick={() => handleSortBy(column.key, dataMap)}
                        style={{ cursor: "pointer" }}
                        src="/usableIcons/sort.svg"
                        alt="sortBy"
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataMap.map((row, index) => (
              <tr style={{}} key={index}>
                {columns.map((column) => (
                  <td
                    style={{
                      padding: "10px",
                      borderBottom:
                        index < dataMap.length - 1
                          ? "2px solid lightgray"
                          : "none",
                    }}
                    key={column.key}
                  >
                    {row.get(column.key) == "docx" ? (
                      <div style={flexCenter}>
                        <img src="/usableIcons/word.svg" alt="docs" />
                      </div>
                    ) : (
                      row.get(column.key)
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default General;
