import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";
import Select from "../Ponctualite/Select";

export default function Card4global() {
  return (
    <PonctualiteChart>
      <div className="container__head">
        <div className="title_select">
          <p className="chartTitle">occupancy rate</p>
          <div className="select_Button">
            <Select
              forTag="lignSelected"
              name="lign"
              id="lignSelected"
              value="Ligne 1"
            />
            <Select
              forTag="daysSelected"
              name="days"
              id="daysSelected"
              value="Jours"
            />
          </div>
        </div>
      </div>
      <Chart
        type="line"
        width="100%"
        height="80%"
        series={[
          {
            name: "Punctual",
            data: [19, 22, 20, 26, 333, 777, 1, 222],
          },
          {
            name: "Not punctual",
            data: [103, 105, 98, 83, 30, 900, 400],
          },
        ]}
        options={{
          xaxis: {
            categories: [10, 12, 14, 16, 18, 20, 22, 24],
            title: {
              text: "day",
              style: {
                color: undefined,
              },
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          colors: ["#18B6C0", "#FF5C01"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          //   xaxis: {
          //     tickAmount:8,
          //     min: 10,
          //     max: 24,
          //   }
          // ,
          yaxis: {
            tickAmount: 2,
            min: 0,
            max: 1000,
          },
        }}
      />
    </PonctualiteChart>
  );
}

const PonctualiteChart = styled.div`
  height: 100%;
  width: 100%;
  @media screen and (max-width: 550px) {
    min-width: 500px;
  }
  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 0.2rem;

    .chartTitle {
      display: flex;
      align-items: center;
    }
    .title_select {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 630px) {
        flex-direction: column;
        gap: 1rem;
      }
    }
    .select_Button {
      display: flex;
      gap: 0.8rem;
    }
    .selectButton {
      border: 0.87px solid #dcdcdc;
      color: #6d6d6d;
    }
  }
`;
