import React from "react";
import styled from "styled-components";

export default function Card({ image, number, type }: any) {
  return (
    <CardStyle>
      <div className="image">
        <img src={image} alt="" />
      </div>
      <div className="description">
        <p className="description__number">{number}</p>
        <p className="description__type">{type}</p>
      </div>
    </CardStyle>
  );
}

const CardStyle = styled.div`
  &:hover {
    .image {
      background-color: #18b6c0;
    }
  }
  background-color: white;
  /* max-width: 500px;
  min-width: 200px; */
  display: flex;
  align-items: center;
  gap: 1rem;
  .image {
    height: 76px;
    width: 71px;
    background-color: #42728d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4.5px;
  }
  .description {
    p {
      margin-bottom: 0;
    }
    .description__number {
      font-weight: 800;
      font-size: 16px;
    }
    .description__type {
      font-weight: 400;
    }
  }
  border-radius: 4.5px;
  box-shadow: 0px 3.31035px 4px rgba(0, 0, 0, 0.14);
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;
