import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";

const options = {
  chart: {
    type: "areaspline",
    height: "265",
  },
  title: {
    text: "",
  },
  xAxis: {
    categories: [
      "22 02 2024",
      "23 02 2024",
      "24 02 2024",
      "25 02 2024",
      "26 02 2024",
      "27 02 2024",
      "28 02 2024",
    ],
  },
  yAxis: {
    title: {
      text: "",
    },
  },
  tooltip: {
    shared: true,
    valueSuffix: " mbar",
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    areaspline: {
      fillOpacity: 0.5,
    },
  },
  series: [
    {
      color: "#B9CE11",
      // name: "pression",
      name: "pressure",
      data: [30000, 40000, 30000, 50000, 40000, 10000, 12000],
    },
  ],
};
export function PressionChart() {
  return (
    <PressionChartStyle>
      {/* <strong className="h5 __title">Pression atmosphérique stats (mbar)</strong> */}
      <strong className="h5 __title">Atmospheric pressure stats (mbar)</strong>

      <HighchartsReact highcharts={Highcharts} options={options} />
    </PressionChartStyle>
  );
}

const PressionChartStyle = styled.div`
  width: 100%;
  background-color: white;
  padding: 1rem;
  height: 100%;
  min-width: 600px;
`;
