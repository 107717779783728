import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Heatmap() {
  return (
    <Chart
      type="heatmap"
      width="100%"
      height="100%"
      series={[
        {
          name: "Avenue Adrien-Hébrard",
          data: [
            100, 110, 120, 130, 300, 350, 500, 550, 400, 500, 500, 400, 400,
            150, 100,
          ],
        },
      ]}
      options={{
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 100,
                  color: "#00A100",
                  name: "low",
                },
                {
                  from: 100,
                  to: 300,
                  color: "#128FD9",
                  name: "medium",
                },
                {
                  from: 300,
                  to: 100,
                  color: "#FFB200",
                  name: "high",
                },
              ],
            },
          },
        },
        labels: ["Cars", "Road transport", "of goods", "Two-wheelers", "Buses"],
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        title: {
          text: "Daily congestion trends",
          align: "left",
          style: {
            color: "#161E54",
            fontSize: "15px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        colors: ["#135C9F"],
        chart: {
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        xaxis: {
          categories: [
            "1 AM",
            "2 AM",
            "3 AM",
            "4 AM",
            "6 AM",
            "7 AM",
            "8 AM",
            "9 PM",
            "10 PM",
            "11 PM",
            "1 PM",
            "2 PM",
            "3 PM",
            "4 PM",
            "6 PM",
          ],
        },
      }}

      // series= {[{
      //     data: [{
      //       x: new Date('2018-02-12').getTime(),
      //       y: 76
      //     }, {
      //       x: new Date('2018-02-12').getTime(),
      //       y: 76
      //     }]
      //   }]}
      //   options= {{
      //     chart: {
      //       type: 'area',
      //       height: 350,
      //       zoom: {
      //         enabled: false
      //       }
      //     },
      //     dataLabels: {
      //       enabled: false
      //     },
      //     stroke: {
      //       curve: 'straight'
      //     },

      //     title: {
      //       text: 'Fundamental Analysis of Stocks',
      //       align: 'left'
      //     },
      //     subtitle: {
      //       text: 'Price Movements',
      //       align: 'left'
      //     },
      //     // labels: series.monthDataSeries1.dates,
      //     xaxis: {
      //       type: 'datetime',
      //     },
      //     yaxis: {
      //       opposite: true
      //     },
      //     legend: {
      //       horizontalAlign: 'left'
      //     }
      //   }}
    />
  );
}
