import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Detailsbarchart() {
  return (
    <Chart
      height="300px"
      series={[
        {
          name: "Lighting",
          type: "bar",
          data: [
            7, 4, 2, 7, 11, 7, 2, 7, 11, 7, 11, 10, 22, 18, 8, 8, 22, 18, 8, 8,
            7, 11, 7, 11, 10, 22, 18,
          ],
        },
        {
          name: "Waste",
          type: "bar",
          data: [
            11, 10, 22, 18, 8, 8, 22, 18, 8, 8, 7, 4, 2, 7, 11, 7, 2, 7, 11, 7,
            7, 11, 7, 11, 10, 22, 18,
          ],
        },
        {
          name: "Social Media",
          type: "line",
          data: [
            0, 1, 2, 3, 4, 5, 0, 8, 7, 4, 2, 7, 11, 7, 2, 7, 1, 7, 7, 23, 2, 5,
            7, 3, 11, 10,
          ],
        },
      ]}
      options={{
        dataLabels: {
          // enabled: true,
          formatter: (val) => {
            return `W/h`;
          },
        },
        colors: ["#0BB489", "#D96FF8", "#161E54", "#18B6C0"],
        // chart: {
        //     type: 'line'
        // },
        chart: {
          // type: "area",
          stacked: true,
          toolbar: {
            show: false,
          },
          background: "#fff",
        },
        // yaxis: {
        //   show: true,
        // },
        // tooltip: {
        //   followCursor: true,
        // },
        xaxis: {
          categories: [
            "04:20",
            "04:21",
            "04:22",
            "04:23",
            "04:24",
            "04:25",
            "04:26",
            "04:27",
            "04:28",
            "04:29",
            "04:30",
            "04:31",
            "04:32",
            "04:33",
            "04:34",
            "04:35",
            "04:36",
            "04:37",
            "04:38",
            "04:39",
            "04:40",
            "04:41",
            "04:42",
            "04:43",
            "04:44",
            "04:45",
            // "Jan",
            // "Fev",
            // "Mar",
            // "Avr",
            // "Mai",
            // "Jui",
            // "Juil",
            // "Aoa",
            // "Sep",
            // "Oct",
          ],
          // labels: {
          //   show: false
          // },
        },
        // xaxis: {
        //     categories: ['04:25', '04:26', '04:27', '04:28', '04:29', '04:30', '04:31', '04:32','04:33', '04:34', '04:35', '04:36', '04:37','04:38', '04:39', '04:40', '04:41', '04:42','04:43', '04:44', '04:45', '04:46', '04:47'],
        //   },
        //   yaxis:
        //     {
        //       axisTicks: {
        //         show: true,
        //       },
        //       axisBorder: {
        //         show: true,
        //         color: '#008FFB'
        //       },
        //       labels: {
        //         style: {
        //           colors: '#008FFB',
        //         }
        //       },
        //       title: {
        //         text: "Income (thousand crores)",
        //         style: {
        //           color: '#008FFB',
        //         }
        //       },
        //       tooltip: {
        //         enabled: true
        //       }
        //     },
        legend: {
          show: false,
          markers: {
            radius: 50,
          },
        },
        grid: {
          show: false,
        },
        responsive: [{}],
      }}
    />
  );
}
