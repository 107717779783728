import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

function SelectButton() {
  return (
    <SelectDiv>
      <label htmlFor="dateSelect"></label>
      <select name="date" id="dateSelect">
        <option value="">Days</option>
      </select>
    </SelectDiv>
  );
}

export default function Billetterie() {
  return (
    <Billeterie>
      <div className="min__width">
        <div className="container__head">
          <p>Ticketing</p>
          <button className="exporter">
            <img src="./Evenement/Warning.svg" alt="" />
          </button>
        </div>
        <div className="content">
          <div className="details">
            <div className="filtre">
              <div className="filtre__text">
                <img src="./Filtre.svg" alt="" />
                <p>Filters:</p>
              </div>
              <SelectButton />
            </div>
            <div className="legend">
              <p className="p1">Sports clubs</p>
              <p className="p2">Cinemas</p>
              <p className="p3">Theaters</p>
              <p className="p4">Libraries</p>
            </div>
          </div>
          <Chart
            type="pie"
            series={[12, 13, 10, 75]}
            options={{
              colors: ["#42728D", "#EA759E", "#86DFE4", "#3CD1DB"],
              legend: {
                show: false,
                position: "bottom",
              },
              chart: {
                toolbar: {
                  show: true,
                  tools: {
                    download: false,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </Billeterie>
  );
}

const Billeterie = styled.div`
  /* width: 100%; */
  background-color: #fff;
  min-height: 380px;
  padding: 1rem;
  grid-column: 2/3;
  grid-row: 1 / 2;
  overflow-x: auto;
  @media screen and (max-width: 700px) {
    .min__width {
      min-width: 400px;
    }
  }
  @media screen and (max-width: 865px) {
    grid-column: 1;
    grid-row: 2 / 3;
  }
  @media screen and (max-width: 1670px) and (min-width: 866px) {
    grid-column: 3/5;
    grid-row: 1 /2;
  }
  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 425px) {
    .exporter {
      font-size: 0;
    }
  }
  .exporter {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    color: #18b6c0;
    font-weight: 600;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .legend {
    p {
      margin-bottom: 0.5rem;
    }
    p::before {
      margin-right: 5px;
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background-color: #ea759e;
    }
    .p2::before {
      background-color: #42728d;
    }
    .p3::before {
      background-color: #86dfe4;
    }
    .p4::before {
      background-color: #3cd1db;
    }
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .details {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .filtre {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .filtre__text {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }

  @media screen and (max-width: 550px) {
    .details {
      margin-bottom: 1rem;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
    .legend {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 0.5rem;
      p {
        width: 45%;
        margin-bottom: 0;
        font-size: 12px;
      }
      p::before {
        height: 10px;
        width: 10px;
      }
    }
  }

  @media screen and (max-width: 1850px) and (min-width: 1151px) {
    .legend {
      p {
        font-size: 13px;
      }
      p::before {
        width: 8px;
        height: 8px;
      }
    }
  }
`;

const SelectDiv = styled.div`
  select {
    width: 180px;
    min-width: 150px;
    height: 35px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    text-align: center;
    border: 0px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    @media screen and (max-width: 320px) {
      width: 100px;
      min-width: 0;
      font-size: 14px;
    }
    @media screen and (max-width: 550px) and (min-width: 321px) {
      width: 200px;
    }
    @media screen and (max-width: 1850px) and (min-width: 551px) {
      width: 80px;
    }
  }
`;
