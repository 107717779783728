import React, { useState } from "react";
import styled from "styled-components";
import Card from "./Card";

export default function Cards() {
  const [arr, setArr] = useState([
    {
      image: "/Evenement/Total.svg",
      number: 135,
      type: "Equipments",
    },
    {
      image: "/Evenement/Pool.svg",
      number: 23,
      type: "Swimming pools",
    },
    {
      image: "/Evenement/Theatre.svg",
      number: 11,
      type: "Theaters",
    },
    {
      image: "/Evenement/Books.svg",
      number: 73,
      type: "Libraries",
    },
    {
      image: "/Evenement/Cinema.svg",
      number: 12,
      type: "Cinemas",
    },
  ]);
  return (
    <CardsStyle>
      {arr.map((element) => (
        <Card
          image={element.image}
          number={element.number}
          type={element.type}
        />
      ))}
    </CardsStyle>
  );
}

const CardsStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  width: 100%;
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
  }
  @media screen and (max-width: 700px) and (min-width: 501px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  @media screen and (max-width: 920px) and (min-width: 701px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  @media screen and (max-width: 1400px) and (min-width: 921px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
  }
`;
