import React from "react";
import styled from "styled-components";

const Content = styled.div`
  width: 100%;
  padding: 30px;
  .head {
    display: flex;
    .secur {
      margin-left: 10px;
      color: #3f487e;
    }
    margin-bottom: 20px;
  }
  .flexContent {
    display: flex;
    @media screen and (max-width: 740px) {
      flex-direction: column;
    }
  }
`;
const Contentcards = styled.div`
  padding-right: 30px;
  width: 40%;
  @media screen and (max-width: 740px) {
    width: 100%;
  }
`;
const Card1 = styled.div`
  width: 100%;
  @media screen and (max-width: 740px) {
    width: 100%;
  }
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-size: 13px;
  .cardhead {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-left: 10px;
      margin-top: 20px;
    }
  }
  .listitem {
    padding: 0;
    .item {
      color: #42526e;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 12px;
      .listedescamera {
        width: 100%;
        padding-left: 15px;
        padding-right: 20px;
        display: flex;
        background: #e6e6e6;
        border-radius: 19.5px;
        height: 30px;
        .listedescameratext {
          margin-left: 10px;
          margin-top: 2px;
        }
      }
      .divcontent {
        display: flex;
        justify-content: space-between;
        .text {
          color: #42526e;
        }
        .datetime {
          .time {
            color: #535151;
          }
          .date {
            color: #a4a4a4;
          }
        }
      }
    }
  }
  .listitem1 {
    .item {
      list-style-type: disc;
      color: #ff4968;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 12px;
      .listedescamera {
        width: 100%;
        padding-left: 15px;
        padding-right: 20px;
        display: flex;
        background: #e6e6e6;
        border-radius: 19.5px;
        height: 30px;
        .listedescameratext {
          margin-left: 10px;
          margin-top: 2px;
        }
      }
      .divcontent {
        display: flex;
        justify-content: space-between;
        .text {
          color: #42526e;
        }
        .datetime {
          .time {
            color: #535151;
          }
          .date {
            color: #a4a4a4;
          }
        }
      }
    }
  }
`;
const Videocard = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  .videohead {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .controls {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
  .myprogress {
    display: flex;
    justify-content: center;
    padding: 8px;
  }
  .classvid {
    padding: 20px;
    .twivideodiv {
      display: flex;
      @media screen and (max-width: 740px) {
        /* display: flex; */
        flex-direction: column;
      }
      .video1 {
        width: 50%;
        border-width: 15px;
        border-top-left-radius: 50px;
        /* border-radius: 50px; */
        border-color: black;
        @media screen and (max-width: 740px) {
          width: 100%;
          border-top-right-radius: 50px;
        }
      }
      .video2 {
        width: 50%;
        border-width: 15px;
        border-top-right-radius: 50px;
        /* border-radius: 50px; */
        border-color: black;
        @media screen and (max-width: 740px) {
          width: 100%;
          border-top-right-radius: 0px;
        }
      }
    }
    .video3 {
      border-width: 15px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      border-color: black;
    }
  }
`;
const Securite = () => {
  return (
    <Content>
      <div className="head">
        <img src="/icons/securite.svg" alt="" />
        {/* <span className="secur">Sécurité</span> */}
        <span className="secur">Security</span>
      </div>
      <div className="flexContent">
        <Contentcards>
          <Card1>
            <div className="cardhead">
              <img
                src="/icons/alerte.svg"
                alt=""
                style={{ marginTop: "17px" }}
              />
              {/* <span className="title">Alertes / Notification</span> */}
              <span className="title">Alerts / Notification</span>
            </div>
            <ul className="listitem1">
              <li className="item">
                <div className="divcontent">
                  <span className="text">
                    {/* Mouvement détecté */}
                    Motion detected
                    <br />
                    {/* Domicile - Cam#12 */}
                    Home - Cam#12
                  </span>
                  <span className="datetime">
                    <span className="time">03:56</span>
                    <br></br>
                    <span className="date">19/01/2024</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="divcontent">
                  <span className="text">
                    {/* Mouvement détecté */}
                    Motion detected
                    <br />
                    {/* Rue - CAM #34 */}
                    Street - CAM #34
                  </span>
                  <span className="datetime">
                    <span className="time">03:56</span>
                    <br></br>
                    <span className="date">19/01/2024</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="divcontent">
                  <span className="text">
                    {/* Mouvement détecté */}
                    Motion detected
                    <br />
                    {/* Parking - CAM #45 */}
                    Parking - CAM #45
                  </span>
                  <span className="datetime">
                    <span className="time">03:56</span>
                    <br></br>
                    <span className="date">19/01/2024</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="divcontent">
                  <span className="text">
                    {/* Mouvement détecté */}
                    Motion detected
                    <br />
                    {/* Rond point - CAM #23 */}
                    Roundabout - CAM #23
                  </span>
                  <span className="datetime">
                    <span className="time">03:56</span>
                    <br></br>
                    <span className="date">19/01/2024</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="divcontent">
                  <span className="text">
                    {/* Mouvement détecté */}
                    Motion detected
                    <br />
                    {/* Bureau - CAM #22 */}
                    Office - CAM #22
                  </span>
                  <span className="datetime">
                    <span className="time">03:56</span>
                    <br></br>
                    <span className="date">19/01/2024</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="divcontent">
                  <span className="text">
                    {/* Mouvement détecté */}
                    Motion detected
                    <br />
                    {/* Bureau - CAM #29 */}
                    Office - CAM #29
                  </span>
                  <span className="datetime">
                    <span className="time">03:56</span>
                    <br></br>
                    <span className="date">19/01/2024</span>
                  </span>
                </div>
              </li>
              <li className="item" style={{ paddingBottom: "20px" }}>
                <div className="divcontent">
                  <span className="text">
                    {/* Mouvement détecté */}
                    Motion detected
                    <br />
                    {/* Parking - CAM #45 */}
                    Parking - CAM #45
                  </span>
                  <span className="datetime">
                    <span className="time">03:56</span>
                    <br></br>
                    <span className="date">19/01/2024</span>
                  </span>
                </div>
              </li>
            </ul>
          </Card1>
          <Card1>
            <div className="cardhead">
              <img
                src="/icons/camera.svg"
                alt=""
                style={{ marginTop: "17px" }}
              />
              {/* <span className="title">Listes des caméras</span> */}
              <span className="title">List of cameras</span>
            </div>
            <ul className="listitem">
              <li className="item">
                <div className="listedescamera">
                  <img src="/icons/camera.svg" alt="" width="25" />
                  <span className="listedescameratext">
                    {/* Domicile - <span>CAM #00</span> */}
                    Home - <span>CAM #00</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="listedescamera">
                  <img src="/icons/camera.svg" alt="" width="25" />
                  <span className="listedescameratext">
                    {/* Bureau - <span>CAM #22</span> */}
                    Office - <span>CAM #22</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="listedescamera">
                  <img src="/icons/camera.svg" alt="" width="25" />
                  <span className="listedescameratext">
                    {/* Rue - <span>CAM #34</span> */}
                    Street - <span>CAM #34</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="listedescamera">
                  <img src="/icons/camera.svg" alt="" width="25" />
                  <span className="listedescameratext">
                    {/* Rond point - <span>CAM #23</span> */}
                    Roundabout - <span>CAM #23</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="listedescamera">
                  <img src="/icons/camera.svg" alt="" width="25" />
                  <span className="listedescameratext">
                    {/* Parking - <span>CAM #45</span> */}
                    Parking - <span>CAM #45</span>
                  </span>
                </div>
              </li>
              <li className="item">
                <div className="listedescamera">
                  <img src="/icons/camera.svg" alt="" width="25" />
                  <span className="listedescameratext">
                    {/* Parking - <span>CAM #45</span> */}
                    Parking - <span>CAM #45</span>
                  </span>
                </div>
              </li>
              <li className="item" style={{ paddingBottom: "20px" }}>
                <div className="listedescamera">
                  <img src="/icons/camera.svg" alt="" width="25" />
                  <span className="listedescameratext">
                    {/* Parking - <span>CAM #45</span> */}
                    Parking - <span>CAM #45</span>
                  </span>
                </div>
              </li>
            </ul>
          </Card1>
        </Contentcards>
        <Videocard>
          <div className="videocard">
            <div className="videohead">
              <div style={{ display: "flex" }}>
                <img src="/icons/cameras.svg" alt="" />
                {/* <span style={{ marginLeft: "10px" }}>Caméras</span> */}
                <span style={{ marginLeft: "10px" }}>Cameras</span>
              </div>
              <img src="/icons/full.svg" alt="" />
            </div>
            <div className="classvid">
              <div className="twivideodiv">
                <video
                  src="blob:https://www.youtube.com/14666928-61c7-4bbd-9848-4fa3b07b66ba"
                  className="video1"
                  autoPlay
                  loop
                  muted
                >
                  a
                </video>
                <video
                  src="https://www.youtube.com/watch?v=E9H5YKEDxIA"
                  className="video2"
                  autoPlay
                  loop
                  muted
                >
                  a
                </video>
              </div>
              <video
                src="https://www.youtube.com/watch?v=E9H5YKEDxIA"
                className="video3"
                width="100%"
                autoPlay
                loop
                muted
              >
                a
              </video>
            </div>
            <div className="controls">
              <img src="/icons/live.svg" alt="" />
              <div style={{ display: "flex" }}>
                <img
                  src="/icons/arrowleft.svg"
                  alt=""
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
                <img
                  src="/icons/play.svg"
                  alt=""
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
                <img
                  src="/icons/pause.svg"
                  alt=""
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
                <img
                  src="/icons/arrowright.svg"
                  alt=""
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <img
                  src="/icons/cam.svg"
                  alt=""
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
                <img
                  src="/icons/mute.svg"
                  alt=""
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
                <img
                  src="/icons/zoom.svg"
                  alt=""
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
                <img
                  src="/icons/settings.svg"
                  alt=""
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="myprogress">
              <img src="/icons/progress.svg" alt="" />
            </div>
            {/* <figcaption>
                        <label id="timer" htmlFor="progress" role="timer"></label>
                        <progress className="progress" max="100" value="0">Progress</progress>
                    </figcaption> */}
          </div>
        </Videocard>
      </div>
    </Content>
  );
};

export default Securite;
