import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Card4global from "../charts/card4global";
import HorizontalBar2 from "../charts/horizentchar2";
import HorizontalBar from "../charts/horizontalbar";
import PieChart from "../charts/PieChart";
import PieChartglolab2 from "../charts/Piechartglobal2";
import Transportpublicchart from "../charts/transportpublicchart";
import VerticlBarglob from "../charts/Verticalbar1";
import VerticlBar from "../charts/Verticalvar";
import Map2 from "../maps/map2";

function Global() {
  const Content = styled.div`
    background: white;
    height: 100%;
    width: 100%;
    padding: 10px;
    .position {
      background-color: #e5e5e5;
      padding: 0.5%;
      .fluid {
        .Row {
          .Col {
            .card1 {
              height: 164px;
            }
          }
        }
      }
    }
  `;
  const Mycard = styled.div`
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-size: 86%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 768px) {
      font-size: 100%;
    }
    .firstline {
      display: flex;
      justify-content: space-between;
      .titleleft {
        color: #161e54;
        font-size: 90%;
        .soustext {
          font-size: 70%;
          margin-left: 5px;
        }
      }
      .titleright {
        color: #18b6c0;
        font-size: 90%;
        cursor: pointer;
      }
    }
    .alignCenter {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and (max-width: 420px) {
      }
    }
    .content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 100px);
      justify-content: space-around;
      @media screen and (max-width: 420px) {
        grid-template-columns: repeat(3, 85px);

        .alignCenter {
          img {
            width: 15px !important;
          }
          .__value {
            font-size: 13px !important;
          }
        }
      }
    }
  `;
  const Mycard2 = styled.div`
    height: 100%;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 1rem;
    .firstline {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.4rem;
      .titleleft {
        color: #161e54;
        font-size: 90%;
        .soustext {
          font-size: 70%;
          margin-left: 5px;
        }
      }
      .titleright {
        color: #18b6c0;
        font-size: 90%;
        cursor: pointer;
      }
    }
    .eclairage_public_content {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
    }
  `;
  const Globalchart1 = styled.div`
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 1rem;
    .dechet__header {
      display: flex;
      justify-content: space-between;
    }
    .cardhead {
      font-size: 100%;
      display: flex;
      justify-content: space-between;
    }
    .card4head {
      font-size: 100%;
      display: flex;
      justify-content: space-between;
    }
    .element {
      display: flex;
      width: 100%;
    }
  `;

  const Card4 = styled.div`
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 1rem;
    .dechet__header {
      display: flex;
      justify-content: space-between;
    }
    .cardhead {
      font-size: 100%;
      display: flex;
      justify-content: space-between;
    }
    .card4head {
      font-size: 100%;
      display: flex;
      justify-content: space-between;
    }
    .element {
      display: flex;
      width: 100%;
      /* white-space: nowrap; */
    }
  `;
  const Progress = styled.div`
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 1rem;
    .chart {
      width: 100%;
    }
    .chart4 {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      img {
        width: 25px;
        padding: 0;
      }
    }
    .headprog {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      /* @media screen and (max-width: 1345px) {
        flex-direction: column;
      } */
    }
  `;
  return (
    <ContainerStyle>
      <div className="global__header">
        <div>
          <span style={{ color: "#19B6C0" }}>Global</span>
        </div>
        <div className="global__header_position">
          <img src="/localisation.svg" alt="" /> Morocco, Agadir
        </div>
      </div>
      <ContentStyle>
        <LeftStyle>
          <Mycard className="item-1">
            <div className="firstline">
              <span className="titleleft">
                Air quality
                <span className="soustext">(24h)</span>
              </span>
              <NavLink to="/stationnement" style={{ textDecoration: "none" }}>
                <span className="titleright">Read more</span>
              </NavLink>
            </div>
            <div className="content">
              <div className="alignCenter">
                <img
                  src="/icons/volume.svg"
                  alt=""
                  style={{ width: "18px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "8px" }}>
                  <span className="__value" style={{ color: " #18B6C0" }}>
                    60.0 dB
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>Noise</span>
                </span>
              </div>
              <div className="alignCenter">
                <img
                  src="/icons/lampe.svg"
                  alt=""
                  style={{ width: "18px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "4px" }}>
                  <span className="__value" style={{ color: " #18B6C0" }}>
                    1947.p lux
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>Light</span>
                </span>
              </div>
              <div className="alignCenter" style={{ opacity: 0 }}>
                <img
                  src="/icons/volume.svg"
                  alt=""
                  style={{ width: "18px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "8px" }}>
                  <span className="__value" style={{ color: " #18B6C0" }}>
                    70.0 pgm
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>
                    Solar
                    <br></br>
                    radiation
                  </span>
                </span>
              </div>
              <div className="alignCenter">
                <img
                  src="/icons/cloud.svg"
                  alt=""
                  style={{ width: "18px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "8px" }}>
                  <span className="__value" style={{ color: " #18B6C0" }}>
                    20%
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>No2</span>
                </span>
              </div>
              <div className="alignCenter">
                <img
                  src="/icons/share.svg"
                  alt=""
                  style={{ width: "20px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "8px" }}>
                  <span
                    className="__value"
                    style={{ color: " #18B6C0", marginTop: "10%" }}
                  >
                    60.0 ppm
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>P10</span>
                </span>
              </div>
            </div>
          </Mycard>
          <Mycard className="item-2">
            <div className="firstline">
              <span className="titleleft">
                Weather Channel
                <span className="soustext">(24h)</span>
              </span>
              <NavLink to="/stationnement" style={{ textDecoration: "none" }}>
                <span className="titleright">Read more</span>
              </NavLink>
            </div>
            <div className="content">
              <div className="alignCenter">
                <img
                  src="/icons/temperature.svg"
                  alt=""
                  style={{ width: "18px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "8px" }}>
                  <span className="__value" style={{ color: " #18B6C0" }}>
                    9.1C
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>Temperature</span>
                </span>
              </div>
              <div className="alignCenter">
                <img
                  src="/icons/vent.svg"
                  alt=""
                  style={{ width: "18px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "4px" }}>
                  <span className="__value" style={{ color: " #18B6C0" }}>
                    12 Km/h
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>Wind</span>
                </span>
              </div>
              <div
                // className="rayonnement"
                className="alignCenter"
              >
                <img
                  src="/icons/volume.svg"
                  alt=""
                  style={{ width: "18px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "8px" }}>
                  <span className="__value" style={{ color: " #18B6C0" }}>
                    160 W/m<sup>2</sup>
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>
                    Solar
                    <br></br>
                    Radiation
                  </span>
                </span>
              </div>
              <div className="alignCenter">
                <img
                  src="/icons/humudite.svg"
                  alt=""
                  style={{ width: "18px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "8px" }}>
                  <span className="__value" style={{ color: " #18B6C0" }}>
                    20%
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>Humidity</span>
                </span>
              </div>
              <div
                className="alignCenter"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "30x",
                }}
              >
                <img
                  src="/icons/cloud.svg"
                  alt=""
                  style={{ width: "20px", marginBottom: "9px" }}
                />
                <span style={{ marginLeft: "8px" }}>
                  <span
                    className="__value"
                    style={{ color: " #18B6C0", marginTop: "10%" }}
                  >
                    Cloudy sky
                  </span>
                  <br />
                  {/* P10 */}
                </span>
              </div>
            </div>
          </Mycard>
          <Mycard2 className="item-3">
            <div className="firstline">
              <span className="titleleft">Public lighting</span>
              <NavLink to="/Eclairage" style={{ textDecoration: "none" }}>
                <span className="titleright">Read more</span>
              </NavLink>
            </div>
            <div className="eclairage_public_content">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ color: "#18B6C0", fontSize: "28px" }}>1928</span>
                <span>986 functional</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "80%" }}>Total lights</span>
                <span>142 nonfunctional</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>Program:</span>
                <span style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src="/icons/programme.svg"
                    width="20px"
                    alt=""
                    style={{ marginRight: "2px" }}
                  />
                  <span>06:50</span>
                </span>
                <span style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src="/icons/programme.svg"
                    width="20px"
                    alt=""
                    style={{ marginRight: "2px" }}
                  />
                  <span>06:50</span>
                </span>
              </div>
              <div style={{ height: "250px", width: "100%" }}>
                <VerticlBarglob />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                  }}
                >
                  <span>12:00 AM</span>
                  <span>12:00 PM</span>
                </div>
              </div>
            </div>
          </Mycard2>
        </LeftStyle>
        <MidleStyle>
          <div className="item-4">
            <Globalchart1 className="min__width">
              <div className="cardhead">
                <span>Roads</span>
                <select
                  className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0	"
                  aria-label="Default select example"
                  style={{ fontSize: "70%", width: "fit-content" }}
                >
                  <option selected>Choose the municipality</option>
                  <option value="1">Municipality 1</option>
                  <option value="2">Municipality 2</option>
                  <option value="3">Municipality 3</option>
                </select>
              </div>
              <span
                style={{
                  color: "#18B6C0",
                  fontSize: ".85rem",
                }}
              >
                Works
              </span>
              <PieChart />
              <NavLink to="/voiree" style={{ textDecoration: "none" }}>
                <span
                  style={{
                    color: "#18B6C0",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "0px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  Read more
                </span>
              </NavLink>
            </Globalchart1>
          </div>
          <div className="item-5">
            <Globalchart1 className="min__width">
              <div className="cardhead">
                <span>Mobility</span>
                <span
                  style={{
                    color: "#18B6C0",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "0px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  Read more
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    color: "#18B6C0",
                    fontSize: ".85rem",
                  }}
                >
                  Traffic
                </span>
                <select
                  className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0	"
                  aria-label="Default select example"
                  style={{ fontSize: "70%", width: "fit-content" }}
                >
                  <option selected>Choose the municipality</option>
                  <option value="1">Municipality 1</option>
                  <option value="2">Municipality 2</option>
                  <option value="3">Municipality 3</option>
                </select>
              </div>
              <div style={{ width: "100%" }}>
                <PieChartglolab2 />
              </div>
            </Globalchart1>
          </div>
          <div className="item-6">
            <Map2 />
          </div>
          <div className="item-7">
            <Globalchart1 className="">
              <div className="dechet__header">
                <span>Waste</span>
                <NavLink
                  to="/gestiondedechet"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      color: "#18B6C0",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "0px",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Read more
                  </span>
                </NavLink>
              </div>
              <div className="w-100 element" style={{ marginTop: "20px" }}>
                <img
                  src="/icons/recyclage.svg"
                  style={{ marginLeft: "8px", marginBottom: "5px" }}
                  alt=""
                ></img>
                <span style={{ marginLeft: "10px" }}>
                  Recycling rate <span>65%</span>
                </span>
              </div>
              <div className="w-100 element" style={{ marginTop: "15px" }}>
                <img
                  src="/icons/somme.svg"
                  style={{
                    marginLeft: "8px",
                    marginBottom: "5px",
                    width: "30px",
                  }}
                  alt=""
                ></img>
                <span style={{ marginLeft: "10px", fontSize: "100%" }}>
                  Total <span style={{ fontSize: "100%" }}>70,937 kg</span>
                </span>
              </div>
              {/* <PieChart /> */}
            </Globalchart1>
          </div>
          <div className="item-8">
            <Card4 className="min__width">
              <div className="dechet__header">
                <span>Public transport</span>
                <NavLink
                  to="/Transportpublic"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      color: "#18B6C0",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "0px",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Read more
                  </span>
                </NavLink>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  paddingRight: "5px",
                }}
              >
                <select
                  className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                  style={{ fontSize: "70%", width: "fit-content" }}
                >
                  <option selected>Day</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div style={{ width: "100%" }}>
                <Transportpublicchart />
              </div>
            </Card4>
          </div>
        </MidleStyle>
        <RightStyle>
          <div className="item-9">
            <Progress className="min__width">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span>Culture and sport </span>
                <div className="headprog">
                  <select
                    className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          text-xl	
                          m-0
                          mx-3
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
                    aria-label="Default select example"
                    style={{ fontSize: "70%", width: "fit-content" }}
                  >
                    <option selected>Select a date</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <select
                    className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          text-xl	
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
                    aria-label="Default select example"
                    style={{ fontSize: "70%", width: "fit-content" }}
                  >
                    <option selected>Day</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <div className="chart">
                <HorizontalBar />
              </div>
              <span
                style={{
                  color: "#18B6C0",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "0px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                Read more
              </span>
            </Progress>
          </div>
          <div className="item-10">
            <Progress className="min__width">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span>Social </span>
                <div className="headprog">
                  <select
                    className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          text-xl	
                          m-0
                          mx-3
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
                    aria-label="Default select example"
                    style={{ fontSize: "70%", width: "fit-content" }}
                  >
                    <option selected>Select a date</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <select
                    className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          text-xl	
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
                    aria-label="Default select example"
                    style={{ fontSize: "70%", width: "fit-content" }}
                  >
                    <option selected>Day</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <div className="chart">
                <HorizontalBar2 />{" "}
              </div>
              <NavLink to="/social" style={{ textDecoration: "none" }}>
                <span
                  style={{
                    color: "#18B6C0",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "0px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  Read more
                </span>
              </NavLink>
            </Progress>
          </div>
          <div className="item-11">
            <Progress className="min__width">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span>Traffic management</span>
                <div className="headprog">
                  <select
                    className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          text-xl	
                          m-0
                          mx-3
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
                    aria-label="Default select example"
                    style={{ fontSize: "70%", width: "fit-content" }}
                  >
                    <option selected>Select a date</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <select
                    className="form-select appearance-none
                    block
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding bg-no-repeat
                    border border-solid border-cyan-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    text-xl	
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
                    aria-label="Default select example"
                    style={{ fontSize: "70%", width: "fit-content" }}
                  >
                    <option selected>Day</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div>
              <div className="chart4">
                <span
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img src="/icons/tachometer.svg" alt="" />
                  </span>
                  <span style={{ paddingLeft: "20px" }}>
                    <span>
                      15
                      <br />
                      Number of roads in congestion
                    </span>
                  </span>
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img src="/icons/car2.svg" alt="" />
                  </span>
                  <span style={{ paddingLeft: "20px" }}>
                    <span>
                      169
                      <br />
                      Number of offenses per day
                    </span>
                  </span>
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img src="/icons/car3.svg" alt="" />
                  </span>
                  <span style={{ paddingLeft: "20px" }}>
                    <span>
                      1600
                      <br />
                      Number of vehicles per day
                    </span>
                  </span>
                </span>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img src="/icons/car.svg" alt="" />
                  </span>
                  <span style={{ paddingLeft: "20px" }}>
                    <span>
                      36%
                      <br />
                      Road congestion
                    </span>
                  </span>
                </span>
              </div>
              <NavLink to="/Trafic" style={{ textDecoration: "none" }}>
                <span
                  style={{
                    color: "#18B6C0",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "0px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  Read more
                </span>
              </NavLink>
            </Progress>
          </div>
        </RightStyle>
      </ContentStyle>
    </ContainerStyle>
  );
}

const ContainerStyle = styled.div`
  width: 100%;
  gap: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  .global__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .global__header_position {
      display: flex;
      gap: 0.5rem;
    }
  }
`;

const ContentStyle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 1rem;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 1615px) and (min-width: 1001px) {
    grid-auto-columns: 1fr;
  }
  .min__width {
    @media screen and (max-width: 550px) {
      min-width: 400px;
    }
  }
`;

const LeftStyle = styled.div`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 50px;
  gap: 1rem;
  @media screen and (max-width: 1615px) and (min-width: 1001px) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  .item-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    @media screen and (max-width: 750px) {
      grid-row: 1 / 4;
      grid-column: 1 / 3;
    }
    @media screen and (max-width: 1000px) and (min-width: 751px) {
      grid-row: 1 / 4;
    }
  }
  .item-2 {
    grid-column: 1/2;
    grid-row: 4 / 7;
    @media screen and (max-width: 750px) {
      grid-row: 4 / 7;
      grid-column: 1 / 3;
    }
    @media screen and (max-width: 1000px) and (min-width: 751px) {
      grid-column: 2 / 3;
      grid-row: 1 / 4;
    }
  }
  .item-3 {
    grid-column: 1/2;
    grid-row: 7 / 14;
    @media screen and (max-width: 750px) {
      grid-row: 7 / 14;
      grid-column: 1 / 3;
    }
    @media screen and (max-width: 1000px) and (min-width: 751px) {
      grid-column: 1 / 3;
      grid-row: 4 / 11;
    }
  }
`;
const MidleStyle = styled.div`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 50px;
  gap: 1rem;
  @media screen and (max-width: 1615px) and (min-width: 1001px) {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
  }
  .item-4 {
    grid-column: 1 / 2;
    grid-row: 1 / 5;
    overflow-x: auto;
    @media screen and (max-width: 750px) {
      grid-column: 1 / 3;
    }
  }
  .item-5 {
    grid-column: 2 / 3;
    grid-row: 1 / 5;
    overflow-x: auto;
    @media screen and (max-width: 750px) {
      grid-column: 1 / 3;
      grid-row: 5 / 9;
    }
  }
  .item-6 {
    grid-column: 1 / 3;
    grid-row: 5 / 10;
    @media screen and (max-width: 750px) {
      grid-row: 9 / 14;
    }
  }
  .item-7 {
    grid-column: 1 / 2;
    grid-row: 10 / 14;
    @media screen and (max-width: 750px) {
      grid-column: 1 / 3;
      grid-row: 14 / 17;
    }
  }
  .item-8 {
    grid-column: 2 / 3;
    grid-row: 10 / 14;
    overflow-x: auto;
    @media screen and (max-width: 750px) {
      grid-column: 1 / 3;
      grid-row: 17 / 21;
    }
  }
`;
const RightStyle = styled.div`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 50px;
  gap: 1rem;
  @media screen and (max-width: 1615px) and (min-width: 1001px) {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }
  .item-9 {
    grid-column: 1 / 2;
    grid-row: 1 / 6;
    overflow-x: auto;
  }
  .item-10 {
    grid-column: 1/2;
    grid-row: 6 / 10;
    overflow-x: auto;
  }
  .item-11 {
    grid-column: 1 / 2;
    grid-row: 10 / 14;
    overflow-x: auto;
  }
`;
export default Global;
