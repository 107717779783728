import React from 'react';

import Chart from "react-apexcharts";
import styled from 'styled-components';

export default function AreaChart() {
    return <Chart
        type='area'
        width='100%'
        height='100%'
        series={[
            {
                name: "Route",
                data: [30, 52, 38, 45, 19, 23, 2]
            }
        ]}
        options={
            {
                legend: {
                    show: false,
                },
                title: {
                    text: 'Daily congestion trends',
                    align: 'left',
                    style: {
                        color: "#161E54",
                        fontSize: '15px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                    },
                },
                colors: ["#855CF8"],
                chart: {
                    type: "area",
                    toolbar: {
                        show: false,},
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100]
                    }
                },
                xaxis: {
                    categories: [
                        "01/01",
                        "01/05",
                        "01/03",
                        "01/04",
                        "01/05",
                        "01/06",
                        "01/07"
                    ]
                }
            }}

    // series= {[{
    //     data: [{
    //       x: new Date('2018-02-12').getTime(),
    //       y: 76
    //     }, {
    //       x: new Date('2018-02-12').getTime(),
    //       y: 76
    //     }]
    //   }]}
    //   options= {{
    //     chart: {
    //       type: 'area',
    //       height: 350,
    //       zoom: {
    //         enabled: false
    //       }
    //     },
    //     dataLabels: {
    //       enabled: false
    //     },
    //     stroke: {
    //       curve: 'straight'
    //     },

    //     title: {
    //       text: 'Fundamental Analysis of Stocks',
    //       align: 'left'
    //     },
    //     subtitle: {
    //       text: 'Price Movements',
    //       align: 'left'
    //     },
    //     // labels: series.monthDataSeries1.dates,
    //     xaxis: {
    //       type: 'datetime',
    //     },
    //     yaxis: {
    //       opposite: true
    //     },
    //     legend: {
    //       horizontalAlign: 'left'
    //     }
    //   }}

    />
}

