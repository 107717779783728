import React from 'react';

import Chart from "react-apexcharts";
import styled from 'styled-components';

export default function Baroccupation() {
  return <Chart
        type="bar"
        width='100%'
        height='80%'
        series={[
          {
            data: [0, 0, 9, 21, 15, 15, 23, 28, 38, 38, 35, 38, 38, 44],
          },
        ]}
        options={{
          chart: {
            toolbar: {
              show: false
            }
          },
          dataLabels: { enabled: false },
          colors: ['#18B6C0'],
          yaxis: {
            show: true,
          },
  
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [
                "0h",
                "1h",
                "2h",
                "3h",
                "4h",
                "5h",
                "6h",
                "7h",
                "8h",
                "9h",
                "10h",
                "11h",
                "12h",
                "13h",
            ],
            labels: {
              show: true,
            },
          },
        }}
      />
}
