import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  Marker,
  Popup,
  useMapEvents,
  Polyline,
} from "react-leaflet";

import styled from "styled-components";

function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMapEvents({
    click(e) {
      console.log(e.latlng);
      map.locate();
    },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

const polyligne1:any = [
  [30.66422140802178, -9.3201002548856047],
  [30.66399472058412, -9.3211306582838898],
  [30.65764705836675, -9.318726383687877],
  [30.65390609737169, -9.313230898897008],
  [30.648464204009485, -9.303442066613255],
];
const polyligne2:any = [
  [30.64472256162504, -9.299835654719256],
  [30.64234137193024, -9.2919358953323736],
  [30.64449578649765, -9.282147063048621],
  [30.64710363889878, -9.2756211748594657],
];
const polyligne3:any = [
  [30.648237445706904, -9.27459077146118],
  [30.64869096129241, -9.269782222269155],
  [30.6467634918854, -9.262741132380857],
  [30.64869096129241, -9.252952300097104],
];
const polyligne4:any = [
  [30.65129859675719, -9.2503762916013907],
  [30.65662682380747, -9.2395570559193527],
  [30.66252122738645, -9.218262052354718],
  [30.66456143726649, -9.213796970962134],
];
const polyligne5:any = [
  [30.65390609737169, -9.31374610059615],
  [30.66036758291476, -9.3058463412092682],
  [30.66456143726649, -9.293653234329503],
  [30.668301607548514, -9.2852382732434777],
];
const polyligne6:any = [
  [30.670454913004065, -9.279399320653166],
  [30.68722487927848, -9.2864404105415037],
];

const polyligne7:any = [
  [30.67000159318658, -9.2797427884526082],
  [30.663768032126846, -9.2771667799568944],
  [30.65640010221254, -9.2742473036617388],
  [30.65299915593105, -9.273388634163154],
  [30.64630995896534, -9.2702974239682976],
  [30.64041365983638, -9.2677214154725838],
  [30.630433737099104, -9.2629128662805575],
];

function Map1() {
  const L = require("leaflet");
  const position = { lat: 30.428480398828107, lng:  -9.597313996784024};
  const GreenMarker = new L.Icon({
    iconUrl: "/Social/GreenMarker.svg",
  });
  const BlueMarker = new L.Icon({
    iconUrl: "/Social/BlueMarker.svg",
  });
  const OrangeMarker = new L.Icon({
    iconUrl: "/Social/OrangeMarker.svg",
  });
  const RedMarker = new L.Icon({
    iconUrl: "/Social/RedMarker.svg",
  });
  const YellowMarker = new L.Icon({
    iconUrl: "/Social/YellowMarker.svg",
  });
  return (
    <Map>
      <div className="details">
        <div className="mapTitle">
          <p>Map</p>
        </div>
        <div className="legend legend1">
          <p className="p1">Line 1</p>
          <p className="p2">Line 2</p>
          <p className="p3">Line 3</p>
        </div>
      </div>
      <MapContainer
        style={{
          width: "100%",
          height: "80%",
          minHeight: "300px",
          border: "1px solid #18B6C0",
          zIndex: 1,
        }}
        center={position}
        zoom={10}
        scrollWheelZoom={true}
      >
        <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />

        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
          <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <Polyline positions={polyligne1} color='#4AC9A3' weight={10}/>
        <Polyline positions={polyligne2} color='#FF4B55' weight={10}/>
        <Polyline positions={polyligne3} color='#FF6E01' weight={10}/>
        <Polyline positions={polyligne4} color='#FF6E01' weight={10}/>
        <Polyline positions={polyligne5} color='#4AC9A3' weight={10}/>
        <Polyline positions={polyligne6} color='#FF4B55' weight={10}/>
        <Polyline positions={polyligne7} color='#4AC9A3' weight={10}/>
      </MapContainer>
      <div className="legend legend2">
        <p className="p1">Line 1</p>
        <p className="p2">Line 2</p>
        <p className="p3">Line 3</p>
      </div>
    </Map>
  );
}

export default Map1;

const Map = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 420px;
  .details {
    display: flex;
    justify-content: space-between;
    width: 90%;
    .legend1 p {
      font-size: 14px;
    }
    margin-bottom: 2rem;
  }
  .mapTitle {
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    p {
      font-weight: 700;
      margin: 0%;
    }
  }

  .legend {
    display: flex;
    gap: 1rem;
    p {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0%;
    }

    p::before {
      margin-right: 5px;
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background-color: #0C7FDA;
    }
    .p2::before {
      background-color: #4AC9A3;
    }
    .p3::before {
      background-color: #FF6E01;
    }
  }

  .legend2 {
    display: none;
  }

  @media screen and (max-width: 1700px) {
    .mapTitle p {
      font-size: 18px !important;
    }
    .details {
      p {
        font-size: 15px;
      }
      .legend1 {
        display: none;
      }
      .mapTitle p {
        text-align: center;
        font-size: 14px;
      }
    }
    .legend2 {
      display: flex !important;
      justify-content: space-around;
      width: 90%;
      @media screen and (max-width: 425px) {
        font-size: 12px;
        p::before {
          height: 10px;
          width: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 755px) {
    .legend2 {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0.1rem;
    }
    .legend {
      p {
        height: min-content;
      }
    }
  }
  width: 100%;

`;
