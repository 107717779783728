import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function VerticlBarglob() {
  return (
    <Chart
      type="bar"
      width="100%"
      height="100%"
      series={[
        {
          data: [
            4, 6, 7, 4, 1, 7, 2, 3, 5, 4, 4, 6, 7, 4, 1, 7, 2, 3, 5, 4, 4, 6, 7,
            4, 1, 7, 2, 3, 5, 4, 4, 1, 7, 2, 3, 5, 4, 5, 5, 4, 5, 5, 4, 5, 9, 4,
            8, 10, 0, 2, 5, 9, 4, 1, 3, 5, 6, 8, 0,
          ],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: ["#FA0082"],
        chart: {
          // stacked: false,
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Consumption",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#161E54",
            fontSize: "15px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
          },
        },
        yaxis: {
          show: false,
        },

        //   plotOptions: {
        //     bar: {
        //       horizontal: true
        //     }
        //   },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
            // rotate: 320,
            // rotateAlways: true,
          },
        },
      }}
    />
  );
}
