import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

function Select(props: any) {
  return (
    <MyDiv className="selectButton">
      <select name={props.name} id={props.id}>
        <option value="">{props.value}</option>
      </select>
    </MyDiv>
  );
}

export default function VerticlBar() {
  return (
    <Emission>
      <div className="container__head">
        <div className="title_select">
          <p className="chartTitle">Emission CO2</p>
          <div className="select_Button">
            <Select
              forTag="dateSelected"
              name="date"
              id="dateSelected"
              value="Select a date"
            />
            <Select
              forTag="daySelected"
              name="day"
              id="daySelected"
              value="day"
            />
            <Select
              forTag="ligneSelected"
              name="ligne"
              id="ligneSelected"
              value="Line 1"
            />
          </div>
        </div>
      </div>
      <Chart
        type="bar"
        width="100%"
        height="70%"
        series={[
          {
            data: [345, 270, 240, 200, 345, 420, 280, 290, 210],
          },
        ]}
        options={{
          dataLabels: { enabled: false },
          colors: ["#18B6C0"],
          chart: {
            // stacked: false,
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [
              "Bus 1",
              "Bus 2",
              "Bus 3",
              "Bus 4",
              "Bus 5",
              "Bus 6",
              "Bus 7",
              "Bus 8",
              "Bus 9",
            ],
            labels: {
              show: true,
              // rotate: 320,
              // rotateAlways: true,
            },
          },
          yaxis: {
            labels: {
              formatter: (value) => `${value}g`,
            },
          },
        }}
      />
    </Emission>
  );
}

const Emission = styled.div`
  height: 95%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 550px) {
    min-width: 500px;
  }
  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 0.2rem;
    .chartTitle {
      display: flex;
      align-items: center;
    }
    .title_select {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 630px) {
        flex-direction: column;
        gap: 1rem;
      }
    }
    .select_Button {
      display: flex;
      gap: 0.8rem;
    }
    .selectButton {
      border: 0.87px solid #dcdcdc;
      color: #6d6d6d;
    }
  }
`;

const MyDiv = styled.div`
  width: max-content;
  select {
    /* width: 195px; */
    /* width: min-content; */
    /* height: 44px; */
    padding: 0.3rem;
    background-color: #ffffff;
    border-radius: 4px;
    text-align: center;
    @media screen and (max-width: 446px) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 630px) {
      /* height: 30px; */
      align-items: center;
    }
  }
`;
