import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Frequentation() {
  return (
    <Equipe>
      <div className="min__width">
        <div className="container__head">
          <p className="chartTitle">Attendance statistics</p>
          <button className="exporter">
            More
            <img src="./Evenement/RightArrow.svg" alt="" />
          </button>
        </div>
        <Chart
          type="bar"
          height={250}
          series={[
            {
              data: [95, 20, 25, 60, 75],
            },
          ]}
          options={{
            dataLabels: { enabled: false },
            colors: ["#426579", "#86DFE4", "#54C3CA", "#54C3CA", "#18B6C0"],
            chart: {
              stacked: true,
              toolbar: {
                show: false,
              },
              background: "#fff",
            },
            tooltip: {
              followCursor: true,
            },
            xaxis: {
              categories: [
                "Cinemas",
                "Theaters",
                "libraries",
                "Swimming pools",
                "Conservatories",
              ],
            },
            legend: {
              show: false,
            },
          }}
        />
      </div>
    </Equipe>
  );
}

const Equipe = styled.div`
  /* width: 100%; */
  min-height: 300px;
  grid-column: 1/3;
  grid-row: 1 / 2;
  background-color: #fff;
  overflow-x: auto;
  @media screen and (max-width: 700px) {
    .min__width {
      min-width: 500px;
      width: 100%;
    }
  }
  @media screen and (max-width: 730px) {
    grid-column: 1;
    grid-row: 1 / 2;
  }
  @media screen and (max-width: 1200px) and (min-width: 731px) {
    grid-column: 1/3;
  }
  @media screen and (max-width: 1850px) and (min-width: 1151px) {
    min-height: 380px;
  }
  .exporter {
    color: #18b6c0;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
