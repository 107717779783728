import React, { useState } from "react";
import styled from "styled-components";
import Cards from "./Cards/Cards";
import OccupationChart from "./Charts/OccupationChart";
import BilletterieChart from "./Charts/Billetterie";
import FilmsChart from "./Charts/FilmsChart";
import FrequentationChart from "./Charts/Frequentation";
import NombresCard from "./Cards/NombresCard";
import TravailCard from "./Cards/TravailCard";
const Evenements = () => {
  return (
    <Content>
      <Cards />
      <SecondRow>
        <OccupationChart />
        <BilletterieChart />
        <FilmsChart />
      </SecondRow>
      <ThirdRow>
        <FrequentationChart />
        <NombresCard />
        <TravailCard />
      </ThirdRow>
    </Content>
  );
};

export default Evenements;

const Content = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
`;

const SecondRow = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-auto-columns: 1fr;
`;

const ThirdRow = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-auto-columns: 1fr;
  /* background-color: yellow; */
`;
