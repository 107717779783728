import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export function TempHumPPMChart() {
  const state = {
    options: {
      chart: {
        id: "line",
        toolbar: {
          offsetY: -15,
        },
      },
      xaxis: {
        // categories: [
        //   "26 01 2022",
        //   "27 01 2022",
        //   "28 01 2022",
        //   "29 01 2022",
        //   "30 01 2022",
        //   "31 01 2022",
        //   "01 02 2022",
        // ],
      },

      yaxis: [
        {
          // seriesName: "Température",
          seriesName: "Temperature",
          axisTicks: {
            show: true,
            color: "#FB0400",
          },
          axisBorder: {
            show: true,
            color: "#FB0400",
          },
          labels: {
            show: true,
            style: {
              colors: ["#FB0400"],
            },
          },
          title: {
            // text: "Température (°C)",
            text: "Temperature (°C)",
            style: {
              color: "#FB0400",
            },
          },
        },
        {
          // seriesName: "Humidité",
          seriesName: "Humidity",
          opposite: true,
          axisTicks: {
            show: true,
            color: "#33BDEB",
          },
          axisBorder: {
            show: true,
            color: "#33BDEB",
          },
          labels: {
            style: {
              colors: ["#33BDEB"],
            },
          },
          title: {
            // text: "Humidité (%)",
            text: "Humidity (%)",
            style: {
              fontSize: "12px",
              color: "#33BDEB",
            },
          },
        },
        {
          seriesName: "Co2",
          opposite: true,
          axisTicks: {
            show: true,
            color: "#F49A06",
          },
          axisBorder: {
            show: true,
            color: "#F49A06",
          },
          labels: {
            style: {
              colors: "#F49A06",
            },
          },
          title: {
            text: "CO2 (ppm)",
            style: {
              fontSize: "12px",
              color: "#F49A06",
            },
          },
        },
      ],
    },
    series: [
      {
        // name: "Température",
        name: "Temperature",
        color: "#FB0400",
        data: [
          {
            x: "26 01 2024",
            y: 25,
          },
          {
            x: "27 01 2024",
            y: 27.8,
          },
          {
            x: "28 01 2024",
            y: 26.5,
          },
          {
            x: "29 01 2024",
            y: 28.5,
          },
          {
            x: "30 01 2024",
            y: 25.4,
          },
          {
            x: "31 01 2024",
            y: 22.4,
          },
          {
            x: "01 02 2024",
            y: 24.1,
          },
        ],
      },
      {
        // name: "Humidité",
        name: "Humidity",
        color: "#33BDEB",
        data: [
          {
            x: "26 01 2024",
            y: 45,
          },
          {
            x: "27 01 2024",
            y: 48,
          },
          {
            x: "28 01 2024",
            y: 45,
          },
          {
            x: "29 01 2024",
            y: 50,
          },
          {
            x: "30 01 2024",
            y: 38,
          },
          {
            x: "31 01 2024",
            y: 47,
          },
          {
            x: "01 02 2024",
            y: 45,
          },
        ],
      },
      {
        name: "Co2",
        color: "#F49A06",
        data: [
          {
            x: "26 01 2024",
            y: 600,
          },
          {
            x: "27 01 2024",
            y: 660,
          },
          {
            x: "28 01 2024",
            y: 700,
          },
          {
            x: "29 01 2024",
            y: 645,
          },
          {
            x: "30 01 2024",
            y: 500,
          },
          {
            x: "31 01 2024",
            y: 550,
          },
          {
            x: "01 02 2024",
            y: 600,
          },
        ],
      },

      // {
      //   data: [30, 40, 45, 50, 49, 60, 70, 91],
      // },
      // {
      //   data: [30, 40, 45, 50, 49, 60, 70, 91],
      // },
    ],
  };
  return (
    <TempStyle>
      <strong className="h5 __title">
        {/* Température (°C) / Humidité (%) / CO2 (PPM) indoor */}
        Temperature (°C) / Humidity (%) / CO2 (PPM) indoor
      </strong>

      <Chart
        options={state.options}
        series={state.series}
        type="line"
        height={350}
      />
    </TempStyle>
  );
}

const TempStyle = styled.div`
  width: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
