import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import LongueurChart from "./LongueurChart";
import Budget from "./BudgetChart";

export default function Travaux({ data }: any) {
  const [filterdData, setFilterdData] = useState([] as any);
  const [value, setValue] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const numberPerPage = 2;
  // const [pageVisited, setPageVisited] = useState(pageNumber * numberPerPage)
  let pageVisited = pageNumber * numberPerPage;

  const [pageCount, setPageCount] = useState(
    Math.ceil(data.length / numberPerPage)
  );
  const filterData = (e: any) => {
    setValue(e.target.value);
    if (e.target.value != "") {
      const filterTable = data.filter((element: any) => {
        return element.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setFilterdData([...filterTable]);
    } else {
      setPageCount(Math.ceil(data.length / numberPerPage));
    }
  };
  const changePage = ({ selected }: any) => {
    setPageNumber(selected);
  };
  useEffect(() => {
    if (value && filterdData.length) {
      // console.log(value);

      pageVisited = 0;
      console.log(filterdData);
      setPageCount(Math.ceil(filterdData.length / numberPerPage));
    }
  }, [filterdData, value]);
  return (
    <Content>
      <div className="container__head">
        {/* <p className="chartTitle">Travaux</p> */}
        <p className="chartTitle">Construction work</p>
      </div>
      <Tableau>
        <SearchInput>
          {value ? (
            <input
              type="text"
              placeholder="Recherche"
              onChange={filterData}
              value={value}
            ></input>
          ) : (
            <input
              type="text"
              placeholder="Recherche"
              onChange={filterData}
              value={""}
            ></input>
          )}
          <img src="/Voiries/Search.svg" alt="" />
        </SearchInput>
        <table>
          <tr className="tableHeader">
            {/* <th>Type de travaux</th> */}
            <th>Construction type</th>
            {/* <th>Longueur de voie en travaux</th> */}
            <th>Length of road in construction</th>
            {/* <th>Montant total</th> */}
            <th>Total amount</th>
          </tr>
          {value
            ? filterdData
                .slice(pageVisited, pageVisited + numberPerPage)
                .map((element: any) => {
                  console.log("salam");
                  return (
                    <tr>
                      <td className="td_content">
                        <p className="td_content_name">{element.name}</p>
                        <p>{element.description}</p>
                      </td>
                      <td className="td_details">{element.km}</td>
                      <td className="td_details">{element.montant}</td>
                    </tr>
                  );
                })
            : data
                .slice(pageVisited, pageVisited + numberPerPage)
                .map((element: any) => (
                  <tr>
                    <td className="td_content">
                      <p className="td_content_name">{element.name}</p>
                      <p>{element.description}</p>
                    </td>
                    <td className="td_details">{element.km}</td>
                    <td className="td_details">{element.montant}</td>
                  </tr>
                ))}
        </table>
        <ReactPaginate
          breakLabel={"..."}
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="active"
        />
      </Tableau>
      <div className="Charts">
        <LongueurChart />
        <Budget />
      </div>
    </Content>
  );
}

const Content = styled.div`
  background-color: #fff;
  textarea:focus,
  input:focus {
    outline: none;
  }
  height: 73%;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  .container__head {
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    @media screen and (max-width: 425px) {
      p {
        font-size: 15px;
      }
    }
  }
  .Charts {
    display: flex;
    height: 45%;
  }
  @media screen and (max-width: 560px) {
    .Charts {
      display: flex;
      flex-direction: column;
    }
  }
`;

const Tableau = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
  justify-content: space-evenly;
  padding: 0.5rem 2rem;
  height: 45%;

  @media screen and (max-width: 438px) {
    overflow: scroll;
    .-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  table {
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid #e5e5e5;
      td {
        padding: 0.5rem 1rem;
        color: #18b6c0;
        font-weight: 700;
      }
      .td_content {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
        .td_content_name {
          color: #030229;
        }
      }
      .td_details {
        vertical-align: bottom;
      }
    }
  }
  .pagination {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    gap: 1rem;
  }

  .pagination .page-num {
    text-decoration: none;
    color: #303030;
    padding: 6px 8px;
    cursor: pointer;
    border-radius: 50%;
    font-weight: 400;
  }

  .pagination .page-num:hover {
    background-color: #18b6c0;
    opacity: 0.2;
    color: #fff;
  }
  .pagination .active {
    background-color: #18b6c0;
    color: #fff;
  }
`;

const SearchInput = styled.div`
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  input {
    border: none;
  }
  align-self: flex-end;
  min-width: 150px;
  @media screen and (max-width: 870px) {
    overflow: scroll;
    input {
      width: 70%;
    }
  }
`;
