import { maxWidth } from "@mui/system";
import { divIcon } from "leaflet";
import * as React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import ReactApexChart from "react-apexcharts";

const Statistique = () => {
  return (
    <Chart
      type="bar"
      height="250px"
      // width="200px"
      series={[
        {
          name: "Completed",
          data: [3, 5, 7, 2],
        },
        {
          name: "In Planned",
          data: [5, 6, 3, 4],
        },
        {
          name: "in progress",
          data: [11, 9, 13, 11],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: ["#CBF2F4", "#F9A363", "#5090AC"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "Watering public green spaces",
            "Urban cleaning",
            "Watering golf courses",
            "Providing water for snow cannons",
          ],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            align: "left",
            maxWidth: 210,
            style: {
              fontSize: "12px",
            },
          },
        },
        legend: {
          show: false,
          horizontalAlign: "center",
          markers: {
            radius: 20,
          },
        },
        grid: {
          show: false,
        },
      }}
    />
  );
};

const Source = () => {
  return (
    <Chart
      type="bar"
      height="250px"
      series={[
        {
          name: "Lighting",
          data: [7, 4, 2, 7, 11, 7, 2],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: ["#18B6C0"],
        chart: {
          toolbar: {
            show: false,
          },
          background: "#fff",
        },
        yaxis: {
          show: true,
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "Potable water",
            "Drilling/counting",
            "Open surface waters",
            "Rainy waters",
            "Treated wastewater",
            "Drain water",
            "Sea water desalination",
          ],
          labels: {
            show: true,
          },
        },
        legend: {
          show: true,
          markers: {
            radius: 50,
          },
        },
        grid: {
          show: true,
        },
        responsive: [{}],
      }}
    />
  );
};

const Arrosage = () => {
  return (
    <Chart
      type="bar"
      height="250px"
      series={[
        {
          name: "Completed",
          data: [20, 19, 20, 18, 20],
        },
        {
          name: "In Planned",
          data: [5, 6, 3, 4, 4],
        },
        {
          name: "In progress",
          data: [11, 9, 13, 11, 11],
        },
        {
          name: "late",
          data: [11, 9, 13, 11, 11],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: ["#86DFE4", "#18B6C0", "#5090AC", "#426579"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "Young plants",
            "Large objects",
            "Shrub beds",
            "Pleasure lawns",
            "Sports field lawns",
          ],
          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
            offsetX: -10,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: true,
          },
          labels: {
            align: "left",
          },
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          position: "top",
          markers: {
            radius: 20,
          },
        },
        grid: {
          show: false,
        },
      }}
    />
  );
};
const Clamart = () => {
  return (
    <Chart
      type="area"
      height="250px"
      series={[
        {
          name: "Completed",
          data: [20, 19, 20, 18, 20],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: ["#FFDE54"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "2024-01-12",
            "2024-01-13",
            "2024-01-14",
            "2024-01-15",
            "2024-01-16",
          ],
          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
            offsetX: -10,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: true,
          },
          tooltip: {
            enabled: true,
          },
          labels: {
            align: "left",
          },
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          position: "top",
          markers: {
            radius: 20,
          },
        },
        grid: {
          show: false,
        },
      }}
    />
  );
};
const Mydiv = styled.div`
  .my-card {
    border-radius: 1em;
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    .my-card {
      width: 80px;
      img {
        width: 25px;
      }
      span {
        font-size: 0.9rem;
      }
    }
  }
`;
const Badge = (props: any) => {
  return (
    <BadgeStyle>
      <div className="badge__image">
        <img src={props.src} alt="" />
      </div>
      <div>
        <div className="p-0 badge__title" style={{ textAlign: "right" }}>
          {props.name}
        </div>
        <div className="p-0" style={{ fontWeight: "700", textAlign: "right" }}>
          {props.value}
        </div>
      </div>
    </BadgeStyle>
  );
};

function Page1() {
  return (
    <ContainerStyle>
      <div className="__badges">
        <Badge src="/espaceVert/icon/ciel.svg" name="Cloudy sky" />
        <Badge
          src="/espaceVert/icon/temperature.svg"
          name="Temperature"
          value="20.1°C"
        />
        <Badge
          src="/espaceVert/icon/Humidite.svg"
          name="Humidity"
          value="43%"
        />
        <Badge
          src="/espaceVert/icon/vitesse.svg"
          name="Wind speed"
          value="12 Km/h"
        />
        <Badge
          src="/espaceVert/icon/Rayonnement.svg"
          name="Solar radiation"
          value="80 w/m²"
        />
        <Badge
          src="/espaceVert/icon/evapotransp.svg"
          name="Evapotranspiration"
          value="7.5 mm"
        />
        <Badge
          src="/espaceVert/icon/plus.svg"
          onMouseEnter={(e: any) => console.log(e)}
          name=""
          value=""
        />
      </div>
      <ContentStyle>
        <div className="item-1">
          <div className="min__width">
            <div className="__topSide_left_header">
              <div className="_left_header_title">
                Water supply sources for watering green areas
              </div>
              <div className="">
                <select
                  className="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                >
                  <option disabled selected>
                    {" "}
                    Location
                  </option>
                  <option>Site 1</option>
                  <option>Site 2</option>
                  <option>Site 3</option>
                </select>
              </div>
            </div>
            <div className="">
              <Source />
            </div>
          </div>
        </div>
        <div className="item-2">
          <div className="min__width">
            <div className="__topSide_right_header">
              <div
                className="_right_header_title"
                style={{ fontWeight: "700" }}
              >
                Statistics
              </div>
              <div className="">
                <select
                  className="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                >
                  <option disabled selected>
                    Location
                  </option>
                  <option>Site 1</option>
                  <option>Site 2</option>
                  <option>Site 3</option>
                </select>
              </div>
            </div>
            <div className="row">
              <Statistique />
            </div>
          </div>
        </div>
        <div className="item-3">
          <div className="min__width">
            <div className="bottom_header">
              <div
                className="bottom_header_title"
                style={{ fontWeight: "700" }}
              >
                watering
              </div>
              <div className="">
                <select
                  className="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                >
                  <option disabled selected>
                    Location
                  </option>
                  <option>Site 1</option>
                  <option>Site 2</option>
                  <option>Site 3</option>
                </select>
              </div>
            </div>
            <div className="">
              <Arrosage />
            </div>
          </div>
        </div>
        <div className="item-4">
          <div className="d-flex justify-content-between">
            <div>
              <img src="/espaceVert/icon/alert.svg" alt="" />
            </div>
            <div
              style={{
                fontSize: "45px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              13
            </div>
          </div>
          <div className="">
            <div className="" style={{ fontSize: "20px" }}>
              Technical alerts
            </div>
          </div>
        </div>
        <div className="item-5">
          <div className="">
            <div className="d-flex justify-content-between">
              <div style={{ fontSize: "23px", fontWeight: "700" }} className="">
                <div className="">
                  Clamart,
                  <br />
                  France
                </div>
                <div className="">13°C</div>
              </div>
              <div className="">
                <div style={{ fontWeight: "600", color: "#18B6C0" }}>
                  More forecasts
                </div>
              </div>
            </div>
          </div>
          <Mydiv className="d-flex justify-content-around">
            <div
              className="d-flex justify-content-around my-card"
              style={{
                backgroundColor: "rgba(0, 60, 255, 0.1)",
                color: "#18B6C0",
              }}
            >
              <img src="/espaceVert/icon/plui.svg" alt="" />
              <span>6%</span>
            </div>
            <div
              className="d-flex justify-content-around my-card"
              style={{
                backgroundColor: "rgba(244, 40, 41, 0.1)",
                color: "#F42829",
              }}
            >
              <img src="/espaceVert/icon/tempera.svg" alt="" />
              <span>90%</span>
            </div>
            <div
              className="d-flex justify-content-around my-card"
              style={{
                backgroundColor: "rgba(217, 111, 248, 0.1)",
                color: "#D96FF8",
              }}
            >
              <img src="/espaceVert/icon/vent.svg" alt="" /> <span>19km/h</span>
            </div>
          </Mydiv>
          <div className="mt-2">
            <Clamart />
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ fontWeight: "500" }}
          >
            <div className="row">
              <span>Monday</span>
              <span>Tuesday</span>
              <span>Wednesday</span>
            </div>
            <div className="row" style={{ textAlign: "end" }}>
              <span>13°C 10°C</span>
              <span>13°C 10°C</span>
              <span>13°C 10°C</span>
            </div>
          </div>
        </div>
      </ContentStyle>
    </ContainerStyle>
  );
}

const ContainerStyle = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  .__badges {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.5rem;
    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (max-width: 750px) and (min-width: 451px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 1260px) and (min-width: 751px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 1800px) and (min-width: 1261px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

const BadgeStyle = styled.div`
  height: 100px;
  background-color: white;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 5px;
  .badge__image {
    width: 56px;
  }
  .badge__title {
    font-size: 14px;
  }
`;

const ContentStyle = styled.div`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 175px;
  /* grid-auto-rows: 1fr; */
  gap: 1rem;

  .__topSide_right_header,
  .__topSide_left_header,
  .bottom_header {
    select {
      @media screen and (max-width: 1500px) {
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
        font-size: 0.9rem;
      }
    }
  }
  .min__width {
    @media screen and (max-width: 600px) {
      min-width: 550px;
    }
  }
  .item-1 {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    .__topSide_left_header {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      ._left_header_title {
        width: 50%;
      }
    }
    @media screen and (max-width: 850px) {
      grid-row: 4 / 6;
      grid-column: 1 / 2;
    }
    @media screen and (max-width: 1450px) and (min-width: 851px) {
      grid-row: 2 / 4;
      grid-column: 1 / 3;
    }
    overflow-x: auto;
  }
  .item-2 {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    .__topSide_right_header {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      ._right_header_title {
        width: 50%;
      }
    }
    @media screen and (max-width: 850px) {
      grid-row: 2 / 4;
      grid-column: 1 / 2;
    }
    @media screen and (max-width: 1450px) and (min-width: 851px) {
      grid-row: 4 / 6;
      grid-column: 1 / 4;
    }
    overflow-x: auto;
  }
  .item-3 {
    grid-column: 1 / 3;
    grid-row: 3 / 5;
    width: 100%;
    .bottom_header {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      .bottom_header_title {
        width: 50%;
      }
    }
    @media screen and (max-width: 850px) {
      grid-row: 6 / 8;
      grid-column: 1 / 2;
    }
    @media screen and (max-width: 1450px) and (min-width: 851px) {
      grid-row: 6 / 8;
      grid-column: 1/4;
    }
    overflow-x: auto;
  }
  .item-4 {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
    padding: 1rem;
    background-color: #f6e3e8;
    border-radius: 8px;
    /* height: 180px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 850px) {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    @media screen and (max-width: 1450px) and (min-width: 851px) {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
  }
  .item-5 {
    grid-column: 3 / 4;
    grid-row: 2 / 5;
    @media screen and (max-width: 850px) {
      grid-row: 8 / 11;
      grid-column: 1 / 2;
    }
    @media screen and (max-width: 1450px) and (min-width: 851px) {
      grid-row: 1 / 4;
      grid-column: 3 / 4;
    }
  }
  .item-1,
  .item-2,
  .item-3,
  .item-5 {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }
`;

const LeftSideStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .__topSide {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    .__topSide_left {
    }
    .__topSide_right {
    }
  }

  .__bottomSide {
  }
`;

const RightSideStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .rightSide__top {
  }
`;
export default Page1;
