import styled from "styled-components";
import Accednetbar from "../charts/accedent";
import Agressionbar from "../charts/agressionbar";
import Pierepartitiontransport from "../charts/Peirepartitiontransport";
import PieChart from "../charts/PieChart";
import Pierepartition from "../charts/Pierepartition";
import VerticlBar from "../charts/Verticalvar";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
`;
const Twocards = styled.div`
  width: 100%;
  display: flex;
  //800
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
  justify-content: space-between;
  gap: 20px;
  .lefcards {
    width: 50%;
    display: grid;
    @media screen and (max-width: 1100px) {
      width: 100%;
      /* flex-direction: column; */
    }
    gap: 1rem;
    grid-auto-columns: 1fr;
    .item-1 {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
      @media screen and (max-width: 450px) {
        grid-column: 1;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-column: 1 / 2;
      }
    }
    .item-2 {
      grid-row: 1 / 2;
      grid-column: 3 / 5;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 2 / 3;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-column: 2 / 3;
      }
    }
    .item-3 {
      grid-row: 1 / 2;
      grid-column: 5 / 7;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 3 / 4;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
      }
    }
    .item-4 {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 4 / 5;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
      }
    }
    .item-5 {
      grid-row: 2 / 3;
      grid-column: 3 / 5;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 5 / 6;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
      }
    }
    .item-6 {
      grid-row: 2 / 3;
      grid-column: 5 / 7;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 6 / 7;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
      }
    }
    .item-7 {
      grid-row: 3 / 4;
      grid-column: 1 / 4;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 7 / 8;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 4 / 5;
        grid-column: 1 / 2;
      }
    }
    .item-8 {
      grid-row: 3 / 4;
      grid-column: 4 / 7;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 8 / 9;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 4 / 5;
        grid-column: 2 / 3;
      }
    }
    .recette {
      height: 120px;
      background: #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
    }
    .ticket {
      height: 130px;
      background: #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
    }
  }
  .rightcard {
    width: 50%;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 10px;
    overflow-x: auto;
    height: 402px;
    .rightcard__item {
      height: 100%;
      width: 100%;
      @media screen and (max-width: 570px) {
        min-width: 570px;
      }
    }
  }
`;
const Threecards = styled.div`
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  gap: 1rem;
  .card1 {
    height: 350px;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    @media screen and (max-width: 700px) {
      grid-column: 1;
    }
    @media screen and (max-width: 1300px) and (min-width: 701px) {
      grid-row: 2 / 3;
      grid-column: 1/2;
    }
  }
  .card2 {
    height: 350px;
    grid-row: 1 / 2;
    grid-column: 2 / 4;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 1rem;
    @media screen and (max-width: 700px) {
      grid-row: 2 / 3;
      grid-column: 1;
    }
    @media screen and (max-width: 1300px) and (min-width: 701px) {
      grid-column: 1/3;
    }
    @media screen and (max-width: 450px) {
      height: 400px;
    }
    .card2__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 450px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }
    }
  }
  .card3 {
    height: 350px;
    grid-row: 1 / 2;
    grid-column: 4 / 5;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 1rem;
    @media screen and (max-width: 700px) {
      grid-row: 3 / 4;
      grid-column: 1;
    }
    @media screen and (max-width: 1300px) and (min-width: 701px) {
      grid-row: 2 / 3;
      grid-column: 2/3;
    }
  }
`;
const TravPub = () => {
  return (
    <Content>
      <Twocards>
        <div className="lefcards">
          <div className="recette item-1">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/verifie.svg" alt="" />
              <span style={{ fontWeight: "900" }}>37,2 M€</span>
            </div>
            {/* <span>Recettes</span> */}
            <span>Revenues</span>
          </div>
          <div className="recette item-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/verifieorange.svg" alt="" />
              <span style={{ fontWeight: "900" }}>36,5 M€</span>
            </div>
            {/* <span>Dépenses</span> */}
            <span>Expenditures</span>
          </div>
          <div className="recette item-3">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <img src="/icons/verifieorange.svg" alt="" /> */}
              <span style={{ fontWeight: "900" }}>
                CO<sub>2</sub>
              </span>
              <span style={{ fontWeight: "900" }}>104 g/km</span>
            </div>
            <span>
              {/* Emission CO <sub>2</sub> */}
              CO<sub>2</sub> Emission
            </span>
          </div>
          <div className="ticket item-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/infovert.svg" alt="" />
              {/* <span style={{ color: "#18B6C0" }}>Tickets</span> */}
              <span style={{ color: "#18B6C0" }}>Tickets</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <span>Depuis 24h</span> */}
              <span>Since 24h</span>
              <span style={{ fontWeight: "900" }}>14.8M</span>
            </div>
          </div>
          <div className="ticket item-5">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/infovert.svg" alt="" />
              {/* <span style={{ color: "#18B6C0" }}>Voyages</span> */}
              <span style={{ color: "#18B6C0" }}>Trips</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>15/01/2024 12:45</span>
              <span style={{ fontWeight: "900" }}>55M</span>
            </div>
          </div>
          <div className="ticket item-6">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/infovert.svg" alt="" />
              {/* <span style={{ color: "#18B6C0" }}>Durée moy.</span> */}
              <span style={{ color: "#18B6C0" }}>Average duration</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <span>Minutes</span> */}
              <span>Minutes</span>
              <span style={{ fontWeight: "900" }}>35</span>
            </div>
          </div>
          <div className="recette item-7">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/cumul.svg" alt="" />
              <span style={{ fontWeight: "900" }}>1h</span>
            </div>
            {/* <span>Cumul mensuel des retards</span> */}
            <span>Monthly cumulative delays</span>
          </div>
          <div className="recette item-8">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/totalvoy.svg" alt="" />
              <span style={{ fontWeight: "900" }}>20</span>
            </div>
            {/* <span>Total voyageurs</span> */}
            <span>Total passengers</span>
          </div>
        </div>
        <div className="rightcard">
          <div className="rightcard__item">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <span>Cas d’agressions par nombre de voyages</span> */}
              <span>Number of assaults per trip</span>
              <select
                className="form-select appearance-none
              block
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding bg-no-repeat
              border border-solid border-cyan-300
              rounded
              transition
              ease-in-out
              m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                style={{ fontSize: "70%", width: "150px" }}
              >
                {/* <option selected>Ligne 1</option> */}
                <option value="1">Line 1</option>
                {/* <option value="1">Ligne 2</option> */}
                <option value="2">Line 2</option>
                {/* <option value="2">Ligne 3</option> */}
                <option value="3">Line 3</option>
              </select>
              <img src="/icons/info1.svg" alt="" />
            </div>
            <Agressionbar />
          </div>
        </div>
      </Twocards>
      <Threecards>
        <div className="card1">
          {/* <div>Flotte</div> */}
          <div>Fleet</div>
          <div>
            <img src="/icons/somme.svg" alt="" />
            <div
              style={{
                paddingLeft: "10px",
                width: "90%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <span>Total</span> */}
              <span>Total</span>
              <span>288</span>
            </div>
          </div>
          <div>
            <img src="/icons/exploitation.svg" alt="" />
            <div
              style={{
                paddingLeft: "10px",
                width: "90%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <span>En exploitation</span> */}
              <span>In operation</span>
              <span>254</span>
            </div>
          </div>
          <div>
            <img src="/icons/maintenance.svg" alt="" />
            <div
              style={{
                paddingLeft: "10px",
                width: "90%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <span>En maintenance</span> */}
              <span>In maintenance</span>
              <span>34</span>
            </div>
          </div>
        </div>
        <div className="card2">
          <div className="card2__header">
            {/* <span style={{ fontWeight: "900" }}>Accidents</span> */}
            <span style={{ fontWeight: "900" }}>Accidents</span>
            <div
              style={{
                // width: "50%",
                display: "flex",
                gap: "1rem",
                justifyContent: "space-around",
              }}
            >
              <select
                className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                style={{ fontSize: "70%", width: "100px" }}
              >
                {/* <option selected>Ligne 1</option> */}
                <option value="1">Line 1</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select
                className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                style={{ fontSize: "70%", width: "100px" }}
              >
                {/* <option selected>Jour</option> */}
                <option value="1">Day</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <Accednetbar />
        </div>
        <div className="card3">
          <Pierepartitiontransport />
        </div>
      </Threecards>
    </Content>
  );
};

export default TravPub;
