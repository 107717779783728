import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function HorizontalBar2() {
  return (
    <Chart
      type="bar"
      width="100%"
      height="150px"
      series={[
        {
          data: [58, 90, 33, 80, 48],
        },
      ]}
      options={{
        dataLabels: { enabled: true },
        colors: ["#18B6C0"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Occupancy rate by category",
          align: "left",
          style: {
            color: "#18B6C0",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "Day care center",
            "Accommodation",
            "Residence",
            "Nursing home",
            "CLIC",
          ],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            align: "right",
          },
        },
        legend: {
          horizontalAlign: "center",
          markers: {
            radius: 20,
          },
        },
        grid: {
          show: false,
        },
      }}
    />
  );
}
