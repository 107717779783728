import React from "react";
import AreaChart from "./Areachart";
import DonutChart from "./donutchart";
import  BarCht from "./horizontalbar"
import LineChart from "./Linechart";
import PieChart from "./PieChart";
import VerticlBar from "./Verticalvar";
const BarChart = () => {
    return <div>
        {/* amine */}
        <BarCht />
        <VerticlBar />
        <PieChart />
        <DonutChart/>
        <LineChart />
        <AreaChart />
    </div>
}
export default BarChart
