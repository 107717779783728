import React from 'react'
import { MapContainer, TileLayer, Rectangle, LayersControl, Marker, LayerGroup, Popup, FeatureGroup, Circle } from 'react-leaflet'

function Map2() {
    const L = require('leaflet');
    const position = { lat: 30.428480398828107, lng:  -9.597313996784024};
    const greenIcon = new L.Icon({
        iconUrl: '/assets/global/eclairage.svg',
        // shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
    return (
        <MapContainer
            style={{ width: "100%", height: "380px", border: "1px solid #18B6C0"}}
            center={position} zoom={10} scrollWheelZoom={true}>
            <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
            <Circle center={[51.505, -0.09]} fillColor="green" radius={200} fillOpacity={0.5} color="green"/>
            <Circle center={[51.505, -0.10]} fillColor="red" radius={200} fillOpacity={0.5} color="red"/>
            <Circle center={[51.520, -0.04]} fillColor="red" radius={200} fillOpacity={0.5} color="red"/>
            <Circle center={[51.505, -0.13]} fillColor="green" radius={200} fillOpacity={0.5} color="green"/>
            <Circle center={[51.515, -0.14]} fillColor="green" radius={200} fillOpacity={0.5} color="green"/>
            <LayersControl position="topright" >
                <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
                <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
                </LayersControl.BaseLayer>
            </LayersControl>
        </MapContainer>

    )
}

export default Map2