import React, { useState } from "react";
import styled from "styled-components";
import EtatChart from "./Tools/EtatChart";
import Map from "./Tools/Map";
import Statistique from "./Tools/Statistique";
import TypeChart from "./Tools/TypeChart";

const types = ["Statistics", "BIM", "Parameters"];

function SelectButtons() {
  const [active, setActive] = useState(types[0]);
  return (
    <div className="buttons">
      {types.map((element) => (
        <ButtonStyle
          active={active === element}
          onClick={() => setActive(element)}
        >
          {element}
        </ButtonStyle>
      ))}
    </div>
  );
}

function Patrimoine() {
  return (
    <Content>
      <TitleStyle>
        <img src="/Social/Logo.svg" alt="" />
        <p>heritage</p>
      </TitleStyle>
      <SelectButtons />
      <FirstRow>
        <Map></Map>
      </FirstRow>
      <SecondRow>
        <TypeChart />
        <EtatChart />
        <Statistique />
      </SecondRow>
    </Content>
  );
}

export default Patrimoine;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  gap: 2rem;
  p {
    margin-bottom: 0;
  }
  .buttons {
    display: flex;
    gap: 1rem;
  }
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
`;

const TitleStyle = styled.div`
  display: flex;
  gap: 1rem;
  p {
    color: #3f487e;
    font-weight: 600;
  }
`;

const ButtonStyle = styled.button<any>`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 0.8px solid #18b6c0;
  color: #18b6c0;
  width: 186px;
  text-align: left;
  padding-left: 1rem;
  min-height: 40px;
  @media screen and (max-width: 425px) {
    padding: unset;
    text-align: center;
    font-size: 15px;
    padding: 0 0.4rem;
  }
  ${({ active }) => active && `background: #18B6C0; color: #FFFFFF`}
`;

const FirstRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  @media screen and (max-width: 1024px) {
    justify-content: space-around;
  }
  width: 100%;
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    justify-content: space-around;
  }
`;
