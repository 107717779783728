import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function PieChartglolab2() {
  return (
    <Chart
      type="pie"
      width="100%"
      height="135px"
      // height='1000px'
      series={[15, 15, 15, 55, 0]}
      options={{
        colors: ["#42728D", "#FBACC8", "#FFA764", "#3CD1DB", "#fff"],
        plotOptions: {
          pie: {
            donut: {
              size: "100%",
            },
          },
        },
        labels: ["Cars", "Two-wheelers", "Buses", "Road transport", "of goods"],
        legend: {
          show: true,
          fontSize: "10px",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },

          itemMargin: {
            horizontal: 0,
            vertical: 0,
          },
        },
        dataLabels: {
          formatter: (val) => {
            return `${val}`;
          },
        },
      }}
    />
  );
}
