import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function DonutChart() {
  return (
    <div>
      <Chart
        type="donut"
        width="98%"
        height="100%"
        series={[327, 550, 100]}
        options={{
          labels: ["Network", "Battery", "Solar"],
          plotOptions: {
            pie: {
              donut: {
                size: "65%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 600,
                    color: undefined,
                    offsetY: -1,
                    formatter: function (val) {
                      return val;
                    },
                  },
                  value: {
                    show: true,
                    fontSize: "8px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                    color: undefined,
                    offsetY: -6,
                    formatter: function (val) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    label: "958W/h",
                    fontSize: "10px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    // fontWeight: 600,
                    color: "#373d3f",
                    formatter: function (val) {
                      return "Total power";
                    },
                  },
                },
              },
            },
          },
          colors: ["#FA0082", "#5B93FF", "#18B6C0"],
          dataLabels: {
            enabled: false,
          },
          // title: {
          //     text: 'Source d’énergie',
          //     align: 'left',
          //     style: {
          //         color: "#161E54",
          //         fontSize: '15px',
          //         fontFamily: 'Helvetica, Arial, sans-serif',
          //     },
          // },
          legend: {
            show: false,
          },
        }}
      />
    </div>
  );
}
