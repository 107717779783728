import React from "react";
import styled from "styled-components";

export default function Card({
  title,
  number,
}: any) {
  return (
    <CardStyle>
      <p className="titleCardStatistique">{title}</p>
      <p className="numberCardStatistique">{number}</p>
    </CardStyle>
  );
}


const CardStyle = styled.div`
  width: 24%;
  /* min-width: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #F8F8F8;
  padding: 0.5rem;
  .titleCardStatistique{
    color: #18B6C0;
    font-weight: 500;
  }
  .numberCardStatistique{
    font-size: 29px;
    font-weight: 500;
    text-align: right;
  }
  @media screen and (max-width: 710px) {
    min-width: 98%;
  }

  @media screen and (max-width: 1030px) and (min-width: 711px){
    height: 110px;
  }
`;

//rgba(24, 182, 192, 0.24)"} Title COlor
