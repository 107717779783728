import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function HorizontalBar() {
  return (
    <Chart
      type="bar"
      width="100%"
      height="200px"
      series={[
        {
          data: [58, 90, 33, 80, 48],
        },
      ]}
      options={{
        dataLabels: { enabled: true },
        colors: ["#EA759E"],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        title: {
          text: "Attendance %",
          align: "left",
          style: {
            color: "#18B6C0",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            // dataLabels:
            // {
            //   position: 'top',
            //   maxItems: 100,
            //   hideOverflowingLabels: false,
            //   orientation: 'horizontal',
            // }
          },
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "Conservatory",
            "Cinema",
            "Theater",
            "Library",
            "Swimming pool",
          ],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          labels: {
            align: "right",
          },
        },
        legend: {
          horizontalAlign: "center",
          markers: {
            radius: 20,
          },
        },
        grid: {
          show: false,
        },
      }}
    />
  );
}
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
// import { faker } from '@faker-js/faker';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// export const options: any = {
//   indexAxis: 'y',
//   elements: {
//     bar: {
//       borderWidth: 2,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'right',
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Horizontal Bar Chart',
//     },
//   },
// };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

// export function BarCht() {
//   return <Bar options={options} data={data} />;
// }
