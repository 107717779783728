import React from "react";
import styled from "styled-components";

export default function Select(props: any) {
  return (
    <MyDiv>
      <label htmlFor={props.forTag}></label>

      <select name={props.name} id={props.id}>
        <option value="">{props.value}</option>
      </select>
    </MyDiv>
  );
}

const MyDiv = styled.div`
    select{
        /* width: 195px; */
        /* width: min-content; */
        height: 44px;
        background-color: #FFFFFF;
        border-radius: 4px;
        text-align: center;
    }
`;
