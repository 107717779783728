import React, { useEffect, useState } from "react";
import { Col, Container, Row, Toast } from "react-bootstrap";
import "./media.css";
import styled from "styled-components";
// useNavi
function Login() {
  const Paragrafe = styled.p`
    font-size: 0.9rem;
    font-weight: bold;
    color: #8692a6;
    text-align: center;
    margin-bottom: 1rem;
  `;
  const [email, setEmail] = useState("Demo");
  const [password, setpassword] = useState("Demo");
  const [show, setShow] = useState(false);
  // let history = useHistory();
  useState(() => {
    if (
      localStorage.getItem("userConnecter") != null ||
      localStorage.getItem("userConnecter") != undefined
    ) {
      // history.push('/');
    }
  });
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <div className="ContentLogin">
      <Row>
        <Col>
          <img
            src="/foooter.svg"
            alt=""
            width="250px"
            style={{ marginLeft: "20px", marginTop: "10px" }}
          />
        </Col>
      </Row>
      <Row className="d-flex inputs i-test row">
        <Col xl={6} sm={12} className=" content">
          <h3 style={{ height: "full-content", textAlign: "center" }}>
            Account Login
          </h3>
          <Paragrafe>
            If you are already a member, you can log in with your email address
            and password.
          </Paragrafe>
          <p className="m-2">Email address</p>
          <input
            value={email}
            className="pl-10 inputText"
            type="text"
            onChange={(event) => {
              // event.preventDefault();
              setEmail(event.target.value);
            }}
          />
          <p className="m-2">password</p>
          <input
            value={password}
            className="pl-10 inputText"
            type="password"
            onChange={(event) => {
              // event.preventDefault();
              setpassword(event.target.value);
            }}
          />
          <div className="checkBoxInput  m-2">
            <input
              className="m-2"
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            <label>remember me</label>
            <br></br>
          </div>
          <button
            className="btnLogin"
            onClick={() => {
              if (email === "Demo" && password === "Demo") {
                localStorage.setItem("userConnecter", "Demo");
                window.location.href = "/";
              } else {
                setShow(true);
              }
            }}
          >
            Connect
          </button>
        </Col>
        <Col xl={6} sm={12} className="">
          <img className="w-100" src="/loginimage.svg" alt="" />
        </Col>

        <Col xs={6} position="top-end">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">message</strong>
            </Toast.Header>
            <Toast.Body>incorrect email or password</Toast.Body>
          </Toast>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
