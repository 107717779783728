import React, { Component, useEffect, useState } from "react";
import Content from "../Content/Content";
import HeaderCom from "../header/Header";
import Sidebar from "../Sidebar/Sidebar";
import styled from "styled-components";
import { propTypes } from "react-bootstrap/esm/Image";
import SidebarIcon from "../Sidebar/SidebarIcon";
import Budget from "../GestionsDuContrats/Budget";
import General from "../GestionsDuContrats/Genetal";
import Alerts from "../GestionDesInterventions /Alertes";
import Interventions from "../GestionDesInterventions /Interventions";

import Evenements from "../Hypervision/Evenements/Evenements"

import { Switch, Route, useHistory } from "react-router-dom";
import dashboardRoutes from "../../routes";

let windowheight = window.innerHeight;
let windowwidth = window.innerWidth;
let tmp = "";
const WrapperHeader = styled.section`
  width: 100%;
  height: 68px;
  background: #ffffff;
  box-shadow: 2px 3.16406px 5.27344px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 10000;
`;
const WrapperSidebar = styled.section`
  height: 100vh;
  width: 300px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0;
  position: sticky;
  top: 0;
  overflow-y: hidden;
  @media only screen and (max-width: 1100px) {
    position: absolute;
    top: 68px;
    z-index: 50;
    width: 240px;
    z-index: 1001;
    background: #fff;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 53px;
    height: auto;
  }
`;
const WrapperSidebarIcon = styled.section`
  height: 100%;
  width: 80px;
  box-shadow: 0px 1px 5 px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0;
  position: sticky;
  top: 0;
`;
const WrapperContent = styled.section`
  background: #F6F8FD;
  width: 100%;
  height: auto;
`;
const Mydiv = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
`;
const Mydiv2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F6F8FD;
`;
const DefaultLayout = () => {
  const [sidebaricon, setSidebaricon] = useState(true);
  const checksidebar = (ChildData: boolean) => {
    setSidebaricon(ChildData);
  };
  const switchRoutes = () => {

    return (
      <Switch>
        {dashboardRoutes.map((prop) => {
          return (
            <Route
              path={prop.path}
              component={prop.component}
            />
          );
        })}
      </Switch>
    )
  }
  const his = useHistory()
  if (!localStorage.getItem("userConnecter"))
  {
    his.push('/login')
  }
  useEffect(()=>{
    console.log(windowwidth);
    if (windowwidth <= 600)
    {
      setSidebaricon(false)
    }
  },[windowwidth])
  return (
    <Mydiv>
      {
        sidebaricon &&
        <WrapperSidebar>
          <Sidebar 
          sidebaricon={sidebaricon}
          setSidebaricon={setSidebaricon}
          checksidebar={checksidebar}
          />
        </WrapperSidebar>
      }
      <Mydiv2>
        <WrapperHeader>
          <HeaderCom
            sidebaricon={sidebaricon}
            setSidebaricon={setSidebaricon}
            checksidebar={checksidebar}
          />
        </WrapperHeader>
        <WrapperContent>
          {switchRoutes()}
        </WrapperContent>
      </Mydiv2>
    </Mydiv>
  );
};
export default DefaultLayout;
