import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Pierepartitiontransport() {
  return (
    <Chart
      type="pie"
      width="100%"
      height="290px"
      series={[12, 13, 75]}
      options={{
        colors: ["#42728D", "#FBACC8", "#3CD1DB"],
        plotOptions: {
          pie: {
            donut: {
              size: "90%",
            },
          },
        },
        title: {
          text: "Distribution of accidents by origin",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#161E54",
            fontSize: "15px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
          },
        },
        labels: [],
        legend: {
          show: false,
          itemMargin: {
            horizontal: 5,
            vertical: 7,
          },
        },
        dataLabels: {
          formatter: (val) => {
            return `${val}`;
          },
        },
        // labels: {
        //     show: false,
        //     // rotate: 320,
        //     // rotateAlways: true,
        //   },
      }}
    />
  );
}
