import React, { useState } from "react";
// import { Row, Table } from "react-bootstrap";
import styled from "styled-components";
import Map1 from "./map1";
import EquipeChart from "./EquipeChart";
import GroupeChart from "./GroupeChart";
import StatusChart from "./StatusChart";
import Cards from "./Cards";

const Interventions = () => {
  const btnPrimary = {
    backgroundColor: "#18B6C0",
    color: "white",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "5px 20px",
    margin: "5px",
  };
  const btnOutlinePrimary = {
    color: "#18B6C0",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #18B6C0",
    width: "100%",
    padding: "5px 20px",
    margin: "5px",
  };
  return (
    <Content>
      <div className="content__buttons">
        <div style={{ ...btnPrimary }}>
          <div>Interventions</div>
        </div>
        <div style={{ ...btnOutlinePrimary }}>
          <div>Alerts</div>
        </div>
        <div style={{ ...btnOutlinePrimary }}>
          <div>SMS</div>
        </div>
      </div>
      <FirstRow>
        <Map1></Map1>
        <EquipeChart />
      </FirstRow>
      <SecondRow>
        <Cards />
        <GroupeChart />
        <StatusChart />
      </SecondRow>
    </Content>
  );
};
export default Interventions;

const Content = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  .content__buttons {
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 130px);
    @media screen and (max-width: 540px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const FirstRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  @media screen and (max-width: 930px) {
    grid-template-columns: 1fr;
  }
`;

const SecondRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 20px;
  @media screen and (max-width: 830px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 1200px) and (min-width: 831px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
