import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function GroupeChart({ width }: any) {
  return (
    <Groupe>
      <div className="container__head">
        <p className="chartTitle">Interventions by Group</p>
      </div>
      <Chart
        type="bar"
        height="430px"
        series={[
          {
            name: "Completed",
            data: [1, 0, 7, 0],
          },
          {
            name: "Scheduled",
            data: [3, 4, 0, 2],
          },
          {
            name: "in Progress",
            data: [0, 0, 3, 0],
          },
          {
            name: "Late",
            data: [3, 1, 0, 0],
          },
        ]}
        options={{
          dataLabels: { enabled: false },
          colors: ["#86DFE4", "#18B6C0", "#5090AC", "#426579"],
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [
              "Green Space",
              "Traffic Management",
              "Smart Lighting",
              "Billboard",
            ],
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            tooltip: {
              enabled: true,
            },
            labels: {
              align: "left",
            },
          },
          legend: {
            horizontalAlign: "center",
            markers: {
              radius: 20,
            },
          },
          grid: {
            show: false,
          },
        }}
      />
    </Groupe>
  );
}

const Groupe = styled.div`
  background-color: #fff;
  /* max-width: 900px;
  min-width: 30%; */

  .container__head {
    display: flex;
    align-items: center;
    padding: 1rem;
    p {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 0;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
