import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Map2 from "../../components/maps/map2";
import MapEclairage from "../../components/maps/mapEclairage";
const Content = styled.div`
  background: white;
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .map {
    width: 100%;
  }
  .iconsTitle {
    display: flex;
  }
  .bntTous {
    width: 100px;
    height: 35px;

    background: #18b6c0;
    color: white;
    text-align: center;
    border-radius: 5px;
  }
  .content__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .content__header_date {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 0 1rem;
    }
    @media screen and (max-width: 440px) {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  .content__map {
    width: 100%;
    height: 660px;
  }
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
`;
function Eclairage() {
  return (
    <Content>
      <div className="content__header">
        <div className="content__header_title">
          <div className="iconsTitle">
            <img src="/icons/eclair.svg" alt="" />
            <span className="pl-2">Lighting</span>
          </div>
        </div>
        <div className="content__header_date">
          {Date().toString().split("G")[0]}
        </div>
      </div>
      <ItemsStyle>
        <div>
          <div>
            <input
              type="search"
              className="form-control 
                     flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="button-addon2"
            />
          </div>
        </div>
        <div>
          <select
            className="form-select appearance-none
                block
                w-full
                text-base
                font-normal
                // text-gray-700
                bg-white bg-clip-padding bg-no-repeat
                border border-solid border-cyan-300
                roundeds
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-label="Default select example"
            style={{ color: "#18B6C0", borderBlockColor: "#18B6C0" }}
          >
            <option selected>Select by municipality</option>
            <option value="1">Municipality 1</option>
            <option value="2">Municipality 2</option>
            <option value="3">Municipality 3</option>
          </select>
        </div>
        <div>
          <select
            className="form-select appearance-none
                block
                w-full
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding bg-no-repeat
                border border-solid border-blue-500
                rounded
                transition
                ease-in-out
                text-red-400
                m-0
                focus:text-gray-700 focus:bg-white focus:border-red-600 focus:outline-none"
            aria-label="Default select example"
            style={{ color: "#18B6C0" }}
          >
            <option selected>Select by sector</option>
            <option value="1">sector 1</option>
            <option value="2">sector 2</option>
            <option value="3">sector 3</option>
          </select>
        </div>
      </ItemsStyle>
      <div className="content__map">
        <MapEclairage />
      </div>
    </Content>
  );
}

const ItemsStyle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: 1rem;
  @media screen and (max-width: 530px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 860px) and (min-width: 531px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default Eclairage;
