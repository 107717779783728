import styled from "styled-components";
import Baroccupation from "../charts/barocuupation";
import Pierepartition from "../charts/Pierepartition";
import Select from "./Tools/Select";
import Map from "./Tools/Map";

const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  padding: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  p {
    margin-bottom: 0;
  }
`;
const Twocards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
  }
  .stationnement__map {
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
  }
  .rightcards {
    width: 100%;
    @media screen and (max-width: 800px) {
      width: 100%;
      /* flex-direction: column; */
    }
    display: grid;
    gap: 1rem;
    grid-auto-columns: 1fr;
    .item-1 {
      grid-column: 1 / 4;
      grid-row: 1 / 2;
      @media screen and (max-width: 450px) {
        grid-column: 1;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-column: 1 / 2;
      }
    }
    .item-2 {
      grid-row: 1 / 2;
      grid-column: 4 / 7;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 2 / 3;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-column: 2 / 3;
      }
    }
    .item-3 {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 3 / 4;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
      }
    }
    .item-4 {
      grid-row: 2 / 3;
      grid-column: 3 / 5;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 4 / 5;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
      }
    }
    .item-5 {
      grid-row: 2 / 3;
      grid-column: 5 / 7;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 5 / 6;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
      }
    }
    .item-6 {
      grid-row: 3 / 4;
      grid-column: 1 / 4;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 6 / 7;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
      }
    }
    .item-7 {
      grid-row: 3 / 4;
      grid-column: 4 / 7;
      @media screen and (max-width: 450px) {
        grid-column: 1;
        grid-row: 7 / 8;
      }
      @media screen and (max-width: 700px) and (min-width: 451px) {
        grid-row: 4 / 5;
        grid-column: 1 / 2;
      }
    }
    .recette {
      @media screen and (max-width: 800px) {
        width: 100%;
        gap: 10px;
      }
      height: 120px;
      background: #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-top: 20px;
      padding-right: 20px;
    }

    .ticket {
      @media screen and (max-width: 800px) {
        width: 100%;
      }
      height: 130px;
      background: #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-top: 10px;
      padding-right: 20px;
    }
  }
`;
const Threecards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
  }
  .min__width {
    height: 100%;
    @media screen and (max-width: 560px) {
      min-width: 550px;
    }
  }
  .card2 {
    min-height: 350px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 1rem;
    overflow-x: auto;
    .exporter {
      color: #18b6c0;
      font-weight: 600;
      display: flex;
      gap: 0.4rem;
      align-items: center;
    }

    .container__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 18px;
      }
      margin-bottom: 0.2rem;
      height: 20%;
      .chartTitle {
        display: flex;
        align-items: center;
      }
      .title_select {
        display: flex;
        justify-content: space-between;
        width: 85%;
      }
    }

    .select_Button {
      display: flex;
      gap: 0.8rem;
    }

    @media screen and (max-width: 560px) {
      .select_Button {
        flex-wrap: wrap;
        gap: 0;
      }
      .exporter {
        font-size: 0;
      }
      .title_select {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 1380px) {
      .title_select {
        flex-direction: column;
      }
    }
  }

  .card3 {
    min-height: 350px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 1rem;
    overflow-x: auto;

    .exporter {
      color: #18b6c0;
      font-weight: 600;
      display: flex;
      gap: 0.4rem;
      align-items: center;
    }

    .container__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 18px;
      }
      margin-bottom: 0.2rem;
      height: 20%;
    }
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
`;
const Stationnement = () => {
  return (
    <Content>
      <TitleStyle>
        <img src="/Social/Logo.svg" alt="" />
        <p>Parking</p>
      </TitleStyle>
      <Twocards>
        <div className="stationnement__map">
          <Map />
        </div>
        <div className="rightcards">
          <div className="recette item-1">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/verifie.svg" alt="" />
              <span style={{ fontWeight: "900" }}>37,2 M€</span>
            </div>
            <span>Receips</span>
          </div>
          <div className="recette item-2">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/verifieorange.svg" alt="" />
              <span style={{ fontWeight: "900" }}>36,5 M€</span>
            </div>
            <span>Expenses</span>
          </div>
          <div className="ticket item-3">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/infovert.svg" alt="" />
              <span style={{ color: "#18B6C0" }}>Tickets</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Since 24h ago</span>
              <span style={{ fontWeight: "900" }}>221</span>
            </div>
          </div>
          <div className="ticket item-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/infovert.svg" alt="" />
              <span style={{ color: "#18B6C0" }}>Voyages</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>17/01/2024 12:45</span>
              <span style={{ fontWeight: "900" }}>85</span>
            </div>
          </div>
          <div className="ticket item-5">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/infovert.svg" alt="" />
              <span style={{ color: "#18B6C0" }}>Durée moy.</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Minutes</span>
              <span style={{ fontWeight: "900" }}>296</span>
            </div>
          </div>
          <div className="recette item-6">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/cumul.svg" alt="" />
              <span style={{ fontWeight: "900" }}>1h</span>
            </div>
            <span>monthly cumulative delays</span>
          </div>
          <div className="recette item-7">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src="/icons/totalvoy.svg" alt="" />
              <span style={{ fontWeight: "900" }}>20</span>
            </div>
            <span>total passengers</span>
          </div>
        </div>
      </Twocards>
      <Threecards>
        <div className="card2">
          <div className="min__width">
            <div className="container__head">
              <div className="title_select">
                <p className="chartTitle">Occupancy rate</p>
                <div className="select_Button">
                  <Select
                    forTag="yearSelected"
                    name="year"
                    id="yearSelected"
                    value="Select a date"
                  />
                  <Select
                    forTag="daySelected"
                    name="day"
                    id="daySelected"
                    value="Day"
                  />
                </div>
              </div>
              <button className="exporter">
                More
                <img src="./Evenement/RightArrow.svg" alt="" />
              </button>
            </div>
            <Baroccupation />
          </div>
        </div>
        <div className="card3">
          <div className="min__width">
            <div className="container__head">
              <p className="chartTitle">
                The number of vehicles per parking duration
              </p>
              <button className="exporter">
                More
                <img src="./Evenement/RightArrow.svg" alt="" />
              </button>
            </div>
            <Pierepartition />
          </div>
        </div>
      </Threecards>
    </Content>
  );
};

export default Stationnement;

const TitleStyle = styled.div`
  display: flex;
  gap: 1rem;
  p {
    color: #3f487e;
    font-weight: 600;
  }
`;
