import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import styled from "styled-components";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  Marker,
  Popup,
  useMapEvents,
  Polyline,
} from "react-leaflet";

const MapContainers = () => {
  const mapStyles = {
    height: "700px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 48.6653091,
    lng: 2.2687767,
  };
  const position = { lat: 30.428480398828107, lng: -9.597313996784024 };

  return (
    <MapStyle>
      <p className="h5">Carte</p>
      <MapContainer
        style={{
          width: "100%",
          minHeight: "650px",
          height: "100%",
          border: "1px solid #18B6C0",
          zIndex: 1,
        }}
        center={position}
        zoom={10}
        scrollWheelZoom={true}
      >
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
      </MapContainer>
    </MapStyle>
  );
};

const MapStyle = styled.div``;
export default MapContainers;
