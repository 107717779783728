import React from "react";
import styled from "styled-components";
import BarChart from "../charts/barchar";
import Card4global from "../charts/card4global";
import DonutChart from "../charts/donutchart";
import Donutponc from "../charts/donutponc";
import LineChart from "../charts/Linechart";
import VerticlBar from "../charts/Verticalvar";
import Carte from "./Carte";
import Select from "./Select";

export default function Ponctualite() {
  return (
    <MyDiv>
      <div className="partone">
        <div className="partone__map">
          <Carte />
        </div>
        <div className="chart">
          <div className="container__head">
            <p className="chartTitle">Occupancy Rate</p>
            <div className="select_Button">
              <Select
                forTag="ligneSelected"
                name="ligne"
                id="ligneSelected"
                value="Choisir la ligne"
              />
            </div>
          </div>
          <Donutponc />
        </div>
      </div>
      <div className="parttwo">
        <div className="linechart">
          <Card4global />
        </div>
        <div className="barchart">
          <VerticlBar />
        </div>
      </div>
    </MyDiv>
  );
}

const MyDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  .partone {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 1.5rem;
    .partone__map {
      padding: 1rem;
      grid-column: 1/4;
      background-color: #fff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      @media screen and (max-width: 1050px) {
        grid-column: 1;
        grid-row: 1/2;
      }
    }
    .chart {
      padding: 1rem;
      grid-column: 4/6;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      height: 420px;
      @media screen and (max-width: 1050px) {
        grid-column: 1;
        grid-row: 2/3;
      }
      .container__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 18px;
        }
        .chartTitle {
          display: flex;
          align-items: center;
        }
        .title_select {
          display: flex;
          justify-content: space-between;
          width: 85%;
        }
      }
    }
  }
  .parttwo {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 1.5rem;
    .linechart {
      min-height: 390px;
      padding: 1rem;
      grid-column: 1/3;
      background: #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      @media screen and (max-width: 1050px) {
        grid-column: 1;
        grid-row: 1/2;
      }
      overflow-x: auto;
    }
    .barchart {
      min-height: 390px;
      padding: 1rem;
      grid-column: 3/6;
      background: #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      @media screen and (max-width: 1050px) {
        grid-column: 1;
        grid-row: 2/3;
      }
      overflow-x: auto;
    }
  }
`;
