import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Transportpublicchart() {
  return (
    <div>
      <Chart
        type="line"
        width="100%"
        height="150px"
        series={[
          {
            name: "Punctual",
            data: [19, 22, 20, 26, 333, 777, 1, 222],
          },
          {
            name: "No Punctual",
            data: [103, 105, 98, 83, 30, 900, 400],
          },
        ]}
        options={{
          xaxis: {
            categories: [10, 12, 14, 16, 18, 20, 22, 24],
            title: {
              text: "Day",
              style: {
                color: undefined,
              },
            },
          },
          chart: {
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          colors: ["#18B6C0", "#FF5C01"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          //   xaxis: {
          //     tickAmount:8,
          //     min: 10,
          //     max: 24,
          //   }
          // ,
          yaxis: {
            tickAmount: 2,
            min: 0,
            max: 1000,
          },
        }}
      />
    </div>
  );
}
