import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const columnChartOptions = {
  chart: {
    type: "column",
    height: "250",
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  colors: ["#FB6B9F", "#00BBC4", "#36708E"],
  xAxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: "",
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f} kg</b></td></tr>',
    footerFormat: "</table>",
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  series: [
    {
      name: "Mixte",
      data: [49.9, 71.5, 106.4, 129.2, 144.0],
    },
    {
      name: "Verts",
      data: [83.6, 78.8, 98.5, 93.4, 106.0],
    },
    {
      name: "Organiques",
      data: [48.9, 38.8, 39.3, 41.4, 47.0],
    },
  ],
};

export function RamassageChart() {
  return (
    <div className="card card-body" style={{ minWidth: "600px" }}>
      {/* <strong className="h5">Statistique de ramassage par période</strong> */}
      <strong className="h5">Pickup statistics by period</strong>
      <HighchartsReact highcharts={Highcharts} options={columnChartOptions} />
    </div>
  );
}
