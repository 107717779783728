import React from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";

export default function TypeChart() {
  return (
    <Type>
      <div className="container__head">
        <p className="chartTitle">Type</p>
      </div>
      <Chart
        type="pie"
        height="350px"
        series={[78, 12, 10]}
        options={{
          labels: ["Rental", "Provision", "Concession"],
          colors: ["#42728D", "#3CD1DB", "#FBACC8"],
          legend: {
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
        }}
      />
    </Type>
  );
}

const Type = styled.div`
  min-width: 22%;
  background-color: #fff;
  @media screen and (max-width: 630px) {
    width: 100%;
  }
  @media screen and (max-width: 1470px) and (min-width: 631px) {
    width: 48%;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
