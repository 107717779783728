import React from "react";
import styled from "styled-components";

export default function NombresCard() {
  return (
    <NombreCard>
      <div className="container__head">
        <p>Numbers</p>
      </div>
      <div className="content">
        <div className="vacataire">
          <img src="/Evenement/Vacataires.svg" alt="" />
          <div className="details">
            <p className="number">107</p>
            <p className="title">stand-in</p>
          </div>
        </div>
        <hr />
        <div className="etudiant">
          <img src="/Evenement/Etudiant.svg" alt="" />
          <div className="details">
            <p className="number">1292</p>
            <p className="title">Students</p>
          </div>
        </div>
      </div>
    </NombreCard>
  );
}

const NombreCard = styled.div`
  background-color: #fff;
  grid-column: 3/4;
  grid-row: 1 / 2;
  @media screen and (max-width: 730px) {
    grid-column: 1;
    grid-row: 2 / 3;
  }
  @media screen and (max-width: 1200px) and (min-width: 731px) {
    grid-column: 1/2;
    grid-row: 2 / 3;
  }
  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 2rem;
  }
  min-width: 25%;
  padding: 1.3rem 2rem;
  .content .details {
    p {
      margin-bottom: 0;
    }
    .number {
      font-weight: 800;
      font-size: 16px;
    }
    .title {
      font-weight: 400;
    }
  }

  .content .vacataire,
  .etudiant {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  border-radius: 4.5px;
  box-shadow: 0px 3.31035px 4px rgba(0, 0, 0, 0.14);
  min-height: 340px;
  @media screen and (max-width: 1024px) {
    .vacataire img,
    .etudiant img {
      width: 30%;
      max-width: 80px;
    }
    .content .details {
      .number {
        font-weight: 800;
        font-size: 28px;
      }
      .title {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  @media screen and (max-width: 1850px) and (min-width: 1151px) {
    min-height: 290px;
    .content .details {
      .number {
        font-weight: 800;
        font-size: 28px;
      }
      .title {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
`;
