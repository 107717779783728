import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import styled from "styled-components";

export function BlackPointPriseChargeChart() {
  const series = [44, 55, 41, 17];
  const options: ApexOptions = {
    chart: {
      type: "donut",
    },
    colors: ["#FA0082", "#18B6C0", "#FFCB93", "#0C7FDA"],
    labels: ["", "", "", ""],
    responsive: [
      {
        breakpoint: 490,
        options: {
          chart: {
            // width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <ChartStyle>
      <div className="card__header">
        <div>
          <p className="h5 font-weight-bold __title">
            {/* Point noir par prise en charge */}
            Blackspot by intervention
          </p>
        </div>
        <div className="col__select">
          <select name="" id="" className="bg-transparent card__select">
            {/* <option value="">Choisir date</option> */}
            <option value="">Select date</option>
            {/* <option value="">Aujourd'hui</option> */}
            <option value="">Today</option>
            {/* <option value="">Semaine dernière</option> */}
            <option value="">Last week</option>
            {/* <option value="">Mois dernier</option> */}
            <option value="">Last month</option>
          </select>
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={"80%"}
      />
    </ChartStyle>
  );
}

const ChartStyle = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
