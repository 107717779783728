import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const columnChartOptions = {
  chart: {
    type: "column",
    height: "250",
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  colors: ["#FB6B9F", "#00BBC4", "#36708E", "yellow"],
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    type: "category",
    // categories: ["Mixte", "Verts", "Organiques", "Plastiques"],
    categories: ["Mixed", "Greens", "Organics", "Plastics"],
  },
  yAxis: {
    title: {
      text: "",
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: "{point.y:.1f}Kg",
      },
    },
  },

  tooltip: {
    //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat:
      '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b>kg<br/>',
  },

  series: [
    {
      name: "",
      colorByPoint: true,
      data: [
        {
          // name: "Mixte",
          name: "Mixed",
          y: 62.74,
          // drilldown: "Mixte",
          drilldown: "Mixed",
        },
        {
          // name: "Verts",
          name: "Greens",
          y: 10.57,
          // drilldown: "Verts",
          drilldown: "Greens",
        },
        {
          // name: "Organiques",
          name: "Organics",
          y: 7.23,
          // drilldown: "Organiques",
          drilldown: "Organics",
        },
        {
          // name: "Plastiques",
          name: "Plastics",
          y: 2.23,
          // drilldown: "Plastiques",
          drilldown: "Plastics",
        },
      ],
    },
  ],
  drilldown: {
    breadcrumbs: {
      position: {
        align: "right",
      },
    },
    series: [
      {
        name: "Mixte",
        id: "Mixte",
        data: [
          ["v65.0", 0.1],
          ["v64.0", 1.3],
          ["v63.0", 53.02],
          ["v62.0", 1.4],
          ["v61.0", 0.88],
          ["v60.0", 0.56],
          ["v59.0", 0.45],
          ["v58.0", 0.49],
          ["v57.0", 0.32],
          ["v56.0", 0.29],
          ["v55.0", 0.79],
          ["v54.0", 0.18],
          ["v51.0", 0.13],
          ["v49.0", 2.16],
          ["v48.0", 0.13],
          ["v47.0", 0.11],
          ["v43.0", 0.17],
          ["v29.0", 0.26],
        ],
      },
      {
        // name: "Verts",
        name: "Greens",
        // id: "Verts",
        id: "Greens",
        data: [
          ["v58.0", 1.02],
          ["v57.0", 7.36],
          ["v56.0", 0.35],
          ["v55.0", 0.11],
          ["v54.0", 0.1],
          ["v52.0", 0.95],
          ["v51.0", 0.15],
          ["v50.0", 0.1],
          ["v48.0", 0.31],
          ["v47.0", 0.12],
        ],
      },
      {
        // name: "Organiques",
        name: "Organics",
        // id: "Organiques",
        id: "Organics",
        data: [
          ["v11.0", 6.2],
          ["v10.0", 0.29],
          ["v9.0", 0.27],
          ["v8.0", 0.47],
        ],
      },
      {
        // name: "Plastiques",
        name: "Plastics",
        // id: "Plastiques",
        id: "Plastics",
        data: [
          ["v11.0", 3.39],
          ["v10.1", 0.96],
          ["v10.0", 0.36],
          ["v9.1", 0.54],
          ["v9.0", 0.13],
          ["v5.1", 0.2],
        ],
      },
    ],
  },
};
export function StatistiquesChart() {
  return (
    <div className="card card-body" style={{ minWidth: "600px" }}>
      {/* <strong className="h5">Statistique de ramassage par période</strong> */}
      <strong className="h5">Collection statistics by period</strong>
      <div>
        <p className="p-0 m-0">
          {/* Taux de recyclage <strong>65%</strong> */}
          Recycling rate <strong>65%</strong>
        </p>
        <p>
          Total <strong>70,937 kg</strong>
        </p>
      </div>
      <HighchartsReact highcharts={Highcharts} options={columnChartOptions} />
    </div>
  );
}
