import { red } from "@mui/material/colors";
import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function EtatChart() {
  return (
    <Etat>
      <div className="container__head">
        {/* <p className="chartTitle">Etat de structure</p> */}
        <p className="chartTitle">State of structure</p>
      </div>
      <Chart
        type="bar"
        height={220}
        width="100%"
        series={[
          {
            data: [195, 95, 125, 150],
          },
        ]}
        options={{
          plotOptions: {
            bar: {
              columnWidth: "50%",
            },
          },
          dataLabels: { enabled: false },
          colors: ["#18B6C0"],
          chart: {
            toolbar: {
              show: false,
            },
            background: "#fff",
          },
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [
              "Voie structurante",
              "Voie de désserte",
              "Rue",
              "Chemin rural",
            ],
            labels: {
              hideOverlappingLabels: false,
              rotate: 0,
              style: {
                fontSize: "10px",
              },
            },
          },
          yaxis: {
            labels: {
              formatter: (value, index) => {
                const type = ["", "médiocre", "Dégradé", "Moyen", "Bon"];
                return type[index];
              },
              offsetX: 10,
            },
          },
          legend: {
            show: false,
          },
        }}
      />
    </Etat>
  );
}

const Etat = styled.div`
  /* width: 500px; */
  width: 100%;
  /* min-width: 600px; */
  min-height: 45%;
  background-color: #fff;
  padding: 1rem;
  @media screen and (max-width: 600px) {
    min-width: 550px;
  }
  .exporter {
    color: #18b6c0;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    .container__head {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
`;
