import React from 'react';

import Chart from "react-apexcharts";
import styled from 'styled-components';

export default function DonutPonc() {
    return  <Chart
            type="donut"
            width='100%'
            height='75%'
            series={[44,10]}
            options={{
                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: undefined,
                                    offsetY: -1,
                                    formatter: function (val) {
                                      return val
                                    }
                                  },
                                  value: {
                                    show: true,
                                    fontSize: '8px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    color: undefined,
                                    offsetY: -6,
                                    formatter: function (val) {
                                      return val
                                    }
                                  },
                                  total: {
                                    show: true,
                                    showAlways: true,
                                    label: '80 %',
                                    fontSize: '17px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 900,
                                    color: '#373d3f',
                                    formatter: function (val) {
                                        return ''
                                      }
                                  },
                              }
                        }
                    }
                },
                colors: ["#18B6C0","#D8F3FF","#18B6C0"],
                dataLabels: {
                    enabled: false
                },
                legend:{
                    show: false,
                }
            }
        }

        />
}

