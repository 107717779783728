import React, { useState } from "react";
import styled from "styled-components";
import Card from "./Card";

export default function Cards() {
  const [arr, setArr] = useState([
    {
      title: "Communes",
      number: 11,
    },
    {
      // title: "Panneaux de signalisation",
      title: "Road signs",
      number: 167,
    },
    {
      // title: "Ouvrages",
      title: "Constructions",
      number: 160,
    },
    {
      // title: "Km de voirie",
      title: "Km of road",
      number: 44,
    },
  ]);
  return (
    <Container>
      <div className="container__head">
        {/* <p className="chartTitle">Liste des interventions</p> */}
        <p className="chartTitle">Intervention list</p>
      </div>
      <CardsStyle>
        {arr.map((element) => (
          <Card title={element.title} number={element.number} />
        ))}
      </CardsStyle>
    </Container>
  );
}

const CardsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70%;
  padding: 12px;
  @media screen and (max-width: 710px) {
    flex-wrap: wrap;
  }
  gap: 1rem;
`;

const Container = styled.div`
  background-color: #fff;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 23%;

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30%;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    @media screen and (max-width: 425px) {
      p {
        font-size: 15px;
      }
    }
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
