import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Statistique() {
  return (
    <StatistiqueStyle>
      <div className="container__head">
        <p className="chartTitle">Statistic</p>
      </div>
      <Tableau>
        <table>
          <tr className="tableHeader">
            <th>receipts</th>
            <th>Expenses</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
        </table>
      </Tableau>
    </StatistiqueStyle>
  );
}

const StatistiqueStyle = styled.div`
  min-width: 45%;
  background-color: #fff;
  @media screen and (max-width: 1470px) {
    width: 100%;
    min-height: 350px;
  }

  .container__head {
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  table {
    border-spacing: 20px;
    border-collapse: separate;
    width: 80%;
    th {
      background-color: #f6f6f6;
      text-align: center;
    }
    tr{
      background-color: #f6f6f6;
      text-align: center;
      height: 26px;
    }
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  @media screen and (max-width: 630px) {
    justify-content: center;
    table {
      width: 100%;
      th {
        width: 45%;
      }
    }
  }
`;

const Tableau = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media screen and (max-width: 630px) {
    justify-content: center;
  }
`;
