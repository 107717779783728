import React from 'react'

import { MapContainer, TileLayer, Rectangle, LayersControl, Marker, LayerGroup, Popup, FeatureGroup } from 'react-leaflet'
function Map2(props: any) {
    const L = require('leaflet');
    const Icon1 = L.icon({
        iconUrl: '/streat/icon1.svg',
        iconSize: [40, 40],
        iconAnchor: [32, 64],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    const Icon2 = L.icon({
        iconUrl: '/streat/icon2.svg',
        iconSize: [40, 40],
        iconAnchor: [32, 64],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    const Icon3 = L.icon({
        iconUrl: '/streat/icon3.svg',
        iconSize: [40, 40],
        iconAnchor: [32, 64],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    const Icon4 = L.icon({
        iconUrl: '/streat/icon4.svg',
        iconSize: [40, 40],
        iconAnchor: [32, 64],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    const Icon5 = L.icon({
        iconUrl: '/streat/icon5.svg',
        iconSize: [10, 10],
        iconAnchor: [32, 64],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    const Icon6 = L.icon({
        iconUrl: '/streat/icon6.svg',
        iconSize: [32, 32],
        iconAnchor: [32, 64],
        popupAnchor: null,
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    const fackLocalisation = [
        { position: { lat: 30.4239008, lng: -9.5911701 }, name: Icon1 },
        { position: { lat: 30.4203091, lng: -9.5997767 }, name: Icon2 },
        { position: { lat: 30.4293091, lng: -9.5957767 }, name: Icon3 },
        { position: { lat: 30.4203091, lng: -9.5907767 }, name: Icon4 },
        { position: { lat: 30.4213091, lng: -9.5967767 }, name: Icon5 },
        { position: { lat: 30.4239008, lng: -9.5911701 }, name: Icon1 },
        { position: { lat: 30.4203091, lng: -9.5997767 }, name: Icon2 },
        { position: { lat: 30.4293091, lng: -9.5957767 }, name: Icon3 },
        { position: { lat: 30.4203091, lng: -9.5907767 }, name: Icon4 },
        { position: { lat: 30.4213091, lng: -9.5967767 }, name: Icon5 },
        { position: { lat: 30.4239008, lng: -9.5981701 }, name: Icon1 },
        { position: { lat: 30.4203091, lng: -9.5997767 }, name: Icon2 },
        { position: { lat: 30.4203091, lng: -9.5907767 }, name: Icon3 },
        { position: { lat: 30.4203091, lng: -9.5907767 }, name: Icon4 },
        { position: { lat: 30.4213091, lng: -9.5967767 }, name: Icon5 },
        { position: { lat: 30.4239008, lng: -9.5911701 }, name: Icon1 },
        { position: { lat: 30.4293091, lng: -9.5957767 }, name: Icon3 },
        { position: { lat: 30.4203091, lng: -9.5907767 }, name: Icon4 },
        { position: { lat: 30.4213091, lng: -9.5967767 }, name: Icon5 },
        { position: { lat: 30.4263091, lng: -9.5907767 }, name: Icon2 },
        { position: { lat: 30.4203091, lng: -9.5997767 }, name: Icon3 },
        { position: { lat: 30.4203091, lng: -9.5957767 }, name: Icon5 },
        { position: { lat: 30.4263091, lng: -9.5907767 }, name: Icon2 },
        { position: { lat: 30.4203091, lng: -9.5997767 }, name: Icon5 },
        { position: { lat: 30.4203091, lng: -9.5957767 }, name: Icon1 },
        { position: { lat: 30.4203091, lng: -9.5907767 }, name: Icon2 },
        { position: { lat: 30.4203091, lng: -9.5907767 }, name: Icon3 },
        { position: { lat: 30.4253091, lng: -9.5907767 }, name: Icon4 },
        { position: { lat: 30.4203091, lng: -9.5907767 }, name: Icon5 },

    ]
    // 48.6653091,2.2687767
    const position = { lat: 30.428480398828107, lng:  -9.597313996784024};
    return (
        <MapContainer
            style={{ width: "100%", height: "100%", border: "1px solid #18B6C0", zIndex: 1 }}
            center={position} zoom={15} scrollWheelZoom={true}>
            <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
            {fackLocalisation.map((localisation, index) => {
                return (

                    <Marker position={localisation.position} key={index} icon={localisation.name}>
                        <Popup>
                            {"lat" + localisation.position.lat + "lng" + localisation.position.lng}
                        </Popup>
                    </Marker>
                )

            })}
            <LayersControl position="topright">
                <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.BaseLayer>
            </LayersControl>
        </MapContainer>

    )
}

export default Map2