import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import styled from "styled-components";

export function BlackPointStateChart() {
  const series = [44, 55, 41, 17, 15, 75, 47, 63];
  const options: ApexOptions = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
      // position: "bottom",
    },
    colors: [
      "#F884D0",
      "#82BCF3",
      "#FCD95D",
      "#5FEAA7",
      "#0C7FDA",
      "#FFCB93",
      "#DBAEFF",
      "#6177FF",
    ],
    labels: [
      "Dilapidated",
      "Degraded",
      "Clogged",
      "Under construction",
      "Low slope",
      "No connection",
      "Broken",
      "Undersized",
    ],
    responsive: [
      {
        // breakpoint: 480,
        options: {
          chart: {
            // width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <ChartStyle>
      <div className="card__header">
        <div>
          {/* <p className="h5 font-weight-bold __title">Point noir par etat</p> */}
          Blackspot by state
        </div>
        <div className="col__select">
          <select
            name=""
            id=""
            className="bg-transparent card__select"
            style={{ width: "120px" }}
          >
            {/* <option value="">Choisir date</option> */}
            <option value="">Select date</option>
            {/* <option value="">Aujourd'hui</option> */}
            <option value="">Today</option>
            {/* <option value="">Semaine dernière</option> */}
            <option value="">Last week</option>
            {/* <option value="">Mois dernier</option> */}
            <option value="">Last month</option>
          </select>
        </div>
      </div>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={"85%"}
      />
    </ChartStyle>
  );
}

const ChartStyle = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;

  gap: 0.5rem;
`;
