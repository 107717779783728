import React, { useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Dropdown,
  Row,
  Col,
  Toast,
} from "react-bootstrap";
import styled from "styled-components";
import DropDown from "../dropdown/Dropdown";
const Mynav = styled.div`
  background: white;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  .bx-menu {
    cursor: pointer;
    padding: 17px;
    font-size: 35px;
  }
  .user-div {
    display: flex;
    font-size: 35px;
    .profile {
      display: flex;
      /* padding-right: 27px; */
      padding-left: 4px;
      .username {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    .bx-user {
      cursor: pointer;
      font-size: 35px;
    }
    .bx-menu {
      cursor: pointer;
      padding: 15px;
      font-size: 35px;
      padding-left: 2px;
    }
    .icon {
      display: flex;
      flex-direction: column;
      align-items: end;
      .bx-menu {
        cursor: pointer;
        padding: 17px;
        font-size: 35px;
        padding-left: 2px;
        padding: 9px;
        margin-top: 7px;
      }
      .toast {
        /* width: 100px; */
        @media only screen and (max-width: 500px) {
          width: 90px;
        }
        @media only screen and (max-width: 829px) and (min-width: 500px) {
          width: 200px;
        }
      }
    }
    .bxs-user-circle {
      cursor: pointer;
      padding: 12px;
      font-size: 35px;
      padding-left: 0px;
      margin-right: -24px;
    }
    #dropdown-basic {
      background: transparent;
      font-size: 10px;
      color: black;
      border: none;
    }
    .drop-menu {
      padding: 0;
      border: none;
      background: none;
    }
    .username {
      display: flex;
    }
    .bx-chevron-down {
      cursor: pointer;
      font-size: 22px;
      margin-top: 17px;
      margin-left: 3px;
    }
  }
  .Dropdown {
    background-color: aqua;
  }
  .text-dark {
    .a {
      color: aqua;
    }
  }
`;
const HeaderCom = ({ ...props }) => {
  const [clickmenu, setClickmenu] = useState(false);
  const Sendclick = (test: boolean) => {
    props.checksidebar(test);
  };
  const [clicknotif, setClicknotif] = useState(false);
  const [clickProfile, setClickprofile] = useState(false);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);

  const toggleShowA = () => setShowA(!showA);
  const toggleShowB = () => setShowB(!showB);
  const [show, setShow] = useState(false);

  return (
    <div>
      <Mynav>
        <img
          src="/menu.svg"
          className="bx-menu"
          alt=""
          onClick={() => Sendclick(!props.sidebaricon)}
        />
        <div className="user-div">
          <div className="icon" onClick={() => setClicknotif(!clicknotif)}>
            <div style={{ display: "flex" }}>
              <img
                src="/points.svg"
                className="bx-menu"
                alt=""
                onClick={toggleShowB}
              />
              <img
                src="/notif.svg"
                className="bx-menu"
                alt=""
                onClick={toggleShowA}
              />
            </div>
            <Toast className="toast" show={showA} onClose={toggleShowA}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Notif 1</strong>
                <small>11 mins ago</small>
              </Toast.Header>
              <Toast.Body>Something happen</Toast.Body>
            </Toast>
            <Toast show={showA} onClose={toggleShowA}>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Notif 2</strong>
                <small>20 mins ago</small>
              </Toast.Header>
              <Toast.Body>Something happen</Toast.Body>
            </Toast>
          </div>
          <div className="profile">
            <div>
              <i
                className="bx bxs-user-circle"
                style={{ color: "#1E3367" }}
              ></i>
            </div>
            <Container>
              <Nav>
                <NavDropdown
                  className="text-dark"
                  title="LEAP"
                  style={{
                    fontSize: "21px ",
                    color: "#5b5c5e",
                    marginTop: "7px",
                  }}
                >
                  <NavDropdown.Item href="/">View profile</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/login">Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Container>
          </div>
        </div>
      </Mynav>
    </div>
  );
};
export default HeaderCom;
