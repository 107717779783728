import React from 'react'
import Chart from 'react-apexcharts'
import styled from 'styled-components'

export default function EtatChart() {
  return (
    <Etat>
    <div className="container__head">
      <p className="chartTitle">State</p>
    </div>
    <Chart
      type="pie"
      height="350px"
      series={[15, 30, 55]}
      options={{
        labels: ["Good", "Average", "Bad"],
        colors: ["#3CD1DB",  "#FBACC8", "#42728D"],
        legend: {
          position: "bottom",
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
      }}
    />
  </Etat>
);
}

const Etat = styled.div`
min-width: 22%;
background-color: #fff;
@media screen and (max-width: 630px) {
  width: 100%;
}
@media screen and (max-width: 1470px) and (min-width: 631px) {
    width: 48%;
  }

.container__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  p {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 18px;
  }
  margin-bottom: 1rem;
}
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
border-radius: 8px;
`;
