import React from "react";
import styled from "styled-components";
import "./AirQuality.css";
import { PressionChart } from "./components/PressionChart";
import { TempHumPPMChart } from "./components/TempHumPpmChart";
export function AirQuality() {
  return (
    <ContentStyle>
      <div>
        <h5 className="dark-bleu">
          {/* <i className="fa fa-pie-chart"></i> Qualité de l'air */}
          <i className="fa fa-pie-chart"></i> Air quality
        </h5>
      </div>
      <BadgesStyle>
        <div className="card card-body">
          <div className="d-flex justify-content-between align-items-center">
            <img src="/temp.svg" width={60} height={60}></img>
            <strong
              style={{
                fontSize: "30px",
              }}
            >
              21
            </strong>
            <span> °C</span>
          </div>
          <small className="text-muted mt-2 font-weight-bold">
            {/* Température */}
            Temperature
          </small>
        </div>
        {/* <!-- col-2--> */}

        <div className="card card-body">
          <div className="d-flex justify-content-between align-items-center">
            <img src="/hum.svg" width={60} height={60}></img>
            <strong
              style={{
                fontSize: "30px",
              }}
            >
              45
            </strong>
            <span> %</span>
          </div>
          {/* <small className="text-muted mt-2 font-weight-bold">Humidité</small> */}
          <small className="text-muted mt-2 font-weight-bold">Humidity</small>
        </div>

        <div className="card card-body">
          <div className="d-flex justify-content-between align-items-center">
            <img src="/press.svg" width={60} height={60}></img>
            <strong
              style={{
                fontSize: "30px",
              }}
            >
              80
            </strong>
            <span> mbar</span>
          </div>
          {/* <small className="text-muted mt-2 font-weight-bold">Pression</small> */}
          <small className="text-muted mt-2 font-weight-bold">Pressure</small>
        </div>
        <div className="card card-body">
          <div className="d-flex justify-content-between align-items-center">
            <img src="/co2.svg" width={60} height={60}></img>
            <strong
              style={{
                fontSize: "30px",
              }}
            >
              600
            </strong>
            <span> ppm</span>
          </div>
          <small className="text-muted mt-2 font-weight-bold">CO2</small>
        </div>
        <div className="card card-body">
          <div className="d-flex justify-content-between align-items-center">
            <img src="/airquality.svg" width={60} height={60}></img>
            <strong
              style={{
                fontSize: "30px",
              }}
            >
              {/* Bon */}
              Good
            </strong>
          </div>
          <small className="text-muted mt-2 font-weight-bold">
            {/* Qualité de l'air */}
            Air quality
          </small>
        </div>
      </BadgesStyle>
      <SecondStyle>
        <div className="card card-body qualiteDair">
          {/* <p className="h5 __title">Qualité de l'air</p> */}
          <p className="h5 __title">Air quality</p>
          <img src="quality.svg" alt="" />
        </div>
        <div className="col-6 pression">
          <PressionChart />
        </div>{" "}
        <div className="card card-body" style={{ minWidth: "100px" }}>
          {/* <p className="h5 mb-3 __title">Alarms</p> */}
          <p className="h5 mb-3 __title">Alarms</p>
          <div
            className="text-center text-white  d-flex flex-column py-3"
            style={{
              borderRadius: "5px",
              background:
                "transparent linear-gradient(246deg, #FF617E 0%, #FF3D60 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <span>0</span>
            {/* <strong>Seuil d'alerte</strong> */}
            <strong>Alert threshold</strong>
          </div>

          <div
            className="text-center text-white d-flex flex-column mt-2 py-3"
            style={{
              borderRadius: "5px",
              background:
                "transparent linear-gradient(248deg, #FFC855 0%, #FCB92C 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <span>0</span>
            {/* <strong>Seuil d'information</strong> */}
            <strong>Information threshold</strong>
          </div>

          <div
            className="text-center text-white d-flex flex-column mt-2 py-3"
            style={{
              borderRadius: "5px",
              background:
                "transparent linear-gradient(249deg, #38E4B1 0%, #1CBB8C 100%) 0% 0% no-repeat padding-box",
            }}
          >
            <span>0</span>
            {/* <strong>Valeur guide</strong> */}
            <strong>Guide value</strong>
          </div>
        </div>
      </SecondStyle>{" "}
      {/* <!-- row --> */}
      <div className="temperature">
        <TempHumPPMChart />
      </div>
      {/* <!-- row --> */}
    </ContentStyle>
  );
}

const ContentStyle = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  .temperature {
    width: 100%;
    background-color: white;
    padding: 1rem;
    overflow-x: auto;
  }

  @media screen and (max-width: 570px) {
    .__title {
      font-size: 1rem;
    }
  }
  /* box-sizing: border-box; */
`;

const BadgesStyle = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 780px) and (min-width: 551px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1245px) and (min-width: 781px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const SecondStyle = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  @media screen and (max-width: 890px) {
    display: grid;
    grid-template-columns: 1fr;
    .pression {
      width: 100%;
      overflow-x: auto;
    }
  }
  @media screen and (max-width: 1500px) and (min-width: 891px) {
    .qualiteDair {
      width: 100%;
    }
  }
`;
