import React, { useState } from "react";
import styled from "styled-components";
import Map1 from "./tools/Map";
import EtatChart from "./tools/EtatChart";
import Cards from "./tools/StatistiquesCards";
import Travaux from "./tools/Travaux";

function Voiries() {
  const [data, setData] = useState([
    {
      name: "Barneaux",
      description: "Programmé",
      km: "1.2 km",
      montant: "50 561.00€",
    },
    {
      name: "Antony",
      description: "Réalisé",
      km: "0.9 km",
      montant: "81 981.56€",
    },
  ]);
  return (
    <Content>
      <TitleStyle>
        <img src="/Social/Logo.svg" alt="" />
        {/* <p>Voiries</p> */}
        <p>Roadways</p>
      </TitleStyle>
      <Details>
        <FirstRow>
          <Map1 />
          <div className="min__width" style={{ width: "100%" }}>
            <EtatChart />
          </div>
        </FirstRow>
        <SecondRow>
          <Cards />
          <Travaux data={data} />
        </SecondRow>
      </Details>
    </Content>
  );
}

export default Voiries;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
  p {
    margin-bottom: 0;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  @media screen and (max-width: 1512px) {
    justify-content: space-around;
  }
  min-width: 49%;
  min-height: 900px;
  @media screen and (max-width: 1512px) {
    min-height: unset;
  }
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  min-width: 49%;
  /* width: 780px; */
  @media screen and (max-width: 1512px) {
    max-width: unset;
    align-items: center;
  }
`;

const TitleStyle = styled.div`
  display: flex;
  gap: 1rem;
  p {
    color: #3f487e;
    font-weight: 600;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1512px) {
    flex-direction: column;
    gap: 1rem;
  }
  .min__width {
    @media screen and (max-width: 600px) {
      overflow-x: auto;
    }
  }
`;
