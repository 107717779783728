import React, { useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";

function SelectButton({ htmlFor, name, id, option }: any) {
  return (
    <SelectDiv>
      <label htmlFor={htmlFor}></label>
      <select name={name} id={id}>
        <option value="">{option}</option>
      </select>
    </SelectDiv>
  );
}

export default function FrequentationChart() {
  const [data, setData] = useState([
    {
      htmlFor: "dateSelect",
      name: "date",
      id: "dateSelect",
      option: "Select Date",
    },
    {
      htmlFor: "yearSelect",
      name: "year",
      id: "yearSelect",
      option: "per Year",
    },
  ]);
  return (
    <Frequentation>
      <div className="container__head">
        <p className="chartTitle">
          Frequentation
        </p>
        <div className="SelectTag">
          {data.map((element) => (
            <SelectButton
              htmlFor={element.htmlFor}
              name={element.name}
              id={element.id}
              option={element.option}
            />
          ))}
        </div>
      </div>
      <Chart
        type="bar"
        height={250}
        series={[
          {
            data: [195, 95, 125, 150, 130],
          },
        ]}
        options={{
          dataLabels: { enabled: false },
          colors: ["#FBACC8"],
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
            background: "#fff",
          },
          tooltip: {
            followCursor: true,
          },
          xaxis: {
            categories: [
              "CLIC",
              "Hosting",
              "Residence",
              "Retirement home",
              "Day care center",
              
            ],
            labels: {
              hideOverlappingLabels: false,
              rotate: 0,
              style: {
                fontSize: "10px",
              },
            },
          },
          yaxis: {
            labels: {
              formatter: (value) => value.toFixed(0) + "%",
            },
          },
          legend: {
            show: false,
          },
        }}
      />
    </Frequentation>
  );
}

const Frequentation = styled.div`
  /* width: 500px; */
  width: 100%;
  background-color: #fff;
  min-width: 500px;
  .exporter {
    color: #18b6c0;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
    .SelectTag {
      display: flex;
      width: 70%;
      justify-content: space-between;
    }
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    .container__head {
      .chartTitle, .SelectTag{
        width: 100%;
        margin-top: 0.3rem;
        flex-wrap: wrap;
      }
      justify-content: flex-start;
      flex-direction: column;
    }
  }
`;

const SelectDiv = styled.div`
  select {
    height: 35px;
    background-color: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    text-align: center;
    border: 0px;
    border-radius: 4px;
    @media screen and (max-width: 320px) {
      width: 100px;
      min-width: unset;
      font-size: 14px;
    }
  }
`;
