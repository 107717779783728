import React from "react";
import styled from "styled-components";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  Marker,
  Popup,
  useMapEvents,
  Polyline,
} from "react-leaflet";
import L from "leaflet";

const MapContainers = () => {
  const position = { lat: 30.428480398828107, lng: -9.597313996784024 };

  return (
    <MapStyle>
      <div className="map__header">
        <div className="col-2">
          <p className="h5 p-0 mb-2" style={{ whiteSpace: "nowrap" }}>
            Carte
          </p>
        </div>
        <div className="map__legend">
          <img src="/assainissement.svg" alt="" />
        </div>
      </div>
      <MapContainer
        style={{
          width: "97%",
          height: "100%",
          minHeight: "380px",
          border: "1px solid #18B6C0",
          zIndex: 1,
        }}
        center={position}
        zoom={10}
        scrollWheelZoom={true}
      >
        <Marker
          position={position}
          icon={L.icon({
            iconUrl: "/co2.svg",
            iconSize: [100, 100],
            iconAnchor: [50, 50],
          })}
        />
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
            <TileLayer
              url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
      </MapContainer>
    </MapStyle>
  );
};

const MapStyle = styled.div`
  background-color: white;
  padding: 1rem;
  .map__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
export default MapContainers;
