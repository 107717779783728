import React from "react";
import styled from "styled-components";
import "./Assainissement.css";
import { BlackPointCommunChart } from "./components/BlackPointCommunChart";
import { BlackPointPriseChargeChart } from "./components/BlackPointPriseChargeChart";
import { BlackPointStateChart } from "./components/BlackPointStateChart";
import MapContainer from "./components/Map";

export function Assainissement() {
  return (
    <ContainerStyle>
      <div className="Assainissement__header">
        <h5 className="dark-bleu">
          {/* <i className="fa fa-pie-chart "></i> Assainissement */}
          <i className="fa fa-pie-chart "></i> Sanitation
        </h5>
      </div>
      <div className="Assainissement__map">
        <MapContainer />
      </div>
      <ChartsStyle>
        <div className="chart__heigth">
          <BlackPointCommunChart />
        </div>
        <div className="chart__heigth">
          <BlackPointPriseChargeChart />
        </div>
        <div className="chart__heigth">
          <BlackPointStateChart />
        </div>
      </ChartsStyle>
    </ContainerStyle>
  );
}

const ContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  gap: 1rem;
  .Assainissement__header {
    width: 100%;
  }
  .Assainissement__map {
    width: 100%;
  }
`;

const ChartsStyle = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  .chart__heigth {
    background-color: white;
    height: 335px;
  }
  @media screen and (max-width: 780px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 1200px) and (min-width: 781px) {
    grid-template-columns: 1fr 1fr;
  }
`;
// <div className="col-4">
