import styled from "styled-components";

export const Badge = (props: any) => {
  return (
    <BadgeStyle
      style={{
        backgroundColor: "#FFFF",
      }}
    >
      <div className="my-icon" style={{ minWidth: "100px" }}>
        <img
          className="p-1"
          src={props.src}
          alt=""
          style={{ maxWidth: "60px", marginLeft: "auto", marginRight: "auto" }}
        />
      </div>
      <div
        className="col d-flex flex-column justify-content-center rounded"
        style={{ backgroundColor: "#FFFF" }}
      >
        <div className="row">
          <div className="col font-weight-bold" style={{ fontWeight: "bold" }}>
            {props.value}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span>{props.name}</span>
          </div>
        </div>
      </div>
    </BadgeStyle>
  );
};

const BadgeStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 100px;
  border-radius: 8px;
  filter: drop-shadow(0px 3.31035px 4px rgba(0, 0, 0, 0.14));
  .my-icon {
    height: 100px;
    display: flex;
    border-radius: 5px;
    align-items: center;
  }
  div.my-icon {
    background-color: #42728d;
  }
  div.my-icon:hover {
    background-color: #18b6c0;
  }
`;
