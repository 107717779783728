import React from "react";
import {
  MapContainer,
  TileLayer,
  Rectangle,
  LayersControl,
  Marker,
  LayerGroup,
  Popup,
  FeatureGroup,
} from "react-leaflet";
import styled from "styled-components";

function Map1() {
  const position = { lat: 48.6653091, lng: 2.2687767 };
  return (
    <Map>
      <div className="details">
        <div className="mapTitle">
          <p>Interventions in progress and overdue</p>
        </div>
        <div className="legend legend1">
          <p className="p1">Minor</p>
          <p className="p2">Minor</p>
          <p className="p3">Critical</p>
        </div>
      </div>
      <MapContainer
        style={{
          width: "90%",
          height: "80%",
          border: "1px solid #18B6C0",
          zIndex: 1,
        }}
        center={position}
        zoom={13}
        scrollWheelZoom={true}
      >
        <TileLayer
          url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <Rectangle
          bounds={[
            [51.49, -0.08],
            [51.5, -0.06],
          ]}
          color="green"
        >
          <input type="text" />
        </Rectangle>
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
        </LayersControl>
      </MapContainer>
      <div className="legend legend2">
        <p className="p1">Minor</p>
        <p className="p2">Minor</p>
        <p className="p3">Critical</p>
      </div>
    </Map>
  );
}

export default Map1;

const Map = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  height: 590px;
  .details {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  .mapTitle {
    font-size: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    p {
      font-weight: 700;
      margin: 0;
    }
  }

  .legend {
    display: flex;
    gap: 10%;
    p {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0%;
    }

    p::before {
      margin-right: 5px;
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background-color: green;
    }
    .p2::before {
      background-color: orange;
    }
    .p3::before {
      background-color: red;
    }
  }

  .legend2 {
    display: none;
  }

  @media screen and (max-width: 1440px) {
    .mapTitle p {
      font-size: 18px !important;
    }
    .details {
      p {
        font-size: 15px;
      }
      .legend1 {
        display: none;
      }
      .mapTitle p {
        text-align: center;
        font-size: 14px;
      }
    }
    .legend2 {
      display: flex !important;
      justify-content: space-around;
      /* font-size: 14px; */
      width: 90%;
      @media screen and (max-width: 425px) {
        font-size: 12px;
        p::before {
          height: 10px;
          width: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 470px) {
    .details {
      align-self: flex-start;
      padding-left: 1rem;
      .mapTitle {
        p {
          width: max-content;
          font-size: 15px !important;
          font-weight: 500;
        }
      }
    }
  }

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
