import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

export default function FilmsChart() {
  return (
    <Equipe>
      <div className="min__width">
        <div className="container__head">
          <p className="chartTitle">Films</p>
          <button className="exporter">
            <img src="./Evenement/Warning.svg" alt="" />
          </button>
        </div>
        <Chart
          type="bar"
          // width='90%'
          height={250}
          series={[
            {
              data: [400, 1000, 200, 1600],
            },
          ]}
          options={{
            dataLabels: { enabled: false },
            colors: ["#FBACC8"],
            chart: {
              stacked: true,
              toolbar: {
                show: false,
              },
              background: "#fff",
            },
            tooltip: {
              followCursor: true,
            },
            xaxis: {
              categories: [
                "French films",
                "European films",
                "Non-European films",
                "Total",
              ],
              labels: {
                hideOverlappingLabels: false,
                rotate: 0,
                style: {
                  fontSize: "10px",
                },
              },
            },
            legend: {
              show: false,
            },
          }}
        />
      </div>
    </Equipe>
  );
}

const Equipe = styled.div`
  width: 100%;
  min-height: 380px;
  background-color: #fff;
  padding: 1rem;
  grid-column: 3/4;
  grid-row: 1 / 2;

  .exporter {
    color: #18b6c0;
    font-weight: 600;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }

  .container__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
    }
    margin-bottom: 1rem;
  }
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow-x: auto;
  @media screen and (max-width: 700px) {
    .min__width {
      min-width: 500px;
    }
  }
  @media screen and (max-width: 865px) {
    grid-column: 1;
    grid-row: 3 / 4;
  }
  @media screen and (max-width: 1670px) and (min-width: 866px) {
    grid-column: 1/5;
    grid-row: 2 / 3;
  }
`;
