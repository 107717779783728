import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function Pierepartition() {
  return (
    <Chart
      type="bar"
      height="80%"
      series={[
        {
          data: [90, 40, 50, 60, 75, 35],
        },
      ]}
      options={{
        dataLabels: { enabled: false },
        colors: [
          function ({ value, seriesIndex, w }: any) {
            if (value == 90) return "#18B6C0";
            else if (value == 40) return "#FFDE54";
            else if (value == 50) return "#FF8A01";
            else if (value == 60) return "#FF5C01";
            else if (value == 75) return "#FF5C01";
            else if (value == 35) return "#F42829";
          },
        ],
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          },
          background: "#fff",
        },
        tooltip: {
          followCursor: true,
        },
        xaxis: {
          categories: [
            "Available",
            "< 5min",
            "5min - 15min",
            "15min - 1h",
            "1h - 3h",
            "> 3h",
          ],
        },
        legend: {
          show: false,
        },
      }}
    />
  );
}
