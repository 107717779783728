import React from "react";
import styled from "styled-components";
import Carte from "./Carte";
import PieCharttrafic from "../charts/Piecharttrafic";
import AreaChart from "../charts/Areachart";
import Heatmap from "../charts/heatmapchart";

export default function Trafic() {
  return (
    <Container>
      <HeadStyle>
        <div className="Stationnement">
          {/* <p>Gestion de trafic</p> */}
          <p>Traffic management</p>
        </div>
        <SelectStyle>
          <select
            className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
            aria-label="Default select example"
            style={{ fontSize: "70%", width: "fit-content" }}
          >
            {/* <option selected>Sélectionner une date</option> */}
            <option selected>Select a date</option>
          </select>
          <select
            className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
            aria-label="Default select example"
            style={{ fontSize: "70%", width: "fit-content" }}
          >
            {/* <option selected>Sélectionner une date</option> */}
            <option selected>Select a date</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
          <select
            className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
            aria-label="Default select example"
            style={{ fontSize: "70%", width: "fit-content" }}
          >
            {/* <option selected>Sélectionner une date</option> */}
            <option selected>Select a date</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </SelectStyle>
      </HeadStyle>
      <TopCards>
        <CongestionStyle>
          <span style={{ fontSize: "24px", marginLeft: "12px" }}>23%</span>
          <div className="sevdiv">
            <div className="cardpart1">
              {/* <span>CONGESTION DES ROUTES</span> */}
              <span>Road congestion</span>
              <img src="/icons/info.svg" alt="" />
            </div>
            <hr style={{ color: "#B7B7B7", margin: "0.5rem 0" }} />
            <div className="cardpart2">
              <div style={{ display: "flex" }}>
                <i
                  className="bx bx-up-arrow-alt"
                  style={{ color: "#F42829" }}
                ></i>
                <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                  {/* 5% en dessus de la moyenne */}
                  5% above average
                </span>
              </div>
              {/* <span>Détails </span> */}
              <span>Details </span>
            </div>
          </div>
        </CongestionStyle>
        <CongestionStyle1>
          <span
            style={{ fontSize: "24px", marginLeft: "12px", color: "#F42829" }}
          >
            9
          </span>
          <div className="sevdiv">
            <div className="cardpart1">
              {/* <span style={{ paddingLeft: "15px" }}>ACCIDENTS</span> */}
              <span style={{ paddingLeft: "15px" }}>Accidents</span>
              <img
                src="/icons/info1.svg"
                alt=""
                style={{ paddingRight: "10px" }}
              />
            </div>
            <hr style={{ color: "#B7B7B7", margin: "0.5rem 0" }} />
            <div className="cardpart2">
              <div style={{ display: "flex" }}>
                <i
                  className="bx bx-up-arrow-alt"
                  style={{ color: "#F42829" }}
                ></i>
                <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                  {/* 4% en dessus de la moyenne */}
                  4% above average
                </span>
              </div>
              {/* <span style={{ color: "#18B6C0" }}>Détails </span> */}
              <span style={{ color: "#18B6C0" }}>Details </span>
            </div>
          </div>
        </CongestionStyle1>
        <CongestionStyle1>
          <span
            style={{ fontSize: "24px", marginLeft: "12px", color: "#FF6E01" }}
          >
            2645
          </span>
          <div className="sevdiv">
            <div className="cardpart1">
              <span style={{ paddingLeft: "15px", color: "#FF6E01" }}>
                {/* nombre de véhicules/par heure */}
                number of vehicles/hour
              </span>
              <img
                src="/icons/info1.svg"
                alt=""
                style={{ paddingRight: "10px" }}
              />
            </div>
            <hr style={{ color: "#B7B7B7", margin: "0.5rem 0" }} />
            <div className="cardpart2">
              <div style={{ display: "flex" }}>
                <i
                  className="bx bx-down-arrow-alt"
                  style={{ color: "#18B6C0" }}
                ></i>
                <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                  {/* 2% en dessous de la moyenne */}
                  2% below average
                </span>
              </div>
              {/* <span style={{ color: "#18B6C0" }}>Détails </span> */}
              <span style={{ color: "#18B6C0" }}>Details </span>
            </div>
          </div>
        </CongestionStyle1>
        <CongestionStyle1>
          <span
            style={{ fontSize: "24px", marginLeft: "12px", color: "#18B6C0" }}
          >
            60 km/h
          </span>
          <div className="sevdiv">
            <div className="cardpart1">
              <span style={{ paddingLeft: "15px", color: "#18B6C0" }}>
                {/* vitesse moyenne */}
                average speed
              </span>
              <img
                src="/icons/info1.svg"
                alt=""
                style={{ paddingRight: "10px" }}
              />
            </div>
            <hr style={{ color: "#B7B7B7", margin: "0.5rem 0" }} />
            <div className="cardpart2">
              <div style={{ display: "flex" }}>
                <i
                  className="bx bx-up-arrow-alt"
                  style={{ color: "#F42829" }}
                ></i>
                <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                  {/* 3 min ligne de base */}3 min baseline
                </span>
              </div>
              {/* <span style={{ color: "#18B6C0" }}>Détails </span> */}
              <span style={{ color: "#18B6C0" }}>Details </span>
            </div>
          </div>
        </CongestionStyle1>
        <CongestionStyle1>
          <span
            style={{ fontSize: "24px", marginLeft: "12px", color: "#FA0082" }}
          >
            43
          </span>
          <div className="sevdiv">
            <div className="cardpart1">
              <span style={{ paddingLeft: "15px", color: "#FA0082" }}>
                {/* dépassement de vitesse */}
                exceeding the speed limit
              </span>
              <img
                src="/icons/info1.svg"
                alt=""
                style={{ paddingRight: "10px" }}
              />
            </div>
            <hr style={{ color: "#B7B7B7", margin: "0.5rem 0" }} />
            <div className="cardpart2">
              <div style={{ display: "flex" }}>
                <i
                  className="bx bx-down-arrow-alt"
                  style={{ color: "#18B6C0" }}
                ></i>
                <span style={{ fontSize: "12px", marginLeft: "5px" }}>
                  {/* 1% en dessous de la moyenne */}
                  1% below average
                </span>
              </div>
              {/* <span style={{ color: "#18B6C0" }}>Détails </span> */}
              <span style={{ color: "#18B6C0" }}>Details </span>
            </div>
          </div>
        </CongestionStyle1>
      </TopCards>
      <Sides>
        <LeftSide>
          <LeftTopSides>
            <CongestionRoutesStyle>
              <div className="globaldiv">
                {/* <span className="title">Congestion des routes</span> */}
                <span className="title">Road congestion</span>
                <div className="titleandprog">
                  <div className="titleprog">
                    <span style={{ color: "#161616", fontSize: "15px" }}>
                      {/* Rue de la porte de travaux */}
                      Porte de Trivaux Street
                    </span>
                    <span className="valuechange">
                      <i
                        className="bx bx-up-arrow-alt"
                        style={{ color: "#F42829" }}
                      ></i>
                      14%
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: "55%", backgroundColor: "#FF6E01" }}
                    ></div>
                  </div>
                </div>
                <div className="titleandprog">
                  <div className="titleprog">
                    <span style={{ color: "#161616", fontSize: "15px" }}>
                      {/* Avenue Adrien-Hébrard */}
                      Adrien-Hébrard Avenue
                    </span>
                    <span className="valuechange">
                      <i
                        className="bx bx-up-arrow-alt"
                        style={{ color: "#F42829" }}
                      ></i>
                      10%
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: "45%", backgroundColor: "#FF6E01" }}
                    ></div>
                  </div>
                </div>
                <div className="titleandprog">
                  <div className="titleprog">
                    <span style={{ color: "#161616", fontSize: "15px" }}>
                      {/* Avenue Alphand */}
                      Alphand Avenue
                    </span>
                    <span className="valuechange">
                      <i
                        className="bx bx-up-arrow-alt"
                        style={{ color: "#F42829" }}
                      ></i>
                      6%
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: "40%", backgroundColor: "#FF6E01" }}
                    ></div>
                  </div>
                </div>
                <div className="titleandprog">
                  <div className="titleprog">
                    <span style={{ color: "#161616", fontSize: "15px" }}>
                      {/* Avenue Beaucour */}
                      Beaucour Avenue
                    </span>
                    <span className="valuechange">
                      <i
                        className="bx bx-up-arrow-alt"
                        style={{ color: "#F42829" }}
                      ></i>
                      5%
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: "35%", backgroundColor: "#FF6E01" }}
                    ></div>
                  </div>
                </div>
              </div>
            </CongestionRoutesStyle>
            <CongestionRoutesStyle>
              {/* <span className="titlechart">Emissions CO2</span> */}
              <span className="titlechart">CO2 emissions</span>
              <div>
                <PieCharttrafic />
              </div>
            </CongestionRoutesStyle>
          </LeftTopSides>
          <ChartQuotidiennesStyle>
            <div className="tendance">
              <div style={{ minWidth: "500px", height: "100%" }}>
                <AreaChart />
              </div>
            </div>
          </ChartQuotidiennesStyle>
        </LeftSide>
        <RightSide>
          <CarteStyle>
            <div className="legendcard">
              <div className="cardhead">
                {/* <span>Carte</span> */}
                <span>Map</span>
                <div className="keymap">
                  <div className="legend">
                    <div
                      className="feuRougeicon"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: "12px",
                      }}
                    ></div>
                    <span
                      style={{
                        fontSize: "10px",
                        marginRight: "20px",
                        marginTop: "5px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {/* Feu vert */}
                      Green light
                    </span>
                  </div>
                  <div className="legend">
                    <div
                      className="feuRougeicon"
                      style={{
                        background: "#E91E1E",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginTop: "12px",
                      }}
                    ></div>
                    <span
                      style={{
                        fontSize: "10px",
                        marginRight: "20px",
                        marginTop: "5px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {/* Feu rouge */}
                      Red light
                    </span>
                  </div>
                </div>
              </div>
              <Carte />
            </div>
          </CarteStyle>
          <ChartHoraireStyle>
            <div className="horraire">
              <div style={{ minWidth: "500px", height: "100%" }}>
                <Heatmap />
              </div>
            </div>
          </ChartHoraireStyle>
        </RightSide>
      </Sides>
    </Container>
  );
}

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  @media screen and (max-width: 360px) {
    padding: 2rem 1rem;
  }
`;

const HeadStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .Stationnement {
    margin-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      margin: 0;
    }
  }
  @media screen and (max-width: 740px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const SelectStyle = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

const Logo = styled.div`
  background-image: url("/Trafic/Logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
`;

const Sides = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1100px) {
    /* flex-direction: column; */
    width: 100%;
  }
`;

const LeftTopSides = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  @media screen and (max-width: 612px) {
    flex-direction: column;
    /* width: 100%; */
  }
`;

const RightSide = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: 1100px) {
    /* flex-direction: column; */
    width: 100%;
  }
`;

const TopCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  @media screen and (max-width: 660px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 1200px) and (min-width: 661px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1800px) and (min-width: 1201px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CongestionStyle = styled.div`
  height: 130px;
  background: linear-gradient(78.67deg, #43d8e1 -82.12%, #18b6c0 149.68%);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: #ffffff;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  .sevdiv {
    .cardpart1 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-left: 10px;
    }
    .cardpart2 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 5px;
    }
  }
`;
const CongestionStyle1 = styled.div`
  height: 130px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  .sevdiv {
    .cardpart1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #f42829;
    }
    .cardpart2 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 5px;
    }
  }
`;

const CongestionRoutesStyle = styled.div`
  padding: 1rem;
  width: 50%;
  @media screen and (max-width: 612px) {
    flex-direction: column;
    width: 100%;
  }
  height: 335px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  .globaldiv {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    justify-content: space-between;
    width: 100%;
    .titleandprog {
      .titleprog {
        display: flex;
        justify-content: space-between;
        padding-bottom: 7px;
        .valuechange {
          background: #f8f8f8;
          border-radius: 4px;
          font-size: 14px;
          margin: 2px;
        }
      }
    }
  }
`;

const ChartQuotidiennesStyle = styled.div`
  width: 100%;
  height: 345px;
  display: flex;
  .tendance {
    padding: 1rem;
    height: 100%;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow-x: auto;
  }
`;

const CarteStyle = styled.div`
  width: 100%;
  display: flex;
  .legendcard {
    padding: 1rem;
    width: 100%;
    height: 490px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    flex-direction: column;
    .cardhead {
      display: flex;
      justify-content: space-between;
      .keymap {
        width: 70%;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        height: 40px;
        padding-top: 5px;
        .legend {
          width: 120px;
          /* width: 40%; */
          display: flex;
          justify-content: space-around;
          background: rgba(217, 217, 217, 0.2);
          border-radius: 8px;
          cursor: pointer;
          .feuRougeicon {
            margin-top: 5px;
            width: 14px;
            height: 14px;
            background: #18b6c0;
            border-radius: 4px;
          }
        }
      }
    }
  }
`;

const ChartHoraireStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  .horraire {
    padding: 1rem;
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    flex-direction: column;
    overflow-x: auto;
  }
`;
