import React from "react";

import Chart from "react-apexcharts";
import styled from "styled-components";

export default function PieCharttrafic() {
  return (
    <Chart
      type="pie"
      width="100%"
      height="300px"
      // height='1000px'
      series={[15, 15, 0, 20]}
      options={{
        colors: ["#42728D", "#FBACC8", "#f00", "#3CD1DB"],
        plotOptions: {
          pie: {
            donut: {
              size: "100%",
            },
          },
        },
        labels: ["Cars", "Road transport of goods", "Two-wheelers", "Buses"],
        legend: {
          show: true,
          position: "bottom",
          fontSize: "10px",
          // horizontalAlign: 'left',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },

          itemMargin: {
            horizontal: 2,
            vertical: 0,
          },
        },
        dataLabels: {
          formatter: (val) => {
            return `${val}` + "%";
          },
        },
      }}
    />
  );
}
