import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Map2 from "../components/maps/map2";
import { format } from "date-fns";
function Carte() {
  const Content = styled.div`
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
    @media screen and (max-width: 360px) {
      padding: 2rem 1rem;
    }
    .header {
    }
    .map {
      width: 100%;
      height: 700px;
    }
    .iconsTitle {
      display: flex;
    }
    .bntTous {
      width: 100px;
      height: 35px;

      background: #18b6c0;
      color: white;
      text-align: center;
      border-radius: 5px;
    }
  `;
  return (
    <Content>
      <HeaderStyle>
        <div className="header__title">
          <div>
            <div className="iconsTitle">
              <img src="/cart.svg" alt="" />
              <span className="pl-2" style={{ color: "#19B6C0" }}>
                card
              </span>
            </div>
          </div>
          <div>{format(new Date(), "EEEE, MMMM dd yyyy")}</div>
        </div>
        <div className="header__inputs">
          <div>
            <div>
              <input
                type="search"
                className="form-control 
                             flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
              />
            </div>
          </div>
          <div>
            <select
              className="form-select appearance-none
                        block
                        w-full
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-cyan-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Sensors</option>
              <option value="1">Air quality</option>
              <option value="2">CameraIP</option>
              <option value="3">Next Lamp Controller</option>
            </select>
          </div>
          <div>
            <select
              className="form-select appearance-none
                        block
                        w-full
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-blue-500
                        rounded
                        transition
                        ease-in-out
                        text-red-400
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-red-600 focus:outline-none"
              aria-label="Default select example"
            >
              ​<option selected>Equipment</option>
              <option value="1">Pool</option>
              <option value="2">Conservatory</option>
              <option value="3">Library</option>
              <option value="3">theater</option>
            </select>
          </div>
          <div>
            <select
              className="form-select appearance-none
                        block
                        w-full
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-blue-500
                        rounded
                        transition
                        ease-in-out
                        text-red-400
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-red-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Vehicle</option>
              <option value="1">Public transport</option>
            </select>
          </div>
          <div>
            <select
              className="form-select appearance-none
                        block
                        w-full
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding bg-no-repeat
                        border border-solid border-blue-500
                        rounded
                        transition
                        ease-in-out
                        text-red-400
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-red-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option selected>Construction site</option>
              <option value="1">Roads</option>
              <option value="2">Sanitation</option>
            </select>
          </div>
          <div>
            <button className="bntTous">all</button>
          </div>
        </div>
      </HeaderStyle>
      <div className="map">
        <Map2 />
      </div>
    </Content>
  );
}

const HeaderStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .header__title {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .header__inputs {
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 1000px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 500px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
export default Carte;
