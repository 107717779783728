import React from "react";
import styled from "styled-components";
import Detailsbarchart from "../charts/Detailsbarchart";
import DonutChart from "../charts/donutchart";
// /817
const Mydetails = styled.div`
  width: 97%;
  padding-left: 10px;
  background: #fcfcfc;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: 15px;
  .cardhead {
    margin-left: 10px;
    display: flex;
    padding-top: 20px;
    .title {
      margin-left: 10px;
      padding-top: 4px;
    }
  }
  .controlleur {
    background: rgba(196, 196, 196, 0.2);
    border-radius: 4px;
    width: fit-content;
    display: flex;
    margin: 3px;
    height: 30px;
    margin-top: 9px;
    height: 30px;
    padding-left: 11px;
    padding-right: 17px;
  }
  .secdiv {
    display: flex;
    width: 100%;
    padding: 10px;
    @media screen and (max-width: 817px) {
      flex-direction: column;
    }
    /* margin: 10px; */
    .image {
      width: 26%;
      @media screen and (max-width: 817px) {
        /* flex-direction: column; */
        width: 100%;
      }
      padding: 10px;
    }
    .cards {
      width: 90%;
      display: flex;
      flex-direction: column;
      .chart {
        width: 93%;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        margin: 10px;
        .charthead {
          display: flex;
          justify-content: space-between;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;
          @media screen and (max-width: 922px) {
            flex-direction: column;
            /* width: 100%; */
          }
          .title {
            width: 30%;
            @media screen and (max-width: 922px) {
              /* flex-direction: column; */
              width: 100%;
            }
          }
          .lefthead {
            width: 40%;
            display: flex;
            justify-content: space-around;
            @media screen and (max-width: 922px) {
              /* flex-direction: column; */
              width: 100%;
            }
          }
        }
      }
      .secdiv {
        width: 95%;
        display: flex;
        justify-content: space-between;
        .card1 {
          width: 38%;
          @media screen and (max-width: 817px) {
            /* flex-direction: column; */
            width: 100%;
          }
          height: 100%;
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-bottom: 15px;
        }
        .card2 {
          width: 25%;
          @media screen and (max-width: 817px) {
            /* flex-direction: column; */
            width: 100%;
          }
          height: 100%;
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          margin-bottom: 15px;
          .card2-1 {
            display: flex;
            justify-content: space-around;
            .image {
              width: 70%;
              @media screen and (max-width: 817px) {
                /* flex-direction: column; */
                width: 100%;
              }
            }
          }
          .card2-2 {
            display: flex;
            justify-content: space-around;
            .image {
              width: 34%;
              @media screen and (max-width: 817px) {
                /* flex-direction: column; */
                width: 100%;
              }
            }
          }
        }
        .card3 {
          height: 100%;
          width: 33%;
          @media screen and (max-width: 817px) {
            /* flex-direction: column; */
            width: 100%;
          }
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          margin-bottom: 15px;
          .title {
            color: #161e54;
            font-size: 15px;
            font-family: Helvetica, Arial, sans-serif;
          }
          .chartdiv {
            display: flex;
            justify-content: space-around;
            @media screen and (max-width: 817px) {
              flex-direction: column;
            }
          }
        }
      }
      .firstcard {
        width: 100%;
        display: flex;
        @media screen and (max-width: 817px) {
          flex-direction: column;
        }
        .div1 {
          padding: 5px;
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          height: 80px;
          margin: 10px;
          width: 17%;
          @media screen and (max-width: 817px) {
            /* flex-direction: column; */
            width: 100%;
          }
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .toptext {
          }
          .bottomtext {
            display: flex;
            justify-content: end;
            color: #18b6c0;
            cursor: pointer;
          }
        }
      }
    }
  }
`;

const Details = () => {
  return (
    <Mydetails>
      <div className="cardhead">
        <img src="/icons/lumieretrafic.svg" alt="" />
        <span className="title">Public lighting pole </span>
      </div>
      <div className="controlleur">
        <img src="/icons/control.svg" alt="" width="20px" />
        <span style={{ marginTop: "4px", fontSize: "15px", marginLeft: "5px" }}>
          Controller ID : <span style={{ fontSize: "17px" }}>C-01</span>
        </span>
        <span
          style={{ marginLeft: "28px", fontSize: "15px", marginTop: "3px" }}
        >
          Name :<span style={{ fontSize: "17px" }}>12782DC</span>
        </span>
      </div>
      <div className="secdiv">
        <div className="image">
          <img src="/icons/poteau.png" alt="" />
        </div>
        <div className="cards">
          <div className="firstcard">
            <div className="div1">
              <span className="toptext">Lamp Status</span>
              <span className="bottomtext">Enabled</span>
            </div>
            <div className="div1">
              <span className="toptext">Light level</span>
              <span className="bottomtext">30%</span>
            </div>
            <div className="div1">
              <span className="toptext">Remaining life</span>
              <span className="bottomtext">28.33%</span>
            </div>
            <div className="div1">
              <span className="toptext">Lamp mode</span>
              <span className="bottomtext">Automatic</span>
            </div>
            <div className="div1">
              <span className="toptext">
                Illumination <span style={{ fontSize: "10px" }}>moy</span>
              </span>
              <span className="bottomtext">20 lux</span>
            </div>
          </div>
          <div className="secdiv">
            <div className="card1">
              <span style={{ display: "flex", justifyContent: "center" }}>
                Applied dimming program
              </span>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/icons/economise.svg"
                  alt=""
                  width="auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    imageResolution: "40",
                  }}
                />
              </div>
              <span style={{ display: "flex", justifyContent: "center" }}>
                35% Saved
              </span>
              <span style={{ display: "flex", justifyContent: "center" }}>
                13 June 2023 à 17 June 2023
              </span>
            </div>
            <div className="card2">
              <span>Connected resources</span>
              <div className="card2-1">
                <div className="image">
                  <img src="/icons/elipse1.svg" alt="" />
                </div>
                <div className="image">
                  <img src="/icons/elipse2.svg" alt="" />
                </div>
                <div className="image">
                  <img src="/icons/elipse3.svg" alt="" />
                </div>
              </div>
              <div className="card2-2">
                <div className="image">
                  <img src="/icons/elipse4.svg" alt="" />
                </div>
                <div className="image">
                  <img src="/icons/elipse5.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="card3">
              <span className="title">Energy source</span>
              <div className="chartdiv">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "10px",
                    justifyContent: "space-around",
                    padding: "20px",
                  }}
                >
                  <span>
                    Network
                    <br />
                    <span style={{ whiteSpace: "nowrap", color: "#FA0082" }}>
                      327 W/h
                    </span>
                  </span>
                  <span>
                    Battery
                    <br />
                    <span style={{ whiteSpace: "nowrap", color: "#5B93FF" }}>
                      500 W/h
                    </span>
                  </span>
                  <span>
                    Solar
                    <br />
                    <span style={{ whiteSpace: "nowrap", color: "#18B6C0" }}>
                      100 W/h
                    </span>
                  </span>
                </div>
                <DonutChart />
              </div>
            </div>
          </div>
          <div className="chart">
            <div className="charthead">
              <span className="title">Energy consumption report (W/h)</span>
              <div className="lefthead">
                <select
                  className="form-select appearance-none
                          block
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding bg-no-repeat
                          border border-solid border-cyan-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none border-0"
                  aria-label="Default select example"
                  style={{ fontSize: "70%", width: "fit-content" }}
                >
                  <option selected>Select date</option>
                </select>
                <span style={{ whiteSpace: "nowrap", color: "#18B6C0" }}>
                  per day
                </span>
                <span style={{ whiteSpace: "nowrap", color: "##161E54" }}>
                  per month
                </span>
              </div>
            </div>
            <Detailsbarchart />
          </div>
        </div>
      </div>
    </Mydetails>
  );
};
export default Details;
