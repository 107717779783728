import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Rectangle,
  LayersControl,
  Marker,
  LayerGroup,
  Popup,
  FeatureGroup,
  ZoomControl,
  useMapEvents,
} from "react-leaflet";
import styled from "styled-components";
import { Control } from "leaflet";
import Select from "./Select";
import Search from "./Search";

function LocationMarker() {
  const [position, setPosition] = useState(null);
  const map = useMapEvents({
    click(e) {
      console.log(e.latlng);
      map.locate();
    },
  });

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  );
}

function Map() {
  const position = { lat: 30.428480398828107, lng:  -9.597313996784024};

  return (
    <MapStyle>
      <MapContainer
        style={{
          width: "100%",
          height: "100%",
          border: "1px solid #18B6C0",
          zIndex: 1,
        }}
        center={position}
        zoom={13}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
            />
        <ZoomControl position="bottomright" />
        <Select />
        <Search placeholder="maps" />
        <Search placeholder="parcelle" />
        {/* <LocationMarker/> */}
      </MapContainer>
    </MapStyle>
  );
}

export default Map;

const MapStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  height: 350px;

  .selectMap {
    width: 150px;
    height: 35px;
    border: 0;
    outline: 0;
    color: green;
    background-color: #ffffff !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    option {
      color: #161e54;
      background-color: #ffffff;
    }
    option:checked {
      color: green !important;
    }
  }

  .inputDiv {
    display: flex;
    gap: 0.4rem;
    background-color: #fff;
    min-height: 35px;
    justify-content: space-around;
    box-shadow: 0px 1px 4p x rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    border: 0.4px solid #18b6c0;
    input {
      width: 50%;
      ::placeholder {
        color: rgba(80, 83, 99, 0.25);
      }
    }
  }

  .leaflet-top.leaflet-left {
    display: flex;
  }
  @media screen and (max-width: 635px) {
    width: 90%;
    .leaflet-top.leaflet-left {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 440px) {
    .inputDiv {
      width: 230px;
    }
    .selectMap {
      margin-top: 6.3rem;
      width: 230px;
    }
  }
  min-width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
